import request_get_or_post, { request_post_multipart_2, request_post_downloadfile, request_post_downloadfile_range } from './Request'
import {dns} from './variable'
// const domain = "http://118.140.243.107:8080/file"
const domain = dns + '/convergency'
// const domain = "http://uni-intra.cni-metaverse.com:8002/file"

export function apifetch_get(params) {
    return request_get_or_post("post", domain + '/get', params);
}
export function apifetch_add(params) {
    return request_get_or_post("post", domain + '/add', params);
}
export function apifetch_update(params) {
    return request_get_or_post("post", domain + '/update', params);
}
export function apifetch_search(params) {
    return request_get_or_post("post", domain + '/search', params);
}
export function apifetch_delete(params) {
    return request_get_or_post("post", domain + '/delete', params);
}
export function apifetch_importfromexcel(file, onUploadProgress) {
    return request_post_multipart_2(domain + '/import_from_excel', file, onUploadProgress);
}
export function apifetch_exportexcel(params) {
    return request_post_downloadfile(domain + '/export_excel', params);
}
export function apifetch_exportexcel_range(params) {
    // 'params' in json format
    // {
    //     "from": 1,
    //     "to": 4,
    //     "sequence": "descending"
    // }
    return request_post_downloadfile_range(domain + '/export_excel_range', params);
}