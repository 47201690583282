import axios from "axios";
import { domain } from "../../services/apifetch/ApiAuth"

//// action paperS, INdirect CALL by below FUNCtionS ---- start
export function loginSuccess(payload) {
    return {
        type: '@@auth/loginSuccess',
        payload
    }
}
export function logoutSuccess(){
    return {
        type: '@@auth/logoutSuccess'
    }
}
//// action paperS, INdirect CALL by below FUNCtionS ---- end


//// action FUNCtionS, CALL 'action paperS' then to REDucerS ---- start
export function actionLogin(userEmail, userPassword, callback) {
    return async (dispatch) => {

        // 18.163.235.235:8001/account/login
        // {
        //     "email": "uni@email.com",
        //     "password": "1234"
        // }
        // (res)
        // {
        //     "email": "uni@email.com",
        //     "address": "0x2e343bbd5266d1050d0f32f06c028938b9620e47",
        //     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBZGRyZXNzIjoiMHgyZTM0M2JiZDUyNjZkMTA1MGQwZjMyZjA2YzAyODkzOGI5NjIwZTQ3IiwiRW1haWwiOiJ1bmlAZW1haWwuY29tIiwiQXV0aG9yaXplZExldmVsIjoxLCJleHAiOjE2OTE0MTk5MTAsImlhdCI6MTY5MDIxMDMxMCwibmJmIjoxNjkwMjEwMzEwfQ.fo5nYhVctit7p3qqnIyWbLezcWjtvxW6T2jnTm4Taz4",
        //     "profile_information": {
        //         "age": 2,
        //         "name": "uni"
        //     },
        //     "profile_information_version": 1,
        //     "private_key": "0x152bcc64d2c94afb9453a13e3c74ded77ead422b1571a4daf8d82edc86cbdce2"
        // }

        const res_login = await axios.post(
            domain,
            {
                'email': userEmail,
                'password': userPassword                
            }
        )
        if (res_login.error) {
            // fixme: no need
            // handleSnackbar(res_login.error, 'error', 2000);
            return
        }
        console.log(" ***** res_login: ", res_login)

        //// REDux
        dispatch(loginSuccess(res_login.data))
        callback();
    }
}
//// action FUNCtionS, CALL 'action paperS' then to REDucerS ---- end