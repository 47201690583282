import request_get_or_post, { request_post_multipart_2, request_post_downloadfile } from './Request'
import {dns1} from './variable'
// const domain = "http://118.140.243.107:8000/weather"
const domain = dns1 + '/weather'
// const domain = "http://uni-intra.cni-metaverse.com:8001/weather"

export function apifetch_get(params) {
    return request_get_or_post("post", domain + '/get', params);
    // return request("post", '/automatic_groundwater_monitoring/get', params);
}
export function apifetch_add(params) {
    return request_get_or_post("post", domain + '/add', params);
}
export function apifetch_update(params) {
    return request_get_or_post("post", domain + '/update', params);
}
export function apifetch_search(params) {
    return request_get_or_post("post", domain + '/search', params);
}
export function apifetch_delete(params) {
    return request_get_or_post("post", domain + '/delete', params);
}
export function apifetch_importfromexcel(file, onUploadProgress) {
    return request_post_multipart_2(domain + '/import_from_excel', file, onUploadProgress);
}
export function apifetch_exportexcel(params) {
    return request_post_downloadfile(domain + '/export_excel', params);
}