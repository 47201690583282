/**
 * http request
 */
import axios from "axios";
import { enqueueSnackbar } from 'notistack'; 

const handleSnackbar = (message, variant, autoHideDuration) => { 
    enqueueSnackbar(message, { variant, autoHideDuration }); 
}; 

axios.defaults.timeout = 100000;
// axios.defaults.baseURL = "http://118.140.243.107:8080/";

/**
 * http request INTERCEPTORs
 * // [fixme] what may happen?
 */
axios.interceptors.request.use(
    (config) => {
        config.data = JSON.stringify(config.data);
        config.headers = {
            "Content-Type": "application/json",
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * http RESponse INTERCEPTORs, for ALL REQuest
 */
axios.interceptors.response.use(
    (response) => {
        if (response.data.code === 0) {
            handleSnackbar(response.data.msg, 'error', 2000); 
        }
        return response;
    },
    (error) => {
        switch (error.response.status) {
            case 400:
                handleSnackbar("Bad Request", 'error', 2000); 
                break;
            case 401:
                handleSnackbar("Unauthorized", 'error', 2000); 
                break;
            case 403:
                handleSnackbar("Forbidden", 'error', 2000); 
                break;
            case 404:
                handleSnackbar("Not Found", 'error', 2000); 
                break;
            case 408:
                handleSnackbar("Request Timeout", 'error', 2000); 
                break;
            // case 500:
            //     handleSnackbar("Internal Server Error", 'error', 2000);
            //     break;
            case 501:
                handleSnackbar("Not Implemented", 'error', 2000); 
                break;
            case 502:
                handleSnackbar("Bad Gateway", 'error', 2000); 
                break;
            case 503:
                handleSnackbar("Service Unavailable", 'error', 2000); 
                break;
            case 504:
                handleSnackbar("Gateway Timeout", 'error', 2000); 
                break;
            case 505:
                handleSnackbar("HTTP Version Not Supported", 'error', 2000); 
                break;
            default:                
                handleSnackbar("Inappropriate Input", 'error', 2000); 
                break;
        }
        return error.response.data
    }
);

/**
 * GET, encapsulation
 * @param url  
 * @param params  
 * @returns {Promise}
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
        }).then((response) => {
            landing(url, params, response.data);
            resolve(response.data);
        })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * POST, encapsulation
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                //
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

let request_post_multipart_1 = axios.create({
    baseURL: axios.defaults.baseURL,
    headers: {
      "Content-type": "application/json"
    }
});
export function request_post_multipart_2(url, file, onUploadProgress) {
    return new Promise((resolve, reject) => {
        
        console.log(" *** [post_multipart] ")
        console.log(" *** [post_multipart] file:", file)

        let formData = new FormData();
        formData.append("excel_file", file);
        
        request_post_multipart_1.post(url, formData, {
            headers: {
            "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        }
        ).then(
            (response) => {
                //
                resolve(response.data);
            },
            (err) => {
                console.log(" *** err:", err)
                alert("wrong file is attached!");
                reject(err);
            }
        );
    });
}

export function request_post_downloadfile (url, fileName) {
    return axios({
      url,
      method: 'POST',
      responseType: 'blob',
      data: {    
        "sequence": "ascending"        
      }
    })
      .then(response => {
        const href = window.URL.createObjectURL(response.data);
  
        const anchorElement = document.createElement('a');
  
        anchorElement.href = href;
        anchorElement.download = fileName;
  
        document.body.appendChild(anchorElement);
        anchorElement.click();
  
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  }

export function request_post_downloadfile_range (url, params) {
    // console.log("*** params:", params)
    return axios({
        url,
        method: 'POST',
        responseType: 'blob',
        data: {
            "from": params.from,
            "to": params.to,
            "sequence": params.sequence
        }
    })
    .then(response => {
        const href = window.URL.createObjectURL(response.data);

        const anchorElement = document.createElement('a');

        anchorElement.href = href;
        anchorElement.download = params.fileName;

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
    })
    .catch(error => {
        console.log('error: ', error);
    });
}

/**
 * PATCH, encapsulation
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                msag(err);
                reject(err);
            }
        );
    });
}

/**
 * PUT, encapsulation
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                msag(err);
                reject(err);
            }
        );
    });
}

// http response -- GET & POST router
export default function request_get_or_post(fetch, url, param) {
    return new Promise((resolve, reject) => {
        switch (fetch) {
            case "get":
                console.log("begin a get request,and url:", url);
                get(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.log("get request GET failed.", error);
                        reject(error);
                    });
                break;
            case "post":
                post(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.log("get request POST failed.", error);
                        reject(error);
                    });
                break;
            default:
                break;
        }
    });
}

// error messageS
// [fixme] what may happen?
function msag(err) {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                alert(err.response.data.error.details);
                break;
            case 401:
                alert("Unauthorized");
                break;

            case 403:
                alert("Forbidden");
                break;

            case 404:
                alert("Not Found");
                break;

            case 408:
                alert("Request Timeout");
                break;

            case 500:
                alert("Internal Server Error");
                break;

            case 501:
                alert("Not Implemented");
                break;

            case 502:
                alert("Bad Gateway");
                break;

            case 503:
                alert("Service Unavailable");
                break;

            case 504:
                alert("Gateway Timeout");
                break;

            case 505:
                alert("HTTP Version Not Supported");
                break;
            default:
                alert("Error")
                break;
        }
    }
}

/**
 * ? check data ...
 * // [fixme] what may happen?
 * @param url
 * @param params
 * @param data
 */
function landing(url, params, data) {
    if (data.code === -1) {
    }
}

