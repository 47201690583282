//@ts-nocheck
import React, { useEffect, useState, useRef } from 'react'
import { withStyles, makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles"
import { Card, Container, Form, Image } from "react-bootstrap"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, FormControl, InputAdornment, Input, InputLabel, OutlinedInput, Select, Box, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import { apifetch_get, apifetch_add, apifetch_update, apifetch_search, apifetch_delete, apifetch_importfromexcel, apifetch_exportexcel } from '../../services/apifetch/ApiPipeJackingMachine'
import { Refresh, Add, Menu, Search, Edit, Clear, CloudUpload, CloudDownload } from "@material-ui/icons";

import Plot from 'react-plotly.js';
import ImageLoading from "../../img/loading_curve1.gif"
import SwipeableViews from 'react-swipeable-views';

type DataElement = {
    id: number| undefined;

    date: string| undefined; 
    ring_count: number| undefined;
    angle_tite: number| undefined;
    angle_rotation: number| undefined;
    angle_x_axis_tilt: number| undefined;
    angle_y_axis_tilt: number| undefined;

    pressure_guidance_system_shield: number| undefined;
    pressure_guidance_system_ground: number| undefined;

    temperature_hyd_oil_tank_cutterhead: number| undefined;
    temperature_hyd_oil_tank_propulsion: number| undefined;

    main_drive_water_temperature: number| undefined;
    main_drive_water_flow: number| undefined;
    main_bearing_oil_flow: number| undefined;
    oil_gas_seal_pressure: number| undefined;
    gear_oil_temperature: number| undefined;
    main_drive_grease_flow: number| undefined;

    cutterhead_speed: number| undefined;
    cutterhead_hyd_pump_outlet_pressure: number| undefined;
    cutterhead_torque: number| undefined;
    cutterhead_oil_leakage_temperature: number| undefined;
    cutterhead_oil_charge_pump_pressure: number| undefined;

    excavation_speed_setting: number| undefined;
    excavation_pump_outlet_pressure: number| undefined;
    excavation_average_speed: number| undefined;
    penetration: number| undefined;
    total_force: number| undefined;
    pressure_auxiliary_pump: number| undefined;

    steering_jack_angle: number| undefined;
    steering_jack_axis_angle: number| undefined;
    steering_jack_ext_1: number| undefined;
    steering_jack_ext_2: number| undefined;
    steering_jack_ext_3: number| undefined;
    steering_jack_ext_4: number| undefined;
    steering_jack_pressure_1: number| undefined;
    steering_jack_pressure_2: number| undefined;
    steering_jack_pressure_3: number| undefined;
    steering_jack_pressure_4: number| undefined;

    bentonite_flow_rate: number| undefined;
    bentonite_pressure: number| undefined;

    excavation_chamber_pressure_gauge_1: number| undefined;
    excavation_chamber_pressure_gauge_2: number| undefined;
    excavation_chamber_pressure_gauge_3: number| undefined;

    slurry_inflow_rate: number| undefined;
    slurry_outflow_rate: number| undefined;
    slurry_inflow_speed_setting_p1_1: number| undefined;
    slurry_inflow_speed_setting_p1_2: number| undefined;
    slurry_outflow_speed_setting_p2_1: number| undefined;
    slurry_outflow_speed_setting_p2_2: number| undefined;
    slurry_outflow_speed_setting_p2_3: number| undefined;
    slurry_inflow_pressure_p1_1: number| undefined;
    slurry_inflow_pressure_p1_2: number| undefined;
    slurry_outflow_pressure_p1_2: number| undefined;
    slurry_outflow_pressure_p1_1: number| undefined;
    slurry_inflow_pressure_p2_1: number| undefined;
    slurry_outflow_pressure_p2_1: number| undefined;
    slurry_inflow_pressure_p2_2: number| undefined;
    slurry_outflow_pressure_p2_2: number| undefined;
    slurry_outflow_speed_p2_1: number| undefined;
    slurry_outflow_speed_p2_2: number| undefined;
    slurry_outflow_current_p2_1: number| undefined;
    slurry_outflow_current_p2_2: number| undefined;

    t11y_transformer_phasel1_voltage: number| undefined;
    t11y_transformer_phasel2_voltage: number| undefined;
    t11y_transformer_phasel3_voltage: number| undefined;
    t11y_transformer_linel1l2_voltage: number| undefined;
    t11y_transformer_linel2l3_voltage: number| undefined;
    t11y_transformer_linel3l1_voltage: number| undefined;
    t11y_transformer_phasel1_current: number| undefined;
    t11y_transformer_phasel2_current: number| undefined;
    t11y_transformer_phasel3_current: number| undefined;
    t11y_transformer_freq: number| undefined;
    t11y_transformer_active_power: number| undefined;
    t11y_transformer_passive_power: number| undefined;
    t11y_transformer_power_factor: number| undefined;
    t11y_transformer_input_power: number| undefined;
    t11y_transformer_output_power: number| undefined;

    feedback_speed_p1_1: number| undefined;
    feedback_current_p1_1: number| undefined;

    excavation_jack_ext_2: number| undefined;
    excavation_jack_ext_5: number| undefined;

    intermediate_jack1_grp1_ext: number| undefined;
    intermediate_jack2_grp1_ext: number| undefined;
    intermediate_jack1_grp2_ext: number| undefined;
    intermediate_jack2_grp2_ext: number| undefined;

    concentration_co2: number| undefined;
    concentration_o2: number| undefined;
    concentration_co: number| undefined;
    concentration_ch4: number| undefined;
    concentration_h2s: number| undefined;

    distance: number| undefined;

    create_at: string | undefined;
    updated_at: string | undefined;
};


//// css
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#808080',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);
const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'green',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'green',
        },
      },
    },
})(TextField);


//// utility
function parsefloat_nonan(input_string){
    if (input_string !== ''){
        return parseFloat(input_string)
    } else {
        return 0
    }        
}


export function PipeJackingMachine() {

    //// data element, initialization
    // [fixme, depends on table, DataElement]
    const [date, setdate] = useState('');
    const [ring_count, setring_count] = useState(0);
    const [angle_tite, setangle_tite] = useState(0);
    const [angle_rotation, setangle_rotation] = useState(0);
    const [angle_x_axis_tilt, setangle_x_axis_tilt] = useState(0);
    const [angle_y_axis_tilt, setangle_y_axis_tilt] = useState(0);

    const [pressure_guidance_system_shield, setpressure_guidance_system_shield] = useState(0);
    const [pressure_guidance_system_ground, setpressure_guidance_system_ground] = useState(0);

    const [temperature_hyd_oil_tank_cutterhead, settemperature_hyd_oil_tank_cutterhead] = useState(0);
    const [temperature_hyd_oil_tank_propulsion, settemperature_hyd_oil_tank_propulsion] = useState(0);

    const [main_drive_water_temperature, setmain_drive_water_temperature] = useState(0);
    const [main_drive_water_flow, setmain_drive_water_flow] = useState(0);
    const [main_bearing_oil_flow, setmain_bearing_oil_flow] = useState(0);
    const [oil_gas_seal_pressure, setoil_gas_seal_pressure] = useState(0);
    const [gear_oil_temperature, setgear_oil_temperature] = useState(0);
    const [main_drive_grease_flow, setmain_drive_grease_flow] = useState(0);

    const [cutterhead_speed, setcutterhead_speed] = useState(0);
    const [cutterhead_hyd_pump_outlet_pressure, setcutterhead_hyd_pump_outlet_pressure] = useState(0);
    const [cutterhead_torque, setcutterhead_torque] = useState(0);
    const [cutterhead_oil_leakage_temperature, setcutterhead_oil_leakage_temperature] = useState(0);
    const [cutterhead_oil_charge_pump_pressure, setcutterhead_oil_charge_pump_pressure] = useState(0);

    const [excavation_speed_setting, setexcavation_speed_setting] = useState(0);
    const [excavation_pump_outlet_pressure, setexcavation_pump_outlet_pressure] = useState(0);
    const [excavation_average_speed, setexcavation_average_speed] = useState(0);
    const [penetration, setpenetration] = useState(0);
    const [total_force, settotal_force] = useState(0);
    const [pressure_auxiliary_pump, setpressure_auxiliary_pump] = useState(0);

    const [steering_jack_angle, setsteering_jack_angle] = useState(0);
    const [steering_jack_axis_angle, setsteering_jack_axis_angle] = useState(0);
    const [steering_jack_ext_1, setsteering_jack_ext_1] = useState(0);
    const [steering_jack_ext_2, setsteering_jack_ext_2] = useState(0);
    const [steering_jack_ext_3, setsteering_jack_ext_3] = useState(0);
    const [steering_jack_ext_4, setsteering_jack_ext_4] = useState(0);
    const [steering_jack_pressure_1, setsteering_jack_pressure_1] = useState(0);
    const [steering_jack_pressure_2, setsteering_jack_pressure_2] = useState(0);
    const [steering_jack_pressure_3, setsteering_jack_pressure_3] = useState(0);
    const [steering_jack_pressure_4, setsteering_jack_pressure_4] = useState(0);

    const [bentonite_flow_rate, setbentonite_flow_rate] = useState(0);
    const [bentonite_pressure, setbentonite_pressure] = useState(0);

    const [excavation_chamber_pressure_gauge_1, setexcavation_chamber_pressure_gauge_1] = useState(0);
    const [excavation_chamber_pressure_gauge_2, setexcavation_chamber_pressure_gauge_2] = useState(0);
    const [excavation_chamber_pressure_gauge_3, setexcavation_chamber_pressure_gauge_3] = useState(0);

    const [slurry_inflow_rate, setslurry_inflow_rate] = useState(0);
    const [slurry_outflow_rate, setslurry_outflow_rate] = useState(0);
    const [slurry_inflow_speed_setting_p1_1, setslurry_inflow_speed_setting_p1_1] = useState(0);
    const [slurry_inflow_speed_setting_p1_2, setslurry_inflow_speed_setting_p1_2] = useState(0);
    const [slurry_outflow_speed_setting_p2_1, setslurry_outflow_speed_setting_p2_1] = useState(0);
    const [slurry_outflow_speed_setting_p2_2, setslurry_outflow_speed_setting_p2_2] = useState(0);
    const [slurry_outflow_speed_setting_p2_3, setslurry_outflow_speed_setting_p2_3] = useState(0);
    const [slurry_inflow_pressure_p1_1, setslurry_inflow_pressure_p1_1] = useState(0);
    const [slurry_inflow_pressure_p1_2, setslurry_inflow_pressure_p1_2] = useState(0);
    const [slurry_outflow_pressure_p1_2, setslurry_outflow_pressure_p1_2] = useState(0);
    const [slurry_outflow_pressure_p1_1, setslurry_outflow_pressure_p1_1] = useState(0);
    const [slurry_inflow_pressure_p2_1, setslurry_inflow_pressure_p2_1] = useState(0);
    const [slurry_outflow_pressure_p2_1, setslurry_outflow_pressure_p2_1] = useState(0);
    const [slurry_inflow_pressure_p2_2, setslurry_inflow_pressure_p2_2] = useState(0);
    const [slurry_outflow_pressure_p2_2, setslurry_outflow_pressure_p2_2] = useState(0);
    const [slurry_outflow_speed_p2_1, setslurry_outflow_speed_p2_1] = useState(0);
    const [slurry_outflow_speed_p2_2, setslurry_outflow_speed_p2_2] = useState(0);
    const [slurry_outflow_current_p2_1, setslurry_outflow_current_p2_1] = useState(0);
    const [slurry_outflow_current_p2_2, setslurry_outflow_current_p2_2] = useState(0);

    const [t11y_transformer_phasel1_voltage, sett11y_transformer_phasel1_voltage] = useState(0);
    const [t11y_transformer_phasel2_voltage, sett11y_transformer_phasel2_voltage] = useState(0);
    const [t11y_transformer_phasel3_voltage, sett11y_transformer_phasel3_voltage] = useState(0);
    const [t11y_transformer_linel1l2_voltage, sett11y_transformer_linel1l2_voltage] = useState(0);
    const [t11y_transformer_linel2l3_voltage, sett11y_transformer_linel2l3_voltage] = useState(0);
    const [t11y_transformer_linel3l1_voltage, sett11y_transformer_linel3l1_voltage] = useState(0);
    const [t11y_transformer_phasel1_current, sett11y_transformer_phasel1_current] = useState(0);
    const [t11y_transformer_phasel2_current, sett11y_transformer_phasel2_current] = useState(0);
    const [t11y_transformer_phasel3_current, sett11y_transformer_phasel3_current] = useState(0);
    const [t11y_transformer_freq, sett11y_transformer_freq] = useState(0);
    const [t11y_transformer_active_power, sett11y_transformer_active_power] = useState(0);
    const [t11y_transformer_passive_power, sett11y_transformer_passive_power] = useState(0);
    const [t11y_transformer_power_factor, sett11y_transformer_power_factor] = useState(0);
    const [t11y_transformer_input_power, sett11y_transformer_input_power] = useState(0);
    const [t11y_transformer_output_power, sett11y_transformer_output_power] = useState(0);

    const [feedback_speed_p1_1, setfeedback_speed_p1_1] = useState(0);
    const [feedback_current_p1_1, setfeedback_current_p1_1] = useState(0);
    
    const [excavation_jack_ext_2, setexcavation_jack_ext_2] = useState(0);
    const [excavation_jack_ext_5, setexcavation_jack_ext_5] = useState(0);

    const [intermediate_jack1_grp1_ext, setintermediate_jack1_grp1_ext] = useState(0);
    const [intermediate_jack2_grp1_ext, setintermediate_jack2_grp1_ext] = useState(0);
    const [intermediate_jack1_grp2_ext, setintermediate_jack1_grp2_ext] = useState(0);
    const [intermediate_jack2_grp2_ext, setintermediate_jack2_grp2_ext] = useState(0);

    const [concentration_co2, setconcentration_co2] = useState(0);
    const [concentration_o2, setconcentration_o2] = useState(0);
    const [concentration_co, setconcentration_co] = useState(0);
    const [concentration_ch4, setconcentration_ch4] = useState(0);
    const [concentration_h2s, setconcentration_h2s] = useState(0);

    const [distance, setdistance] = useState(0);
    


    //// data container
    const [templatedata, settemplatedata] = useState<DataElement[]>([])
    const [totalpagenum, settotalpagenum] = useState(5)


    //// pagination (rows per page)
    const page = useRef(0);
    const rowsPerPage = useRef(5);
    // const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event: any, newPage: any) => {
        page.current = newPage
        console.log("[handleChangePage] page.current:", page.current)
        console.log("[handleChangePage] newPage:", newPage)
        // initListData();
        reloadtabledata()
    };
    const handleChangeRowsPerPage = (event: any) => {
        rowsPerPage.current = parseInt(event.target.value);
        initListData();
    };

    
    ////// backend api --- start
    //// api, getALL
    const initListData = async () => {
        const responsedata = await apifetch_get(
            {
                "page_number": page.current+1,
                "item_per_page": rowsPerPage.current,
                "sequence": "descending"
            }
        );
        settemplatedata(responsedata.schema_template)
        settotalpagenum(responsedata.count)
        console.log("[initListData] responsedata", responsedata);
        console.log("[initListData] responsedata.schema_template", responsedata.schema_template);
    }
    //// api, search
    const [searchText, setsearchText] = useState('')
    const searchHandler = (e: any) => {
        setsearchText(e.target.value)
    }
    const searchtemplatedata = async () => {
        const responsedata = await apifetch_search(
            {
                "page_number": page.current+1, 
                "item_per_page": rowsPerPage.current,
                "sequence": "descending",
                "search_param": searchText
            }
        );
        settemplatedata(responsedata.schema_template)
        settotalpagenum(responsedata.count)
        console.log("[searchtemplatedata] responsedata:", responsedata);
    }
    ////// backend api --- end


    //// startup, data reading - api, getALL
    useEffect(() => {
        async function init() {
            // getALL list
            initListData()
        }

        init();
    }, []);


    //// non-startup, data reading
    //    - if there exist 'searchText', then response the needed data
    //    - if there exist NO 'searchText', then response 'getALL'
    const reloadtabledata = () => {
        if (searchText) {
            searchtemplatedata()
        } else {
            initListData();
        }
    }


    //// for POPup, DialogEdit, input    
    const [dialogEditOpen, setdialogEditOpen] = useState(false);    
    const openDialogEdit = (
        // [fixme, depends on table, DataElement]
        date: any,  
        ring_count: any, 
        angle_tite: any, 
        angle_rotation: any, 
        angle_x_axis_tilt: any, 
        angle_y_axis_tilt: any, 

        pressure_guidance_system_shield: any, 
        pressure_guidance_system_ground: any, 

        temperature_hyd_oil_tank_cutterhead: any, 
        temperature_hyd_oil_tank_propulsion: any, 

        main_drive_water_temperature: any, 
        main_drive_water_flow: any, 
        main_bearing_oil_flow: any, 
        oil_gas_seal_pressure: any, 
        gear_oil_temperature: any, 
        main_drive_grease_flow: any, 

        cutterhead_speed: any, 
        cutterhead_hyd_pump_outlet_pressure: any, 
        cutterhead_torque: any, 
        cutterhead_oil_leakage_temperature: any, 
        cutterhead_oil_charge_pump_pressure: any, 

        excavation_speed_setting: any, 
        excavation_pump_outlet_pressure: any, 
        excavation_average_speed: any, 
        penetration: any, 
        total_force: any, 
        pressure_auxiliary_pump: any, 

        steering_jack_angle: any, 
        steering_jack_axis_angle: any, 
        steering_jack_ext_1: any, 
        steering_jack_ext_2: any, 
        steering_jack_ext_3: any, 
        steering_jack_ext_4: any, 
        steering_jack_pressure_1: any, 
        steering_jack_pressure_2: any, 
        steering_jack_pressure_3: any, 
        steering_jack_pressure_4: any, 

        bentonite_flow_rate: any, 
        bentonite_pressure: any, 

        excavation_chamber_pressure_gauge_1: any, 
        excavation_chamber_pressure_gauge_2: any, 
        excavation_chamber_pressure_gauge_3: any, 

        slurry_inflow_rate: any, 
        slurry_outflow_rate: any, 
        slurry_inflow_speed_setting_p1_1: any, 
        slurry_inflow_speed_setting_p1_2: any, 
        slurry_outflow_speed_setting_p2_1: any, 
        slurry_outflow_speed_setting_p2_2: any, 
        slurry_outflow_speed_setting_p2_3: any, 
        slurry_inflow_pressure_p1_1: any, 
        slurry_inflow_pressure_p1_2: any, 
        slurry_outflow_pressure_p1_2: any, 
        slurry_outflow_pressure_p1_1: any, 
        slurry_inflow_pressure_p2_1: any, 
        slurry_outflow_pressure_p2_1: any, 
        slurry_inflow_pressure_p2_2: any, 
        slurry_outflow_pressure_p2_2: any, 
        slurry_outflow_speed_p2_1: any, 
        slurry_outflow_speed_p2_2: any, 
        slurry_outflow_current_p2_1: any, 
        slurry_outflow_current_p2_2: any, 

        t11y_transformer_phasel1_voltage: any, 
        t11y_transformer_phasel2_voltage: any, 
        t11y_transformer_phasel3_voltage: any, 
        t11y_transformer_linel1l2_voltage: any, 
        t11y_transformer_linel2l3_voltage: any, 
        t11y_transformer_linel3l1_voltage: any, 
        t11y_transformer_phasel1_current: any, 
        t11y_transformer_phasel2_current: any, 
        t11y_transformer_phasel3_current: any, 
        t11y_transformer_freq: any, 
        t11y_transformer_active_power: any, 
        t11y_transformer_passive_power: any, 
        t11y_transformer_power_factor: any, 
        t11y_transformer_input_power: any, 
        t11y_transformer_output_power: any, 

        feedback_speed_p1_1: any, 
        feedback_current_p1_1: any, 

        excavation_jack_ext_2: any, 
        excavation_jack_ext_5: any, 

        intermediate_jack1_grp1_ext: any, 
        intermediate_jack2_grp1_ext: any, 
        intermediate_jack1_grp2_ext: any, 
        intermediate_jack2_grp2_ext: any, 

        concentration_co2: any, 
        concentration_o2: any, 
        concentration_co: any, 
        concentration_ch4: any, 
        concentration_h2s: any, 

        distance: any, 
    ) => {
        // [fixme, depends on table, DataElement]
        setdate(date)
        setring_count(ring_count)
        setangle_tite(angle_tite)
        setangle_rotation(angle_rotation)
        setangle_x_axis_tilt(angle_x_axis_tilt)
        setangle_y_axis_tilt(angle_y_axis_tilt)

        setpressure_guidance_system_shield(pressure_guidance_system_shield)
        setpressure_guidance_system_ground(pressure_guidance_system_ground)

        settemperature_hyd_oil_tank_cutterhead(temperature_hyd_oil_tank_cutterhead)
        settemperature_hyd_oil_tank_propulsion(temperature_hyd_oil_tank_propulsion)

        setmain_drive_water_temperature(main_drive_water_temperature)
        setmain_drive_water_flow(main_drive_water_flow)
        setmain_bearing_oil_flow(main_bearing_oil_flow)
        setoil_gas_seal_pressure(oil_gas_seal_pressure)
        setgear_oil_temperature(gear_oil_temperature)
        setmain_drive_grease_flow(main_drive_grease_flow)

        setcutterhead_speed(cutterhead_speed)
        setcutterhead_hyd_pump_outlet_pressure(cutterhead_hyd_pump_outlet_pressure)
        setcutterhead_torque(cutterhead_torque)
        setcutterhead_oil_leakage_temperature(cutterhead_oil_leakage_temperature)
        setcutterhead_oil_charge_pump_pressure(cutterhead_oil_charge_pump_pressure)

        setexcavation_speed_setting(excavation_speed_setting)
        setexcavation_pump_outlet_pressure(excavation_pump_outlet_pressure)
        setexcavation_average_speed(excavation_average_speed)
        setpenetration(penetration)
        settotal_force(total_force)
        setpressure_auxiliary_pump(pressure_auxiliary_pump)

        setsteering_jack_angle(steering_jack_angle)
        setsteering_jack_axis_angle(steering_jack_axis_angle)
        setsteering_jack_ext_1(steering_jack_ext_1)
        setsteering_jack_ext_2(steering_jack_ext_2)
        setsteering_jack_ext_3(steering_jack_ext_3)
        setsteering_jack_ext_4(steering_jack_ext_4)
        setsteering_jack_pressure_1(steering_jack_pressure_1)
        setsteering_jack_pressure_2(steering_jack_pressure_2)
        setsteering_jack_pressure_3(steering_jack_pressure_3)
        setsteering_jack_pressure_4(steering_jack_pressure_4)

        setbentonite_flow_rate(bentonite_flow_rate)
        setbentonite_pressure(bentonite_pressure)

        setexcavation_chamber_pressure_gauge_1(excavation_chamber_pressure_gauge_1)
        setexcavation_chamber_pressure_gauge_2(excavation_chamber_pressure_gauge_2)
        setexcavation_chamber_pressure_gauge_3(excavation_chamber_pressure_gauge_3)

        setslurry_inflow_rate(slurry_inflow_rate)
        setslurry_outflow_rate(slurry_outflow_rate)
        setslurry_inflow_speed_setting_p1_1(slurry_inflow_speed_setting_p1_1)
        setslurry_inflow_speed_setting_p1_2(slurry_inflow_speed_setting_p1_2)
        setslurry_outflow_speed_setting_p2_1(slurry_outflow_speed_setting_p2_1)
        setslurry_outflow_speed_setting_p2_2(slurry_outflow_speed_setting_p2_2)
        setslurry_outflow_speed_setting_p2_3(slurry_outflow_speed_setting_p2_3)
        setslurry_inflow_pressure_p1_1(slurry_inflow_pressure_p1_1)
        setslurry_inflow_pressure_p1_2(slurry_inflow_pressure_p1_2)
        setslurry_outflow_pressure_p1_2(slurry_outflow_pressure_p1_2)
        setslurry_outflow_pressure_p1_1(slurry_outflow_pressure_p1_1)
        setslurry_inflow_pressure_p2_1(slurry_inflow_pressure_p2_1)
        setslurry_outflow_pressure_p2_1(slurry_outflow_pressure_p2_1)
        setslurry_inflow_pressure_p2_2(slurry_inflow_pressure_p2_2)
        setslurry_outflow_pressure_p2_2(slurry_outflow_pressure_p2_2)
        setslurry_outflow_speed_p2_1(slurry_outflow_speed_p2_1)
        setslurry_outflow_speed_p2_2(slurry_outflow_speed_p2_2)
        setslurry_outflow_current_p2_1(slurry_outflow_current_p2_1)
        setslurry_outflow_current_p2_2(slurry_outflow_current_p2_2)

        sett11y_transformer_phasel1_voltage(t11y_transformer_phasel1_voltage)
        sett11y_transformer_phasel2_voltage(t11y_transformer_phasel2_voltage)
        sett11y_transformer_phasel3_voltage(t11y_transformer_phasel3_voltage)
        sett11y_transformer_linel1l2_voltage(t11y_transformer_linel1l2_voltage)
        sett11y_transformer_linel2l3_voltage(t11y_transformer_linel2l3_voltage)
        sett11y_transformer_linel3l1_voltage(t11y_transformer_linel3l1_voltage)
        sett11y_transformer_phasel1_current(t11y_transformer_phasel1_current)
        sett11y_transformer_phasel2_current(t11y_transformer_phasel2_current)
        sett11y_transformer_phasel3_current(t11y_transformer_phasel3_current)
        sett11y_transformer_freq(t11y_transformer_freq)
        sett11y_transformer_active_power(t11y_transformer_active_power)
        sett11y_transformer_passive_power(t11y_transformer_passive_power)
        sett11y_transformer_power_factor(t11y_transformer_power_factor)
        sett11y_transformer_input_power(t11y_transformer_input_power)
        sett11y_transformer_output_power(t11y_transformer_output_power)

        setfeedback_speed_p1_1(feedback_speed_p1_1)
        setfeedback_current_p1_1(feedback_current_p1_1)

        setexcavation_jack_ext_2(excavation_jack_ext_2)
        setexcavation_jack_ext_5(excavation_jack_ext_5)

        setintermediate_jack1_grp1_ext(intermediate_jack1_grp1_ext)
        setintermediate_jack2_grp1_ext(intermediate_jack2_grp1_ext)
        setintermediate_jack1_grp2_ext(intermediate_jack1_grp2_ext)
        setintermediate_jack2_grp2_ext(intermediate_jack2_grp2_ext)

        setconcentration_co2(concentration_co2)
        setconcentration_o2(concentration_o2)
        setconcentration_co(concentration_co)
        setconcentration_ch4(concentration_ch4)
        setconcentration_h2s(concentration_h2s)

        setdistance(distance)

        
        setdialogEditOpen(true);
    };
    const closeDialogEdit = () => {
        setdialogEditOpen(false);
    };
    //// button handler - 'confirm'
    const handleconfirmDialogEdit = async () => {

        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_update(
            {
                "date":date,
                "ring_count":ring_count,
                "angle_tite":angle_tite,
                "angle_rotation":angle_rotation,
                "angle_x_axis_tilt":angle_x_axis_tilt,
                "angle_y_axis_tilt":angle_y_axis_tilt,

                "pressure_guidance_system_shield":pressure_guidance_system_shield,
                "pressure_guidance_system_ground":pressure_guidance_system_ground,

                "temperature_hyd_oil_tank_cutterhead":temperature_hyd_oil_tank_cutterhead,
                "temperature_hyd_oil_tank_propulsion":temperature_hyd_oil_tank_propulsion,

                "main_drive_water_temperature":main_drive_water_temperature,
                "main_drive_water_flow":main_drive_water_flow,
                "main_bearing_oil_flow":main_bearing_oil_flow,
                "oil_gas_seal_pressure":oil_gas_seal_pressure,
                "gear_oil_temperature":gear_oil_temperature,
                "main_drive_grease_flow":main_drive_grease_flow,
                
                "cutterhead_speed":cutterhead_speed,
                "cutterhead_hyd_pump_outlet_pressure":cutterhead_hyd_pump_outlet_pressure,
                "cutterhead_torque":cutterhead_torque,
                "cutterhead_oil_leakage_temperature":cutterhead_oil_leakage_temperature,
                "cutterhead_oil_charge_pump_pressure":cutterhead_oil_charge_pump_pressure,

                "excavation_speed_setting":excavation_speed_setting,
                "excavation_pump_outlet_pressure":excavation_pump_outlet_pressure,
                "excavation_average_speed":excavation_average_speed,
                "penetration":penetration,
                "total_force":total_force,
                "pressure_auxiliary_pump":pressure_auxiliary_pump,

                "steering_jack_angle":steering_jack_angle,
                "steering_jack_axis_angle":steering_jack_axis_angle,
                "steering_jack_ext_1":steering_jack_ext_1,
                "steering_jack_ext_2":steering_jack_ext_2,
                "steering_jack_ext_3":steering_jack_ext_3,
                "steering_jack_ext_4":steering_jack_ext_4,
                "steering_jack_pressure_1":steering_jack_pressure_1,
                "steering_jack_pressure_2":steering_jack_pressure_2,
                "steering_jack_pressure_3":steering_jack_pressure_3,
                "steering_jack_pressure_4":steering_jack_pressure_4,

                "bentonite_flow_rate":bentonite_flow_rate,
                "bentonite_pressure":bentonite_pressure,

                "excavation_chamber_pressure_gauge_1":excavation_chamber_pressure_gauge_1,
                "excavation_chamber_pressure_gauge_2":excavation_chamber_pressure_gauge_2,
                "excavation_chamber_pressure_gauge_3":excavation_chamber_pressure_gauge_3,

                "slurry_inflow_rate":slurry_inflow_rate,
                "slurry_outflow_rate":slurry_outflow_rate,
                "slurry_inflow_speed_setting_p1_1":slurry_inflow_speed_setting_p1_1,
                "slurry_inflow_speed_setting_p1_2":slurry_inflow_speed_setting_p1_2,
                "slurry_outflow_speed_setting_p2_1":slurry_outflow_speed_setting_p2_1,
                "slurry_outflow_speed_setting_p2_2":slurry_outflow_speed_setting_p2_2,
                "slurry_outflow_speed_setting_p2_3":slurry_outflow_speed_setting_p2_3,
                "slurry_inflow_pressure_p1_1":slurry_inflow_pressure_p1_1,
                "slurry_inflow_pressure_p1_2":slurry_inflow_pressure_p1_2,
                "slurry_outflow_pressure_p1_2":slurry_outflow_pressure_p1_2,
                "slurry_outflow_pressure_p1_1":slurry_outflow_pressure_p1_1,
                "slurry_inflow_pressure_p2_1":slurry_inflow_pressure_p2_1,
                "slurry_outflow_pressure_p2_1":slurry_outflow_pressure_p2_1,
                "slurry_inflow_pressure_p2_2":slurry_inflow_pressure_p2_2,
                "slurry_outflow_pressure_p2_2":slurry_outflow_pressure_p2_2,
                "slurry_outflow_speed_p2_1":slurry_outflow_speed_p2_1,
                "slurry_outflow_speed_p2_2":slurry_outflow_speed_p2_2,
                "slurry_outflow_current_p2_1":slurry_outflow_current_p2_1,
                "slurry_outflow_current_p2_2":slurry_outflow_current_p2_2,

                "t11y_transformer_phasel1_voltage":t11y_transformer_phasel1_voltage,
                "t11y_transformer_phasel2_voltage":t11y_transformer_phasel2_voltage,
                "t11y_transformer_phasel3_voltage":t11y_transformer_phasel3_voltage,
                "t11y_transformer_linel1l2_voltage":t11y_transformer_linel1l2_voltage,
                "t11y_transformer_linel2l3_voltage":t11y_transformer_linel2l3_voltage,
                "t11y_transformer_linel3l1_voltage":t11y_transformer_linel3l1_voltage,
                "t11y_transformer_phasel1_current":t11y_transformer_phasel1_current,
                "t11y_transformer_phasel2_current":t11y_transformer_phasel2_current,
                "t11y_transformer_phasel3_current":t11y_transformer_phasel3_current,
                "t11y_transformer_freq":t11y_transformer_freq,
                "t11y_transformer_active_power":t11y_transformer_active_power,
                "t11y_transformer_passive_power":t11y_transformer_passive_power,
                "t11y_transformer_power_factor":t11y_transformer_power_factor,
                "t11y_transformer_input_power":t11y_transformer_input_power,
                "t11y_transformer_output_power":t11y_transformer_output_power,

                "feedback_speed_p1_1":feedback_speed_p1_1,
                "feedback_current_p1_1":feedback_current_p1_1,

                "excavation_jack_ext_2":excavation_jack_ext_2,
                "excavation_jack_ext_5":excavation_jack_ext_5,

                "intermediate_jack1_grp1_ext":intermediate_jack1_grp1_ext,
                "intermediate_jack2_grp1_ext":intermediate_jack2_grp1_ext,
                "intermediate_jack1_grp2_ext":intermediate_jack1_grp2_ext,
                "intermediate_jack2_grp2_ext":intermediate_jack2_grp2_ext,

                "concentration_co2":concentration_co2,
                "concentration_o2":concentration_o2,
                "concentration_co":concentration_co,
                "concentration_ch4":concentration_ch4,
                "concentration_h2s":concentration_h2s,

                "distance":distance
            }
        )
        console.log(" [handleconfirmDialogEdit] responsedata", responsedata);

        // [fixme, depends on table, DataElement]
        setdate('')
        setring_count(0)
        setangle_tite(0)
        setangle_rotation(0)
        setangle_x_axis_tilt(0)
        setangle_y_axis_tilt(0)

        setpressure_guidance_system_shield(0)
        setpressure_guidance_system_ground(0)

        settemperature_hyd_oil_tank_cutterhead(0)
        settemperature_hyd_oil_tank_propulsion(0)

        setmain_drive_water_temperature(0)
        setmain_drive_water_flow(0)
        setmain_bearing_oil_flow(0)
        setoil_gas_seal_pressure(0)
        setgear_oil_temperature(0)
        setmain_drive_grease_flow(0)

        setcutterhead_speed(0)
        setcutterhead_hyd_pump_outlet_pressure(0)
        setcutterhead_torque(0)
        setcutterhead_oil_leakage_temperature(0)
        setcutterhead_oil_charge_pump_pressure(0)

        setexcavation_speed_setting(0)
        setexcavation_pump_outlet_pressure(0)
        setexcavation_average_speed(0)
        setpenetration(0)
        settotal_force(0)
        setpressure_auxiliary_pump(0)

        setsteering_jack_angle(0)
        setsteering_jack_axis_angle(0)
        setsteering_jack_ext_1(0)
        setsteering_jack_ext_2(0)
        setsteering_jack_ext_3(0)
        setsteering_jack_ext_4(0)
        setsteering_jack_pressure_1(0)
        setsteering_jack_pressure_2(0)
        setsteering_jack_pressure_3(0)
        setsteering_jack_pressure_4(0)

        setbentonite_flow_rate(0)
        setbentonite_pressure(0)

        setexcavation_chamber_pressure_gauge_1(0)
        setexcavation_chamber_pressure_gauge_2(0)
        setexcavation_chamber_pressure_gauge_3(0)

        setslurry_inflow_rate(0)
        setslurry_outflow_rate(0)
        setslurry_inflow_speed_setting_p1_1(0)
        setslurry_inflow_speed_setting_p1_2(0)
        setslurry_outflow_speed_setting_p2_1(0)
        setslurry_outflow_speed_setting_p2_2(0)
        setslurry_outflow_speed_setting_p2_3(0)
        setslurry_inflow_pressure_p1_1(0)
        setslurry_inflow_pressure_p1_2(0)
        setslurry_outflow_pressure_p1_2(0)
        setslurry_outflow_pressure_p1_1(0)
        setslurry_inflow_pressure_p2_1(0)
        setslurry_outflow_pressure_p2_1(0)
        setslurry_inflow_pressure_p2_2(0)
        setslurry_outflow_pressure_p2_2(0)
        setslurry_outflow_speed_p2_1(0)
        setslurry_outflow_speed_p2_2(0)
        setslurry_outflow_current_p2_1(0)
        setslurry_outflow_current_p2_2(0)

        sett11y_transformer_phasel1_voltage(0)
        sett11y_transformer_phasel2_voltage(0)
        sett11y_transformer_phasel3_voltage(0)
        sett11y_transformer_linel1l2_voltage(0)
        sett11y_transformer_linel2l3_voltage(0)
        sett11y_transformer_linel3l1_voltage(0)
        sett11y_transformer_phasel1_current(0)
        sett11y_transformer_phasel2_current(0)
        sett11y_transformer_phasel3_current(0)
        sett11y_transformer_freq(0)
        sett11y_transformer_active_power(0)
        sett11y_transformer_passive_power(0)
        sett11y_transformer_power_factor(0)
        sett11y_transformer_input_power(0)
        sett11y_transformer_output_power(0)
        
        setfeedback_speed_p1_1(0)
        setfeedback_current_p1_1(0)
        
        setexcavation_jack_ext_2(0)
        setexcavation_jack_ext_5(0)

        setintermediate_jack1_grp1_ext(0)
        setintermediate_jack2_grp1_ext(0)
        setintermediate_jack1_grp2_ext(0)
        setintermediate_jack2_grp2_ext(0)

        setconcentration_co2(0)
        setconcentration_o2(0)
        setconcentration_co(0)
        setconcentration_ch4(0)
        setconcentration_h2s(0)

        setdistance(0)

        
        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }
    }


    //// button handler - 'delete', no POPup
    const handleDelete = async (inputId) => {

        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_delete(
            {
                "ids": [inputId]
            }
        )
        console.log(" [handleDelete] responsedata", responsedata);
        
        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }
    }


    //// button handler - 'export excel', no POPup
    const handleExportExcel = async () => {

        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_exportexcel("pipe_jacking_machine.xlsx")
        console.log(" [handleExportExcel] responsedata", responsedata);
    }


    //// for POPup, DialogimportExcelFile, 'import Excel'
    const [dialogimportExcelFileOpen, setdialogimportExcelFileOpen] = useState(false);
    const [importExcelFile, setimportExcelFile] = useState('');
    const [onloadprogressExcelFile, setonloadprogressExcelFile] = useState(0);
    const importExcelFileHandler = (e: any) => {
        console.log("[importExcelFileHandler] e.target:", e.target)        
        console.log("[importExcelFileHandler] e.target.files[0]:", e.target.files[0])
        setimportExcelFile(e.target.files[0])
    }
    const opendialogimportExcelFile = (type) => {
        
        // // [fixme]
        // if (type == 1) {
        //     setdialogOperationResultState(true)
        // } else {
        //     setdialogOperationResultState(false)
        // }

        // closeDialogEdit()
        setonloadprogressExcelFile(0)
        setdialogimportExcelFileOpen(true);
    };
    const closedialogimportExcelFile = () => {
        setdialogimportExcelFileOpen(false);
    };
    const handleconfirmImportExcel = async () => {
        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_importfromexcel(importExcelFile, 
            (event) => {
                setonloadprogressExcelFile(
                    Math.round((100 * event.loaded) / event.total)
                )
                console.log("[handleconfirmImportExcel] Math.round((100 * event.loaded) / event.total):", Math.round((100 * event.loaded) / event.total))
                console.log("[handleconfirmImportExcel] onloadprogressExcelFile:", onloadprogressExcelFile)
            }
        )
        console.log(" [handleconfirmImportExcel] responsedata", responsedata) 

        // fixme: operationStatus, loading status bar
        setonloadprogressExcelFile(0)

        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }

        closedialogimportExcelFile()
    }    


    //// for POPup, DialogOperationResult, input -> result (success / failure)
    const [dialogOperationResultOpen, setdialogOperationResultOpen] = useState(false);
    const [dialogOperationResultState, setdialogOperationResultState] = useState(true);
    const openDialogOperationResult = (type) => {
        
        // [fixme]
        if (type === 1) {
            setdialogOperationResultState(true)
        } else {
            setdialogOperationResultState(false)
        }

        closeDialogEdit()
        setdialogOperationResultOpen(true);
    };
    const closeDialogOperationResult = () => {
        setdialogOperationResultOpen(false);
    };


    //// for POPup, DialogAdd
    const [dialogAddOpen, setdialogAddOpen] = useState(false);
    const openDialogAdd = (type: any) => {   
        setdialogAddOpen(true);
    };
    const closeDialogAdd = () => {
        setdialogAddOpen(false);
    };
    const handleconfirmDialogAdd = async () => {

        // [fixme, depends on table, DataElement]        
        const responsedata = await apifetch_add(
            {
                "date":date,
                "ring_count":ring_count,
                "angle_tite":angle_tite,
                "angle_rotation":angle_rotation,
                "angle_x_axis_tilt":angle_x_axis_tilt,
                "angle_y_axis_tilt":angle_y_axis_tilt,

                "pressure_guidance_system_shield":pressure_guidance_system_shield,
                "pressure_guidance_system_ground":pressure_guidance_system_ground,

                "temperature_hyd_oil_tank_cutterhead":temperature_hyd_oil_tank_cutterhead,
                "temperature_hyd_oil_tank_propulsion":temperature_hyd_oil_tank_propulsion,

                "main_drive_water_temperature":main_drive_water_temperature,
                "main_drive_water_flow":main_drive_water_flow,
                "main_bearing_oil_flow":main_bearing_oil_flow,
                "oil_gas_seal_pressure":oil_gas_seal_pressure,
                "gear_oil_temperature":gear_oil_temperature,
                "main_drive_grease_flow":main_drive_grease_flow,
                
                "cutterhead_speed":cutterhead_speed,
                "cutterhead_hyd_pump_outlet_pressure":cutterhead_hyd_pump_outlet_pressure,
                "cutterhead_torque":cutterhead_torque,
                "cutterhead_oil_leakage_temperature":cutterhead_oil_leakage_temperature,
                "cutterhead_oil_charge_pump_pressure":cutterhead_oil_charge_pump_pressure,

                "excavation_speed_setting":excavation_speed_setting,
                "excavation_pump_outlet_pressure":excavation_pump_outlet_pressure,
                "excavation_average_speed":excavation_average_speed,
                "penetration":penetration,
                "total_force":total_force,
                "pressure_auxiliary_pump":pressure_auxiliary_pump,

                "steering_jack_angle":steering_jack_angle,
                "steering_jack_axis_angle":steering_jack_axis_angle,
                "steering_jack_ext_1":steering_jack_ext_1,
                "steering_jack_ext_2":steering_jack_ext_2,
                "steering_jack_ext_3":steering_jack_ext_3,
                "steering_jack_ext_4":steering_jack_ext_4,
                "steering_jack_pressure_1":steering_jack_pressure_1,
                "steering_jack_pressure_2":steering_jack_pressure_2,
                "steering_jack_pressure_3":steering_jack_pressure_3,
                "steering_jack_pressure_4":steering_jack_pressure_4,

                "bentonite_flow_rate":bentonite_flow_rate,
                "bentonite_pressure":bentonite_pressure,

                "excavation_chamber_pressure_gauge_1":excavation_chamber_pressure_gauge_1,
                "excavation_chamber_pressure_gauge_2":excavation_chamber_pressure_gauge_2,
                "excavation_chamber_pressure_gauge_3":excavation_chamber_pressure_gauge_3,

                "slurry_inflow_rate":slurry_inflow_rate,
                "slurry_outflow_rate":slurry_outflow_rate,
                "slurry_inflow_speed_setting_p1_1":slurry_inflow_speed_setting_p1_1,
                "slurry_inflow_speed_setting_p1_2":slurry_inflow_speed_setting_p1_2,
                "slurry_outflow_speed_setting_p2_1":slurry_outflow_speed_setting_p2_1,
                "slurry_outflow_speed_setting_p2_2":slurry_outflow_speed_setting_p2_2,
                "slurry_outflow_speed_setting_p2_3":slurry_outflow_speed_setting_p2_3,
                "slurry_inflow_pressure_p1_1":slurry_inflow_pressure_p1_1,
                "slurry_inflow_pressure_p1_2":slurry_inflow_pressure_p1_2,
                "slurry_outflow_pressure_p1_2":slurry_outflow_pressure_p1_2,
                "slurry_outflow_pressure_p1_1":slurry_outflow_pressure_p1_1,
                "slurry_inflow_pressure_p2_1":slurry_inflow_pressure_p2_1,
                "slurry_outflow_pressure_p2_1":slurry_outflow_pressure_p2_1,
                "slurry_inflow_pressure_p2_2":slurry_inflow_pressure_p2_2,
                "slurry_outflow_pressure_p2_2":slurry_outflow_pressure_p2_2,
                "slurry_outflow_speed_p2_1":slurry_outflow_speed_p2_1,
                "slurry_outflow_speed_p2_2":slurry_outflow_speed_p2_2,
                "slurry_outflow_current_p2_1":slurry_outflow_current_p2_1,
                "slurry_outflow_current_p2_2":slurry_outflow_current_p2_2,

                "t11y_transformer_phasel1_voltage":t11y_transformer_phasel1_voltage,
                "t11y_transformer_phasel2_voltage":t11y_transformer_phasel2_voltage,
                "t11y_transformer_phasel3_voltage":t11y_transformer_phasel3_voltage,
                "t11y_transformer_linel1l2_voltage":t11y_transformer_linel1l2_voltage,
                "t11y_transformer_linel2l3_voltage":t11y_transformer_linel2l3_voltage,
                "t11y_transformer_linel3l1_voltage":t11y_transformer_linel3l1_voltage,
                "t11y_transformer_phasel1_current":t11y_transformer_phasel1_current,
                "t11y_transformer_phasel2_current":t11y_transformer_phasel2_current,
                "t11y_transformer_phasel3_current":t11y_transformer_phasel3_current,
                "t11y_transformer_freq":t11y_transformer_freq,
                "t11y_transformer_active_power":t11y_transformer_active_power,
                "t11y_transformer_passive_power":t11y_transformer_passive_power,
                "t11y_transformer_power_factor":t11y_transformer_power_factor,
                "t11y_transformer_input_power":t11y_transformer_input_power,
                "t11y_transformer_output_power":t11y_transformer_output_power,

                "feedback_speed_p1_1":feedback_speed_p1_1,
                "feedback_current_p1_1":feedback_current_p1_1,

                "excavation_jack_ext_2":excavation_jack_ext_2,
                "excavation_jack_ext_5":excavation_jack_ext_5,

                "intermediate_jack1_grp1_ext":intermediate_jack1_grp1_ext,
                "intermediate_jack2_grp1_ext":intermediate_jack2_grp1_ext,
                "intermediate_jack1_grp2_ext":intermediate_jack1_grp2_ext,
                "intermediate_jack2_grp2_ext":intermediate_jack2_grp2_ext,

                "concentration_co2":concentration_co2,
                "concentration_o2":concentration_o2,
                "concentration_co":concentration_co,
                "concentration_ch4":concentration_ch4,
                "concentration_h2s":concentration_h2s,

                "distance":distance
            }
        )
        console.log(" [handleconfirmDialogAdd] responsedata:", responsedata)

        // [fixme, depends on table, DataElement]
        setdate('')
        setring_count(0)
        setangle_tite(0)
        setangle_rotation(0)
        setangle_x_axis_tilt(0)
        setangle_y_axis_tilt(0)

        setpressure_guidance_system_shield(0)
        setpressure_guidance_system_ground(0)

        settemperature_hyd_oil_tank_cutterhead(0)
        settemperature_hyd_oil_tank_propulsion(0)

        setmain_drive_water_temperature(0)
        setmain_drive_water_flow(0)
        setmain_bearing_oil_flow(0)
        setoil_gas_seal_pressure(0)
        setgear_oil_temperature(0)
        setmain_drive_grease_flow(0)

        setcutterhead_speed(0)
        setcutterhead_hyd_pump_outlet_pressure(0)
        setcutterhead_torque(0)
        setcutterhead_oil_leakage_temperature(0)
        setcutterhead_oil_charge_pump_pressure(0)

        setexcavation_speed_setting(0)
        setexcavation_pump_outlet_pressure(0)
        setexcavation_average_speed(0)
        setpenetration(0)
        settotal_force(0)
        setpressure_auxiliary_pump(0)

        setsteering_jack_angle(0)
        setsteering_jack_axis_angle(0)
        setsteering_jack_ext_1(0)
        setsteering_jack_ext_2(0)
        setsteering_jack_ext_3(0)
        setsteering_jack_ext_4(0)
        setsteering_jack_pressure_1(0)
        setsteering_jack_pressure_2(0)
        setsteering_jack_pressure_3(0)
        setsteering_jack_pressure_4(0)

        setbentonite_flow_rate(0)
        setbentonite_pressure(0)

        setexcavation_chamber_pressure_gauge_1(0)
        setexcavation_chamber_pressure_gauge_2(0)
        setexcavation_chamber_pressure_gauge_3(0)

        setslurry_inflow_rate(0)
        setslurry_outflow_rate(0)
        setslurry_inflow_speed_setting_p1_1(0)
        setslurry_inflow_speed_setting_p1_2(0)
        setslurry_outflow_speed_setting_p2_1(0)
        setslurry_outflow_speed_setting_p2_2(0)
        setslurry_outflow_speed_setting_p2_3(0)
        setslurry_inflow_pressure_p1_1(0)
        setslurry_inflow_pressure_p1_2(0)
        setslurry_outflow_pressure_p1_2(0)
        setslurry_outflow_pressure_p1_1(0)
        setslurry_inflow_pressure_p2_1(0)
        setslurry_outflow_pressure_p2_1(0)
        setslurry_inflow_pressure_p2_2(0)
        setslurry_outflow_pressure_p2_2(0)
        setslurry_outflow_speed_p2_1(0)
        setslurry_outflow_speed_p2_2(0)
        setslurry_outflow_current_p2_1(0)
        setslurry_outflow_current_p2_2(0)

        sett11y_transformer_phasel1_voltage(0)
        sett11y_transformer_phasel2_voltage(0)
        sett11y_transformer_phasel3_voltage(0)
        sett11y_transformer_linel1l2_voltage(0)
        sett11y_transformer_linel2l3_voltage(0)
        sett11y_transformer_linel3l1_voltage(0)
        sett11y_transformer_phasel1_current(0)
        sett11y_transformer_phasel2_current(0)
        sett11y_transformer_phasel3_current(0)
        sett11y_transformer_freq(0)
        sett11y_transformer_active_power(0)
        sett11y_transformer_passive_power(0)
        sett11y_transformer_power_factor(0)
        sett11y_transformer_input_power(0)
        sett11y_transformer_output_power(0)
        
        setfeedback_speed_p1_1(0)
        setfeedback_current_p1_1(0)
        
        setexcavation_jack_ext_2(0)
        setexcavation_jack_ext_5(0)

        setintermediate_jack1_grp1_ext(0)
        setintermediate_jack2_grp1_ext(0)
        setintermediate_jack1_grp2_ext(0)
        setintermediate_jack2_grp2_ext(0)

        setconcentration_co2(0)
        setconcentration_o2(0)
        setconcentration_co(0)
        setconcentration_ch4(0)
        setconcentration_h2s(0)

        setdistance(0)

        
        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }

        closeDialogAdd()
    }
    



    //// CSS related
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            themeStyleRoot: {
                padding: theme.spacing(2),
                flexGrow: 1,
            },
            text_center: {
                textAlign: 'center'
            },
            button_icon: {
                color: "#fff",
                backgroundColor: "#2aa10f",
                height: "30px",
                width: "30px",
            },
            textfield_input: {
                marginLeft: theme.spacing(1),
                flex: 1,
            },
            iconButton_menu: {
                padding: 10,
            },
            iconButton_search: {
                padding: 10,
            },
            margin: {
                margin: theme.spacing(0),
                height: "20px",
            },
            themeTabRoot: {
                backgroundColor: theme.palette.background.paper,
                width: "100%",
            },            
        })
    );
    const classesStyle = useStyles();



    //////////////////////////////////////////////////////////////////////////////
    ////// [Graph / Plot] logic --- start
    //      - category is 'date'
    //        - block is 'legend' on right
    //        - 'date' data in a group -> present in 'candle box' format
    //        - skip '0'?
    const [loading, setLoading] = useState<boolean>(true);
    
    // for Plotly!!
    const [datacandlebox1, setdatacandlebox1]=useState([]);
    // const [datacandlebox2, setdatacandlebox2]=useState([]);
    const rowsPerPageGraph = useRef(50);
    

    ////// backend api --- start
    //// api, getALL
    const initPlotData = async () => {

        setLoading(true);
        const responsedata = await apifetch_get(
            {
                // "page_number": page.current+1,
                "page_number": 1,
                "item_per_page": rowsPerPageGraph.current,
                // "item_per_page": 200,
                "sequence": "descending"
            }
        );
        console.log("[initPlotData] responsedata", responsedata);
        console.log("[initPlotData] responsedata.schema_template", responsedata.schema_template);

        // transform1_initPlotData(responsedata.schema_template)
    }
    //// api, search
    // const [searchTextGraph, setsearchTextGraph] = useState('')
    // const searchHandlerGraph = (e: any) => {
    //     setsearchTextGraph(e.target.value)
    // }
    // const searchtemplatedataGraph = async () => {
    //     const responsedata = await apifetch_search(
    //         {
    //             // "page_number": page.current+1,
    //             "page_number": 1,
    //             "item_per_page": rowsPerPageGraph.current,
    //             "sequence": "descending",
    //             "search_param": searchText
    //         }
    //     );        
    //     console.log("[searchtemplatedataGraph] responsedata:", responsedata);
    //     transform1_initPlotData(responsedata.settlement_monitorings)
    // }
    ////// backend api --- end


    //// transform 1 --- category
    //     - x : category of DATE (no time)
    //     - y1: @DATE CATEGORY -> ARRAY of DATA
    //          - non zero guard
    //          - e.g. 2023-06-02T00:00:00Z, 2023-06-02T01:00:00Z, SAME @DATE CATEGORY
    //          - x to y1, x to y2, x to y3 ...
    const transform1_initPlotData = (response_initPlotData) => {

        let timestamp_splits_date_pre: string;
        let category_counter: number;

        let x: any = [];
        let y: any = [];
        let y_array_element: any = [];
        let y2: any = [];
        let y2_array_element: any = [];
        let y3: any = [];
        let y3_array_element: any = [];

        const records = response_initPlotData        

        const data_y_length = records.length;
        console.log("[transform1_initPlotData, entry] data_y_length:", data_y_length)
        // (only for my console visualization, not related to any STATE variable)
        category_counter = -1;
        records.forEach( (datum: any, i: number) => {
            console.log("### [transform1_initPlotData] (start) loop:", i)
        
            
            //// guard / filter / skip, non ZERO value (use 'return' like 'continue', in for loop)
            // if (datum["verticaloffset"] === 0 || datum["humidity"] === 0){
            //     return;
            // }

            const timestamp_splits_date_current = datum["date"].split(" ");
            console.log(
                "[useEffect2] idea... timestamp_splits_date_current[0]:",
                timestamp_splits_date_current[0]
            );
            
            if (timestamp_splits_date_current[0] !== timestamp_splits_date_pre) {

                category_counter = category_counter +1;

                timestamp_splits_date_pre = timestamp_splits_date_current[0];
                console.log(
                    "category new, timestamp_splits_date_current[0]:",
                    timestamp_splits_date_current[0]
                );
                console.log(
                    "category new, timestamp_splits_date_pre:",
                    timestamp_splits_date_pre
                );

                x.push(timestamp_splits_date_current[0]);

        //// CASE 1 --- category DIFFERENT, first one --- accumulate (y_array_element) to (y_array_element)
                if (category_counter == 0) {

                    console.log("[transform1_initPlotData] case 1")

                    console.log(
                        i, 
                        "category new, category_counter:", category_counter);

                    y_array_element.push(datum["measured_peak_aop"]);
                    y2_array_element.push(datum["type_of_rock_blasted"]);
                    // y3_array_element.push(datum["rainfall"]);
                    console.log(
                        i,
                        "[1st] category new", category_counter, ", y_array_element:",
                        y_array_element
                    );
                    console.log(
                        i,
                        "[1st] category new", category_counter, ", y2_array_element:",
                        y2_array_element
                    );
                    console.log(
                        i,
                        "[1st] category new", category_counter, ", y3_array_element:",
                        y3_array_element
                    );

                    return
                }
                
                console.log("[transform1_initPlotData] case X + 2")
                
        //// CASE 2 --- category DIFFERENT, NOT first one 
        //    --2a- save / commit (y_array_element) to (y, finish a category)
        //    --2b- accumulate (y_array_element) to ( NEW y_array_element)

                //    --2a- save / commit (y_array_element) to (y, finish a category)
                y.push(y_array_element);
                y2.push(y2_array_element);
                y3.push(y3_array_element);
                console.log(
                    i,
                    "category new", category_counter, ", y_array_element:",
                    y_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y2_array_element:",
                    y2_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y3_array_element:",
                    y3_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y:", y);
                console.log(
                    i,
                    "category new", category_counter, ", y2:", y2);
                console.log(
                    i,
                    "category new", category_counter, ", y3:", y3);
                

                //    --2b- accumulate (y_array_element) to ( NEW y_array_element)
                y_array_element = [];
                y2_array_element = [];
                y3_array_element = [];
                y_array_element.push(datum["measured_peak_aop"]);
                y2_array_element.push(datum["type_of_rock_blasted"]);
                // y3_array_element.push(datum["rainfall"]);
                console.log(
                    i,
                    "category new", category_counter,", y_array_element:",
                    y_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y2_array_element:",
                    y2_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y3_array_element:",
                    y3_array_element
                );

        //// CASE 5 --- category DIFFERENT, last one --- save / commit (y_array_element) to (y, finish a category)
        //              - do it before guard ZERO!!!!!        
                if (i == data_y_length - 1) {

                    console.log("[transform1_initPlotData] case 5")
                    console.log(" *** [last one] category new, category_counter:", category_counter);

                    y.push(y_array_element);
                    y2.push(y2_array_element);
                    y3.push(y3_array_element);
                    console.log(
                        i,
                        "[last one] category new", category_counter, ", y_array_element:",
                        y_array_element
                    );
                    console.log(
                        i,
                        "[last one] category new", category_counter, ", y2_array_element:",
                        y2_array_element
                    );
                    console.log(
                        i,
                        "[last one] category new", category_counter, ", y3_array_element:",
                        y3_array_element
                    );
                    console.log(
                        i,
                        "[last one] category new", category_counter, ", y:", y);
                    console.log(
                        i,
                        "[last one] category new", category_counter, ", y2:", y2);
                    console.log(
                        i,
                        "[last one] category new", category_counter, ", y3:", y3);
                }

            } else {

                console.log("[transform1_initPlotData] case X + 3")
        //// CASE 3 --- category SAME, NOT last one --- accumulate (y_array_element) to (y_array_element)
                console.log(
                    "category old, timestamp_splits_date_current:",
                    timestamp_splits_date_current[0]
                );
                console.log(
                    "category old, timestamp_splits_date_pre:",
                    timestamp_splits_date_pre
                );
                y_array_element.push(datum["measured_peak_aop"]);
                y2_array_element.push(datum["type_of_rock_blasted"]);
                y3_array_element.push(datum["rainfall"]);
                console.log(
                    i,
                    "category old", category_counter, ", datum[verticaloffset]:",
                    datum["verticaloffset"]
                );
                console.log(
                    i,
                    "category old", category_counter, ", y_array_element:", y_array_element);
                console.log(
                    i,
                    "category old", category_counter, ", y2_array_element:", y2_array_element);
                console.log(
                    i,
                    "category old", category_counter, ", y3_array_element:", y3_array_element);

                    console.log("[transform1_initPlotData] case X + 4")
        //// CASE 4 --- category SAME, last one --- save / commit (y_array_element) to (y, finish a category)
                if (i == data_y_length - 1) {
                    console.log(
                        i,
                        "[last one] category old, category_counter:", category_counter);
                    y.push(y_array_element);
                    y2.push(y2_array_element);
                    y3.push(y3_array_element);
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y_array_element:",
                        y_array_element
                    );
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y2_array_element:",
                        y2_array_element
                    );
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y3_array_element:",
                        y3_array_element
                    );
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y:", y);
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y2:", y2);
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y3:", y3);
        
                    category_counter = 0;
                    y_array_element = [];
                    y2_array_element = [];
                    y3_array_element = [];

                    console.log("... [cleaning] after [last one] category old ...")
                    console.log(
                        "[cleaning, check] y_array_element:",
                        y_array_element
                    );
                    console.log(
                        "[cleaning, check] y2_array_element:",
                        y2_array_element
                    );
                    console.log(
                        "[cleaning, check] y3_array_element:",
                        y3_array_element
                    );
                }
            }
            console.log("### [transform1_initPlotData] loop:", i, ", y_array_element:", y_array_element, ", y:", y)
        })
        console.log(" [transform1_initPlotData, end] x:", x)
        console.log(" [transform1_initPlotData, end] y:", y)
        transform2_transform1data_candlebox(x, y)
    }
    
    //// transform 2 --- candle data format, fit for plotly
    function transform2_transform1data_candlebox(dataploy_x, dataploy_y) {        
        let data_candlebox: any = [];
        if (dataploy_x !== undefined && dataploy_y !== undefined) {
            for (var i = 0; i < dataploy_x.length; i++) {
                var result = {
                type: "box",
                y: dataploy_y[i],
                name: dataploy_x[i],
                boxpoints: "all",
                jitter: 0.5,
                whiskerwidth: 0.2,
                fillcolor: "cls",
                marker: {
                    size: 5,
                },
                line: {
                    width: 1,
                },
                };            
                data_candlebox.push(result);
            }          
            console.log("[transform2, endA] data_candlebox", data_candlebox);

            // setdatacandlebox1( ... datacandlebox1, data_candlebox)
            setdatacandlebox1( data_candlebox )

        } else {
          console.log("[transform2, endB] !data_candlebox, dataploy_x", dataploy_x);
        }
        
        setLoading(false);
    }
    
    //// startup, data reading - api, getALL
    useEffect(() => {
        async function initGraph() {
            initPlotData()
        }
        initGraph();
    }, []);

    //// non-startup, data reading
    //    - (table) if there exist 'searchText', then response the needed data
    //    - if there exist NO 'searchText', then response 'getALL'
    const reloadgraphdata = () => {
        // if (searchTextGraph) {
        //     searchtemplatedataGraph()
        // } else {
        //     initPlotData();
        // }
        initPlotData();
    }

    //// number of records to show on graph
    const handleChangeRowsPerPageGraph = (event: any) => {
        rowsPerPageGraph.current = parseInt(event.target.value);
        reloadgraphdata()
    };

    ////// [Graph / Plot] logic --- end
    //////////////////////////////////////////////////////////////////////////////




    //////////////////////////////////////////////////////////////////////////////
    ////// [Tab / Panel] logic --- start
    const theme = useTheme();
    const [tabvalue, settabvalue] = React.useState(0);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        settabvalue(newValue);
    }    
    const handleTabChangeIndex = (index: number) => {
        settabvalue(index);
    };    

    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: any;
        value: any;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    function tabProps(index: any) {
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
    }
    ////// [Tab / Panel] logic --- end
    //////////////////////////////////////////////////////////////////////////////




    return (
        <div className={classesStyle.themeStyleRoot}>
            <Container>
                <br />
                <br />
                <br />

                <h1 className='tal pb10'> Pipe Jacking Machine </h1>








                {/* ////// [Tab / Panel] UI --- start */}
                {/* <Paper>
                    <div className={classesStyle.themeTabRoot}>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={tabvalue}
                            onChangeIndex={handleTabChangeIndex}
                        >
                            <TabPanel value={tabvalue} index={0} dir={theme.direction}>
                                abc
                            </TabPanel>
                            <TabPanel value={tabvalue} index={1} dir={theme.direction}>
                                123
                            </TabPanel>
                            <TabPanel value={tabvalue} index={2} dir={theme.direction}>
                                ABC
                            </TabPanel>
                        </SwipeableViews>
                        <AppBar position="static" color="transparent">
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >                                
                                <Tab label="Tab1" {...tabProps(0)} />
                                <Tab label="Tab2" {...tabProps(1)} />
                                <Tab label="Tab3" {...tabProps(2)} />
                            </Tabs>
                        </AppBar>
                    </div>
                </Paper> */}
                {/* ////// [Tab / Panel] UI --- end */}








                
                <hr />
                <br /> 

                







                {/* ////// [Graph / Plot] UI --- start */}                
                {/* {console.log("[main, return] dataPlot.x:", dataPlot.x)}
                {console.log("[main, return] dataPlot.y:", dataPlot.y)} */}                
                {console.log("[main, return] loading:", loading)}                
                {console.log("[main, return] datacandlebox1", datacandlebox1)}

                {/* <h3 className='w-grey'> &nbsp; Graph
                    &nbsp;&nbsp;                    
                    <IconButton onClick={reloadgraphdata} className={classesStyle.button_icon}>
                        <Refresh />
                    </IconButton>                    
                </h3>

                <br/>
                {loading ? (
                    <div className="text-center">
                        <Table>
                        <tbody>
                            <tr>
                                <Image src={ImageLoading} fluid />                                
                            </tr>
                            <tr>
                            </tr>
                        </tbody>
                        </Table>
                    </div>
                    ) : (
                    <div>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="age-native-simple"> &nbsp;&nbsp; Number of Display Records</InputLabel>
                            <Select
                                native
                                value={rowsPerPageGraph.current}
                                onChange={handleChangeRowsPerPageGraph}
                            >
                                <option aria-label="None" value="" />
                                <option value={50}>  &nbsp;&nbsp;&nbsp;&nbsp; 50</option>
                                <option value={500}> &nbsp;&nbsp;&nbsp;&nbsp; 500</option>
                                <option value={1000}>&nbsp;&nbsp;&nbsp;&nbsp; 1000</option>
                                <option value={2000}>&nbsp;&nbsp;&nbsp;&nbsp; 2000</option>
                                <option value={5000}>&nbsp;&nbsp;&nbsp;&nbsp; 5000</option>
                            </Select>
                        </FormControl> 
                        <Plot
                            config={{ displayModeBar: false }}
                            data={datacandlebox1}
                            layout={{
                                margin: {
                                    l: 60,
                                    r: 50,
                                    b: 50,
                                    t: 50,
                                    pad: 0,
                                },
                                autosize: true,
                                xaxis: {
                                type: "date",
                                rangeslider: { visible: true },
                                // domain: [0, 0.9],
                                // title: "Device - AM1",
                                },
                                yaxis: {
                                    title: "measured peak aop",
                                side: "left",
                                // domain: [0.84, 1],
                                },
                            }}
                            useResizeHandler={true}
                            style={{ width: "100%", height: "100%" }}
                        />
                    </div>
                    )
                } */}
                {/* ////// [Graph / Plot] UI --- end */}










                {/* <hr/>
                <br/> */}

                <h3 className='w-grey'> &nbsp; Database
                    &nbsp;&nbsp;
                    <IconButton onClick={() => openDialogAdd("add")} 
                        className={classesStyle.button_icon}
                        // className={[classesStyle.button_add, 'themeBgi'].join(' ')}
                        >
                        <Add />
                    </IconButton>
                    &nbsp;
                    <IconButton onClick={opendialogimportExcelFile} className={classesStyle.button_icon}>
                        <CloudUpload />
                    </IconButton>
                    &nbsp;
                    <IconButton onClick={handleExportExcel} className={classesStyle.button_icon}>
                        <CloudDownload />
                    </IconButton>
                    &nbsp;
                    <IconButton onClick={reloadtabledata} className={classesStyle.button_icon}>
                        <Refresh />
                    </IconButton>                    
                </h3>
                
                <br/>
                <FormControl fullWidth>                    
                    <Input
                        placeholder="&nbsp; keyword search"                        
                        // id="outlined-adornment-amount"
                        onChange={searchHandler}
                        startAdornment={
                            <InputAdornment position="end">
                                <IconButton type="submit" className={classesStyle.iconButton_menu} aria-label="search" onClick={searchtemplatedata}>
                                    <Menu />
                                </IconButton>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton type="submit" className={classesStyle.iconButton_search} aria-label="search" onClick={searchtemplatedata}>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                        // labelWidth={60}
                    />
                </FormControl>                
                
                <br/>
                <br/>

                <TableContainer component={Paper} variant="outlined">
                    <Table className='tableBg' aria-label="simple table">
                        <TableHead>
                            <TableRow>                                
                                <StyledTableCell width={80} align="center"> date </StyledTableCell>
                                <StyledTableCell width={80} align="center"> ring_count </StyledTableCell>
                                <StyledTableCell width={80} align="center"> angle_tite </StyledTableCell>
                                <StyledTableCell width={80} align="center"> angle_rotation </StyledTableCell>
                                <StyledTableCell width={80} align="center"> angle_x_axis_tilt </StyledTableCell>
                                <StyledTableCell width={80} align="center"> angle_y_axis_tilt </StyledTableCell>

                                <StyledTableCell width={80} align="center"> pressure_guidance_system_shield </StyledTableCell>
                                <StyledTableCell width={80} align="center"> pressure_guidance_system_ground </StyledTableCell>

                                <StyledTableCell width={80} align="center"> temperature_hyd_oil_tank_cutterhead </StyledTableCell>
                                <StyledTableCell width={80} align="center"> temperature_hyd_oil_tank_propulsion </StyledTableCell>

                                <StyledTableCell width={80} align="center"> main_drive_water_temperature </StyledTableCell>
                                <StyledTableCell width={80} align="center"> main_drive_water_flow </StyledTableCell>
                                <StyledTableCell width={80} align="center"> main_bearing_oil_flow </StyledTableCell>
                                <StyledTableCell width={80} align="center"> oil_gas_seal_pressure </StyledTableCell>
                                <StyledTableCell width={80} align="center"> gear_oil_temperature </StyledTableCell>
                                <StyledTableCell width={80} align="center"> main_drive_grease_flow </StyledTableCell>

                                <StyledTableCell width={80} align="center"> cutterhead_speed </StyledTableCell>
                                <StyledTableCell width={80} align="center"> cutterhead_hyd_pump_outlet_pressure </StyledTableCell>
                                <StyledTableCell width={80} align="center"> cutterhead_torque </StyledTableCell>
                                <StyledTableCell width={80} align="center"> cutterhead_oil_leakage_temperature </StyledTableCell>
                                <StyledTableCell width={80} align="center"> cutterhead_oil_charge_pump_pressure </StyledTableCell>

                                <StyledTableCell width={80} align="center"> excavation_speed_setting </StyledTableCell>
                                <StyledTableCell width={80} align="center"> excavation_pump_outlet_pressure </StyledTableCell>
                                <StyledTableCell width={80} align="center"> excavation_average_speed </StyledTableCell>
                                <StyledTableCell width={80} align="center"> penetration </StyledTableCell>
                                <StyledTableCell width={80} align="center"> total_force </StyledTableCell>
                                <StyledTableCell width={80} align="center"> pressure_auxiliary_pump </StyledTableCell>

                                <StyledTableCell width={80} align="center"> steering_jack_angle </StyledTableCell>
                                <StyledTableCell width={80} align="center"> steering_jack_axis_angle </StyledTableCell>
                                <StyledTableCell width={80} align="center"> steering_jack_ext_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> steering_jack_ext_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> steering_jack_ext_3 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> steering_jack_ext_4 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> steering_jack_pressure_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> steering_jack_pressure_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> steering_jack_pressure_3 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> steering_jack_pressure_4 </StyledTableCell>

                                <StyledTableCell width={80} align="center"> bentonite_flow_rate </StyledTableCell>
                                <StyledTableCell width={80} align="center"> bentonite_pressure </StyledTableCell>

                                <StyledTableCell width={80} align="center"> excavation_chamber_pressure_gauge_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> excavation_chamber_pressure_gauge_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> excavation_chamber_pressure_gauge_3 </StyledTableCell>

                                <StyledTableCell width={80} align="center"> slurry_inflow_rate </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_rate </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_inflow_speed_setting_p1_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_inflow_speed_setting_p1_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_speed_setting_p2_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_speed_setting_p2_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_speed_setting_p2_3 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_inflow_pressure_p1_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_inflow_pressure_p1_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_pressure_p1_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_pressure_p1_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_inflow_pressure_p2_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_pressure_p2_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_inflow_pressure_p2_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_pressure_p2_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_speed_p2_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_speed_p2_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_current_p2_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> slurry_outflow_current_p2_2 </StyledTableCell>

                                <StyledTableCell width={80} align="center"> t11y_transformer_phasel1_voltage </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_phasel2_voltage </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_phasel3_voltage </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_linel1l2_voltage </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_linel2l3_voltage </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_linel3l1_voltage </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_phasel1_current </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_phasel2_current </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_phasel3_current </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_freq </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_active_power </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_passive_power </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_power_factor </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_input_power </StyledTableCell>
                                <StyledTableCell width={80} align="center"> t11y_transformer_output_power </StyledTableCell>

                                <StyledTableCell width={80} align="center"> feedback_speed_p1_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> feedback_current_p1_1 </StyledTableCell>

                                <StyledTableCell width={80} align="center"> excavation_jack_ext_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> excavation_jack_ext_5 </StyledTableCell>

                                <StyledTableCell width={80} align="center"> intermediate_jack1_grp1_ext </StyledTableCell>
                                <StyledTableCell width={80} align="center"> intermediate_jack2_grp1_ext </StyledTableCell>
                                <StyledTableCell width={80} align="center"> intermediate_jack1_grp2_ext </StyledTableCell>
                                <StyledTableCell width={80} align="center"> intermediate_jack2_grp2_ext </StyledTableCell>

                                <StyledTableCell width={80} align="center"> concentration_co2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> concentration_o2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> concentration_co </StyledTableCell>
                                <StyledTableCell width={80} align="center"> concentration_ch4 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> concentration_h2s </StyledTableCell>

                                <StyledTableCell width={80} align="center"> distance </StyledTableCell>


                                <StyledTableCell width={100} align="center">operation</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templatedata ?
                                (
                                    <>
                                        {templatedata.map((row, index) => (
                                            <StyledTableRow key={row.id} className="table-striped">
                                                <TableCell align="center" width={80}>{row.date}</TableCell>
                                                <TableCell align="center" width={80}>{row.ring_count}</TableCell>
                                                <TableCell align="center" width={80}>{row.angle_tite}</TableCell>
                                                <TableCell align="center" width={80}>{row.angle_rotation}</TableCell>
                                                <TableCell align="center" width={80}>{row.angle_x_axis_tilt}</TableCell>
                                                <TableCell align="center" width={80}>{row.angle_y_axis_tilt}</TableCell>

                                                <TableCell align="center" width={80}>{row.pressure_guidance_system_shield}</TableCell>
                                                <TableCell align="center" width={80}>{row.pressure_guidance_system_ground}</TableCell>

                                                <TableCell align="center" width={80}>{row.temperature_hyd_oil_tank_cutterhead}</TableCell>
                                                <TableCell align="center" width={80}>{row.temperature_hyd_oil_tank_propulsion}</TableCell>

                                                <TableCell align="center" width={80}>{row.main_drive_water_temperature}</TableCell>
                                                <TableCell align="center" width={80}>{row.main_drive_water_flow}</TableCell>
                                                <TableCell align="center" width={80}>{row.main_bearing_oil_flow}</TableCell>
                                                <TableCell align="center" width={80}>{row.oil_gas_seal_pressure}</TableCell>
                                                <TableCell align="center" width={80}>{row.gear_oil_temperature}</TableCell>
                                                <TableCell align="center" width={80}>{row.main_drive_grease_flow}</TableCell>

                                                <TableCell align="center" width={80}>{row.cutterhead_speed}</TableCell>
                                                <TableCell align="center" width={80}>{row.cutterhead_hyd_pump_outlet_pressure}</TableCell>
                                                <TableCell align="center" width={80}>{row.cutterhead_torque}</TableCell>
                                                <TableCell align="center" width={80}>{row.cutterhead_oil_leakage_temperature}</TableCell>
                                                <TableCell align="center" width={80}>{row.cutterhead_oil_charge_pump_pressure}</TableCell>

                                                <TableCell align="center" width={80}>{row.excavation_speed_setting}</TableCell>
                                                <TableCell align="center" width={80}>{row.excavation_pump_outlet_pressure}</TableCell>
                                                <TableCell align="center" width={80}>{row.excavation_average_speed}</TableCell>
                                                <TableCell align="center" width={80}>{row.penetration}</TableCell>
                                                <TableCell align="center" width={80}>{row.total_force}</TableCell>
                                                <TableCell align="center" width={80}>{row.pressure_auxiliary_pump}</TableCell>

                                                <TableCell align="center" width={80}>{row.steering_jack_angle}</TableCell>
                                                <TableCell align="center" width={80}>{row.steering_jack_axis_angle}</TableCell>
                                                <TableCell align="center" width={80}>{row.steering_jack_ext_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.steering_jack_ext_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.steering_jack_ext_3}</TableCell>
                                                <TableCell align="center" width={80}>{row.steering_jack_ext_4}</TableCell>
                                                <TableCell align="center" width={80}>{row.steering_jack_pressure_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.steering_jack_pressure_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.steering_jack_pressure_3}</TableCell>
                                                <TableCell align="center" width={80}>{row.steering_jack_pressure_4}</TableCell>

                                                <TableCell align="center" width={80}>{row.bentonite_flow_rate}</TableCell>
                                                <TableCell align="center" width={80}>{row.bentonite_pressure}</TableCell>

                                                <TableCell align="center" width={80}>{row.excavation_chamber_pressure_gauge_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.excavation_chamber_pressure_gauge_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.excavation_chamber_pressure_gauge_3}</TableCell>

                                                <TableCell align="center" width={80}>{row.slurry_inflow_rate}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_rate}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_inflow_speed_setting_p1_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_inflow_speed_setting_p1_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_speed_setting_p2_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_speed_setting_p2_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_speed_setting_p2_3}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_inflow_pressure_p1_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_inflow_pressure_p1_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_pressure_p1_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_pressure_p1_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_inflow_pressure_p2_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_pressure_p2_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_inflow_pressure_p2_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_pressure_p2_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_speed_p2_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_speed_p2_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_current_p2_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.slurry_outflow_current_p2_2}</TableCell>

                                                <TableCell align="center" width={80}>{row.t11y_transformer_phasel1_voltage}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_phasel2_voltage}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_phasel3_voltage}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_linel1l2_voltage}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_linel2l3_voltage}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_linel3l1_voltage}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_phasel1_current}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_phasel2_current}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_phasel3_current}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_freq}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_active_power}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_passive_power}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_power_factor}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_input_power}</TableCell>
                                                <TableCell align="center" width={80}>{row.t11y_transformer_output_power}</TableCell>

                                                <TableCell align="center" width={80}>{row.feedback_speed_p1_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.feedback_current_p1_1}</TableCell>

                                                <TableCell align="center" width={80}>{row.excavation_jack_ext_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.excavation_jack_ext_5}</TableCell>

                                                <TableCell align="center" width={80}>{row.intermediate_jack1_grp1_ext}</TableCell>
                                                <TableCell align="center" width={80}>{row.intermediate_jack2_grp1_ext}</TableCell>
                                                <TableCell align="center" width={80}>{row.intermediate_jack1_grp2_ext}</TableCell>
                                                <TableCell align="center" width={80}>{row.intermediate_jack2_grp2_ext}</TableCell>

                                                <TableCell align="center" width={80}>{row.concentration_co2}</TableCell>
                                                <TableCell align="center" width={80}>{row.concentration_o2}</TableCell>
                                                <TableCell align="center" width={80}>{row.concentration_co}</TableCell>
                                                <TableCell align="center" width={80}>{row.concentration_ch4}</TableCell>
                                                <TableCell align="center" width={80}>{row.concentration_h2s}</TableCell>

                                                <TableCell align="center" width={80}>{row.distance}</TableCell>


                                                
                                                <TableCell align="center">
                                                    <IconButton className={classesStyle.button_icon} onClick=
                                                        {
                                                            () => { 
                                                                // [fixme, depends on table, DataElement], caution: SEQUENCE of 'openDialogEdit()'
                                                                openDialogEdit(
                                                                    row.date,
                                                                    row.ring_count,
                                                                    row.angle_tite,
                                                                    row.angle_rotation,
                                                                    row.angle_x_axis_tilt,
                                                                    row.angle_y_axis_tilt,

                                                                    row.pressure_guidance_system_shield,
                                                                    row.pressure_guidance_system_ground,

                                                                    row.temperature_hyd_oil_tank_cutterhead,
                                                                    row.temperature_hyd_oil_tank_propulsion,

                                                                    row.main_drive_water_temperature,
                                                                    row.main_drive_water_flow,
                                                                    row.main_bearing_oil_flow,
                                                                    row.oil_gas_seal_pressure,
                                                                    row.gear_oil_temperature,
                                                                    row.main_drive_grease_flow,

                                                                    row.cutterhead_speed,
                                                                    row.cutterhead_hyd_pump_outlet_pressure,
                                                                    row.cutterhead_torque,
                                                                    row.cutterhead_oil_leakage_temperature,
                                                                    row.cutterhead_oil_charge_pump_pressure,

                                                                    row.excavation_speed_setting,
                                                                    row.excavation_pump_outlet_pressure,
                                                                    row.excavation_average_speed,
                                                                    row.penetration,
                                                                    row.total_force,
                                                                    row.pressure_auxiliary_pump,

                                                                    row.steering_jack_angle,
                                                                    row.steering_jack_axis_angle,
                                                                    row.steering_jack_ext_1,
                                                                    row.steering_jack_ext_2,
                                                                    row.steering_jack_ext_3,
                                                                    row.steering_jack_ext_4,
                                                                    row.steering_jack_pressure_1,
                                                                    row.steering_jack_pressure_2,
                                                                    row.steering_jack_pressure_3,
                                                                    row.steering_jack_pressure_4,

                                                                    row.bentonite_flow_rate,
                                                                    row.bentonite_pressure,

                                                                    row.excavation_chamber_pressure_gauge_1,
                                                                    row.excavation_chamber_pressure_gauge_2,
                                                                    row.excavation_chamber_pressure_gauge_3,

                                                                    row.slurry_inflow_rate,
                                                                    row.slurry_outflow_rate,
                                                                    row.slurry_inflow_speed_setting_p1_1,
                                                                    row.slurry_inflow_speed_setting_p1_2,
                                                                    row.slurry_outflow_speed_setting_p2_1,
                                                                    row.slurry_outflow_speed_setting_p2_2,
                                                                    row.slurry_outflow_speed_setting_p2_3,
                                                                    row.slurry_inflow_pressure_p1_1,
                                                                    row.slurry_inflow_pressure_p1_2,
                                                                    row.slurry_outflow_pressure_p1_2,
                                                                    row.slurry_outflow_pressure_p1_1,
                                                                    row.slurry_inflow_pressure_p2_1,
                                                                    row.slurry_outflow_pressure_p2_1,
                                                                    row.slurry_inflow_pressure_p2_2,
                                                                    row.slurry_outflow_pressure_p2_2,
                                                                    row.slurry_outflow_speed_p2_1,
                                                                    row.slurry_outflow_speed_p2_2,
                                                                    row.slurry_outflow_current_p2_1,
                                                                    row.slurry_outflow_current_p2_2,

                                                                    row.t11y_transformer_phasel1_voltage,
                                                                    row.t11y_transformer_phasel2_voltage,
                                                                    row.t11y_transformer_phasel3_voltage,
                                                                    row.t11y_transformer_linel1l2_voltage,
                                                                    row.t11y_transformer_linel2l3_voltage,
                                                                    row.t11y_transformer_linel3l1_voltage,
                                                                    row.t11y_transformer_phasel1_current,
                                                                    row.t11y_transformer_phasel2_current,
                                                                    row.t11y_transformer_phasel3_current,
                                                                    row.t11y_transformer_freq,
                                                                    row.t11y_transformer_active_power,
                                                                    row.t11y_transformer_passive_power,
                                                                    row.t11y_transformer_power_factor,
                                                                    row.t11y_transformer_input_power,
                                                                    row.t11y_transformer_output_power,

                                                                    row.feedback_speed_p1_1,
                                                                    row.feedback_current_p1_1,

                                                                    row.excavation_jack_ext_2,
                                                                    row.excavation_jack_ext_5,

                                                                    row.intermediate_jack1_grp1_ext,
                                                                    row.intermediate_jack2_grp1_ext,
                                                                    row.intermediate_jack1_grp2_ext,
                                                                    row.intermediate_jack2_grp2_ext,

                                                                    row.concentration_co2,
                                                                    row.concentration_o2,
                                                                    row.concentration_co,
                                                                    row.concentration_ch4,
                                                                    row.concentration_h2s,

                                                                    row.distance,


                                                                    row.verticaloffset
                                                                ) 
                                                            }
                                                        } 
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                    
                                                    <IconButton className={classesStyle.button_icon} onClick=
                                                        {
                                                            () => {handleDelete(row.id)}
                                                        } 
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                    
                                                </TableCell>

                                                

                                            </StyledTableRow>
                                        ))}
                                    </>
                                ) : (
                                    <div> &nbsp;&nbsp;&nbsp;&nbsp; no record found </div>
                                )
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalpagenum}
                    rowsPerPage={rowsPerPage.current}
                    page={page.current}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Container>

            <DialogAdd
                triggerOpen={dialogAddOpen}
                handleClose={closeDialogAdd}


                setdate={setdate}
                setring_count={setring_count}
                setangle_tite={setangle_tite}
                setangle_rotation={setangle_rotation}
                setangle_x_axis_tilt={setangle_x_axis_tilt}
                setangle_y_axis_tilt={setangle_y_axis_tilt}

                setpressure_guidance_system_shield={setpressure_guidance_system_shield}
                setpressure_guidance_system_ground={setpressure_guidance_system_ground}

                settemperature_hyd_oil_tank_cutterhead={settemperature_hyd_oil_tank_cutterhead}
                settemperature_hyd_oil_tank_propulsion={settemperature_hyd_oil_tank_propulsion}

                setmain_drive_water_temperature={setmain_drive_water_temperature}
                setmain_drive_water_flow={setmain_drive_water_flow}
                setmain_bearing_oil_flow={setmain_bearing_oil_flow}
                setoil_gas_seal_pressure={setoil_gas_seal_pressure}
                setgear_oil_temperature={setgear_oil_temperature}
                setmain_drive_grease_flow={setmain_drive_grease_flow}

                setcutterhead_speed={setcutterhead_speed}
                setcutterhead_hyd_pump_outlet_pressure={setcutterhead_hyd_pump_outlet_pressure}
                setcutterhead_torque={setcutterhead_torque}
                setcutterhead_oil_leakage_temperature={setcutterhead_oil_leakage_temperature}
                setcutterhead_oil_charge_pump_pressure={setcutterhead_oil_charge_pump_pressure}

                setexcavation_speed_setting={setexcavation_speed_setting}
                setexcavation_pump_outlet_pressure={setexcavation_pump_outlet_pressure}
                setexcavation_average_speed={setexcavation_average_speed}
                setpenetration={setpenetration}
                settotal_force={settotal_force}
                setpressure_auxiliary_pump={setpressure_auxiliary_pump}

                setsteering_jack_angle={setsteering_jack_angle}
                setsteering_jack_axis_angle={setsteering_jack_axis_angle}
                setsteering_jack_ext_1={setsteering_jack_ext_1}
                setsteering_jack_ext_2={setsteering_jack_ext_2}
                setsteering_jack_ext_3={setsteering_jack_ext_3}
                setsteering_jack_ext_4={setsteering_jack_ext_4}
                setsteering_jack_pressure_1={setsteering_jack_pressure_1}
                setsteering_jack_pressure_2={setsteering_jack_pressure_2}
                setsteering_jack_pressure_3={setsteering_jack_pressure_3}
                setsteering_jack_pressure_4={setsteering_jack_pressure_4}

                setbentonite_flow_rate={setbentonite_flow_rate}
                setbentonite_pressure={setbentonite_pressure}

                setexcavation_chamber_pressure_gauge_1={setexcavation_chamber_pressure_gauge_1}
                setexcavation_chamber_pressure_gauge_2={setexcavation_chamber_pressure_gauge_2}
                setexcavation_chamber_pressure_gauge_3={setexcavation_chamber_pressure_gauge_3}

                setslurry_inflow_rate={setslurry_inflow_rate}
                setslurry_outflow_rate={setslurry_outflow_rate}
                setslurry_inflow_speed_setting_p1_1={setslurry_inflow_speed_setting_p1_1}
                setslurry_inflow_speed_setting_p1_2={setslurry_inflow_speed_setting_p1_2}
                setslurry_outflow_speed_setting_p2_1={setslurry_outflow_speed_setting_p2_1}
                setslurry_outflow_speed_setting_p2_2={setslurry_outflow_speed_setting_p2_2}
                setslurry_outflow_speed_setting_p2_3={setslurry_outflow_speed_setting_p2_3}
                setslurry_inflow_pressure_p1_1={setslurry_inflow_pressure_p1_1}
                setslurry_inflow_pressure_p1_2={setslurry_inflow_pressure_p1_2}
                setslurry_outflow_pressure_p1_2={setslurry_outflow_pressure_p1_2}
                setslurry_outflow_pressure_p1_1={setslurry_outflow_pressure_p1_1}
                setslurry_inflow_pressure_p2_1={setslurry_inflow_pressure_p2_1}
                setslurry_outflow_pressure_p2_1={setslurry_outflow_pressure_p2_1}
                setslurry_inflow_pressure_p2_2={setslurry_inflow_pressure_p2_2}
                setslurry_outflow_pressure_p2_2={setslurry_outflow_pressure_p2_2}
                setslurry_outflow_speed_p2_1={setslurry_outflow_speed_p2_1}
                setslurry_outflow_speed_p2_2={setslurry_outflow_speed_p2_2}
                setslurry_outflow_current_p2_1={setslurry_outflow_current_p2_1}
                setslurry_outflow_current_p2_2={setslurry_outflow_current_p2_2}

                sett11y_transformer_phasel1_voltage={sett11y_transformer_phasel1_voltage}
                sett11y_transformer_phasel2_voltage={sett11y_transformer_phasel2_voltage}
                sett11y_transformer_phasel3_voltage={sett11y_transformer_phasel3_voltage}
                sett11y_transformer_linel1l2_voltage={sett11y_transformer_linel1l2_voltage}
                sett11y_transformer_linel2l3_voltage={sett11y_transformer_linel2l3_voltage}
                sett11y_transformer_linel3l1_voltage={sett11y_transformer_linel3l1_voltage}
                sett11y_transformer_phasel1_current={sett11y_transformer_phasel1_current}
                sett11y_transformer_phasel2_current={sett11y_transformer_phasel2_current}
                sett11y_transformer_phasel3_current={sett11y_transformer_phasel3_current}
                sett11y_transformer_freq={sett11y_transformer_freq}
                sett11y_transformer_active_power={sett11y_transformer_active_power}
                sett11y_transformer_passive_power={sett11y_transformer_passive_power}
                sett11y_transformer_power_factor={sett11y_transformer_power_factor}
                sett11y_transformer_input_power={sett11y_transformer_input_power}
                sett11y_transformer_output_power={sett11y_transformer_output_power}

                setfeedback_speed_p1_1={setfeedback_speed_p1_1}
                setfeedback_current_p1_1={setfeedback_current_p1_1}

                setexcavation_jack_ext_2={setexcavation_jack_ext_2}
                setexcavation_jack_ext_5={setexcavation_jack_ext_5}

                setintermediate_jack1_grp1_ext={setintermediate_jack1_grp1_ext}
                setintermediate_jack2_grp1_ext={setintermediate_jack2_grp1_ext}
                setintermediate_jack1_grp2_ext={setintermediate_jack1_grp2_ext}
                setintermediate_jack2_grp2_ext={setintermediate_jack2_grp2_ext}

                setconcentration_co2={setconcentration_co2}
                setconcentration_o2={setconcentration_o2}
                setconcentration_co={setconcentration_co}
                setconcentration_ch4={setconcentration_ch4}
                setconcentration_h2s={setconcentration_h2s}

                setdistance={setdistance}


                // [fixme, + magic refresh table after edit / update]
                handleconfirmDialogAdd={handleconfirmDialogAdd}

                // control POPupB of POPupA
                openDialogOperationResult={openDialogOperationResult}
            />

            <DialogEdit
                
                // control POPupA Dialog: on / off
                triggerOpen={dialogEditOpen}
                handleClose={closeDialogEdit}                
                
                // [fixme, depends on table, DataElement]
                date={date}
                ring_count={ring_count}
                angle_tite={angle_tite}
                angle_rotation={angle_rotation}
                angle_x_axis_tilt={angle_x_axis_tilt}
                angle_y_axis_tilt={angle_y_axis_tilt}

                pressure_guidance_system_shield={pressure_guidance_system_shield}
                pressure_guidance_system_ground={pressure_guidance_system_ground}

                temperature_hyd_oil_tank_cutterhead={temperature_hyd_oil_tank_cutterhead}
                temperature_hyd_oil_tank_propulsion={temperature_hyd_oil_tank_propulsion}

                main_drive_water_temperature={main_drive_water_temperature}
                main_drive_water_flow={main_drive_water_flow}
                main_bearing_oil_flow={main_bearing_oil_flow}
                oil_gas_seal_pressure={oil_gas_seal_pressure}
                gear_oil_temperature={gear_oil_temperature}
                main_drive_grease_flow={main_drive_grease_flow}

                cutterhead_speed={cutterhead_speed}
                cutterhead_hyd_pump_outlet_pressure={cutterhead_hyd_pump_outlet_pressure}
                cutterhead_torque={cutterhead_torque}
                cutterhead_oil_leakage_temperature={cutterhead_oil_leakage_temperature}
                cutterhead_oil_charge_pump_pressure={cutterhead_oil_charge_pump_pressure}

                excavation_speed_setting={excavation_speed_setting}
                excavation_pump_outlet_pressure={excavation_pump_outlet_pressure}
                excavation_average_speed={excavation_average_speed}
                penetration={penetration}
                total_force={total_force}
                pressure_auxiliary_pump={pressure_auxiliary_pump}

                steering_jack_angle={steering_jack_angle}
                steering_jack_axis_angle={steering_jack_axis_angle}
                steering_jack_ext_1={steering_jack_ext_1}
                steering_jack_ext_2={steering_jack_ext_2}
                steering_jack_ext_3={steering_jack_ext_3}
                steering_jack_ext_4={steering_jack_ext_4}
                steering_jack_pressure_1={steering_jack_pressure_1}
                steering_jack_pressure_2={steering_jack_pressure_2}
                steering_jack_pressure_3={steering_jack_pressure_3}
                steering_jack_pressure_4={steering_jack_pressure_4}

                bentonite_flow_rate={bentonite_flow_rate}
                bentonite_pressure={bentonite_pressure}

                excavation_chamber_pressure_gauge_1={excavation_chamber_pressure_gauge_1}
                excavation_chamber_pressure_gauge_2={excavation_chamber_pressure_gauge_2}
                excavation_chamber_pressure_gauge_3={excavation_chamber_pressure_gauge_3}

                slurry_inflow_rate={slurry_inflow_rate}
                slurry_outflow_rate={slurry_outflow_rate}
                slurry_inflow_speed_setting_p1_1={slurry_inflow_speed_setting_p1_1}
                slurry_inflow_speed_setting_p1_2={slurry_inflow_speed_setting_p1_2}
                slurry_outflow_speed_setting_p2_1={slurry_outflow_speed_setting_p2_1}
                slurry_outflow_speed_setting_p2_2={slurry_outflow_speed_setting_p2_2}
                slurry_outflow_speed_setting_p2_3={slurry_outflow_speed_setting_p2_3}
                slurry_inflow_pressure_p1_1={slurry_inflow_pressure_p1_1}
                slurry_inflow_pressure_p1_2={slurry_inflow_pressure_p1_2}
                slurry_outflow_pressure_p1_2={slurry_outflow_pressure_p1_2}
                slurry_outflow_pressure_p1_1={slurry_outflow_pressure_p1_1}
                slurry_inflow_pressure_p2_1={slurry_inflow_pressure_p2_1}
                slurry_outflow_pressure_p2_1={slurry_outflow_pressure_p2_1}
                slurry_inflow_pressure_p2_2={slurry_inflow_pressure_p2_2}
                slurry_outflow_pressure_p2_2={slurry_outflow_pressure_p2_2}
                slurry_outflow_speed_p2_1={slurry_outflow_speed_p2_1}
                slurry_outflow_speed_p2_2={slurry_outflow_speed_p2_2}
                slurry_outflow_current_p2_1={slurry_outflow_current_p2_1}
                slurry_outflow_current_p2_2={slurry_outflow_current_p2_2}

                t11y_transformer_phasel1_voltage={t11y_transformer_phasel1_voltage}
                t11y_transformer_phasel2_voltage={t11y_transformer_phasel2_voltage}
                t11y_transformer_phasel3_voltage={t11y_transformer_phasel3_voltage}
                t11y_transformer_linel1l2_voltage={t11y_transformer_linel1l2_voltage}
                t11y_transformer_linel2l3_voltage={t11y_transformer_linel2l3_voltage}
                t11y_transformer_linel3l1_voltage={t11y_transformer_linel3l1_voltage}
                t11y_transformer_phasel1_current={t11y_transformer_phasel1_current}
                t11y_transformer_phasel2_current={t11y_transformer_phasel2_current}
                t11y_transformer_phasel3_current={t11y_transformer_phasel3_current}
                t11y_transformer_freq={t11y_transformer_freq}
                t11y_transformer_active_power={t11y_transformer_active_power}
                t11y_transformer_passive_power={t11y_transformer_passive_power}
                t11y_transformer_power_factor={t11y_transformer_power_factor}
                t11y_transformer_input_power={t11y_transformer_input_power}
                t11y_transformer_output_power={t11y_transformer_output_power}

                feedback_speed_p1_1={feedback_speed_p1_1}
                feedback_current_p1_1={feedback_current_p1_1}

                excavation_jack_ext_2={excavation_jack_ext_2}
                excavation_jack_ext_5={excavation_jack_ext_5}

                intermediate_jack1_grp1_ext={intermediate_jack1_grp1_ext}
                intermediate_jack2_grp1_ext={intermediate_jack2_grp1_ext}
                intermediate_jack1_grp2_ext={intermediate_jack1_grp2_ext}
                intermediate_jack2_grp2_ext={intermediate_jack2_grp2_ext}

                concentration_co2={concentration_co2}
                concentration_o2={concentration_o2}
                concentration_co={concentration_co}
                concentration_ch4={concentration_ch4}
                concentration_h2s={concentration_h2s}

                distance={distance}
                                

                setdate={setdate}
                setring_count={setring_count}
                setangle_tite={setangle_tite}
                setangle_rotation={setangle_rotation}
                setangle_x_axis_tilt={setangle_x_axis_tilt}
                setangle_y_axis_tilt={setangle_y_axis_tilt}

                setpressure_guidance_system_shield={setpressure_guidance_system_shield}
                setpressure_guidance_system_ground={setpressure_guidance_system_ground}

                settemperature_hyd_oil_tank_cutterhead={settemperature_hyd_oil_tank_cutterhead}
                settemperature_hyd_oil_tank_propulsion={settemperature_hyd_oil_tank_propulsion}

                setmain_drive_water_temperature={setmain_drive_water_temperature}
                setmain_drive_water_flow={setmain_drive_water_flow}
                setmain_bearing_oil_flow={setmain_bearing_oil_flow}
                setoil_gas_seal_pressure={setoil_gas_seal_pressure}
                setgear_oil_temperature={setgear_oil_temperature}
                setmain_drive_grease_flow={setmain_drive_grease_flow}

                setcutterhead_speed={setcutterhead_speed}
                setcutterhead_hyd_pump_outlet_pressure={setcutterhead_hyd_pump_outlet_pressure}
                setcutterhead_torque={setcutterhead_torque}
                setcutterhead_oil_leakage_temperature={setcutterhead_oil_leakage_temperature}
                setcutterhead_oil_charge_pump_pressure={setcutterhead_oil_charge_pump_pressure}

                setexcavation_speed_setting={setexcavation_speed_setting}
                setexcavation_pump_outlet_pressure={setexcavation_pump_outlet_pressure}
                setexcavation_average_speed={setexcavation_average_speed}
                setpenetration={setpenetration}
                settotal_force={settotal_force}
                setpressure_auxiliary_pump={setpressure_auxiliary_pump}

                setsteering_jack_angle={setsteering_jack_angle}
                setsteering_jack_axis_angle={setsteering_jack_axis_angle}
                setsteering_jack_ext_1={setsteering_jack_ext_1}
                setsteering_jack_ext_2={setsteering_jack_ext_2}
                setsteering_jack_ext_3={setsteering_jack_ext_3}
                setsteering_jack_ext_4={setsteering_jack_ext_4}
                setsteering_jack_pressure_1={setsteering_jack_pressure_1}
                setsteering_jack_pressure_2={setsteering_jack_pressure_2}
                setsteering_jack_pressure_3={setsteering_jack_pressure_3}
                setsteering_jack_pressure_4={setsteering_jack_pressure_4}

                setbentonite_flow_rate={setbentonite_flow_rate}
                setbentonite_pressure={setbentonite_pressure}

                setexcavation_chamber_pressure_gauge_1={setexcavation_chamber_pressure_gauge_1}
                setexcavation_chamber_pressure_gauge_2={setexcavation_chamber_pressure_gauge_2}
                setexcavation_chamber_pressure_gauge_3={setexcavation_chamber_pressure_gauge_3}

                setslurry_inflow_rate={setslurry_inflow_rate}
                setslurry_outflow_rate={setslurry_outflow_rate}
                setslurry_inflow_speed_setting_p1_1={setslurry_inflow_speed_setting_p1_1}
                setslurry_inflow_speed_setting_p1_2={setslurry_inflow_speed_setting_p1_2}
                setslurry_outflow_speed_setting_p2_1={setslurry_outflow_speed_setting_p2_1}
                setslurry_outflow_speed_setting_p2_2={setslurry_outflow_speed_setting_p2_2}
                setslurry_outflow_speed_setting_p2_3={setslurry_outflow_speed_setting_p2_3}
                setslurry_inflow_pressure_p1_1={setslurry_inflow_pressure_p1_1}
                setslurry_inflow_pressure_p1_2={setslurry_inflow_pressure_p1_2}
                setslurry_outflow_pressure_p1_2={setslurry_outflow_pressure_p1_2}
                setslurry_outflow_pressure_p1_1={setslurry_outflow_pressure_p1_1}
                setslurry_inflow_pressure_p2_1={setslurry_inflow_pressure_p2_1}
                setslurry_outflow_pressure_p2_1={setslurry_outflow_pressure_p2_1}
                setslurry_inflow_pressure_p2_2={setslurry_inflow_pressure_p2_2}
                setslurry_outflow_pressure_p2_2={setslurry_outflow_pressure_p2_2}
                setslurry_outflow_speed_p2_1={setslurry_outflow_speed_p2_1}
                setslurry_outflow_speed_p2_2={setslurry_outflow_speed_p2_2}
                setslurry_outflow_current_p2_1={setslurry_outflow_current_p2_1}
                setslurry_outflow_current_p2_2={setslurry_outflow_current_p2_2}

                sett11y_transformer_phasel1_voltage={sett11y_transformer_phasel1_voltage}
                sett11y_transformer_phasel2_voltage={sett11y_transformer_phasel2_voltage}
                sett11y_transformer_phasel3_voltage={sett11y_transformer_phasel3_voltage}
                sett11y_transformer_linel1l2_voltage={sett11y_transformer_linel1l2_voltage}
                sett11y_transformer_linel2l3_voltage={sett11y_transformer_linel2l3_voltage}
                sett11y_transformer_linel3l1_voltage={sett11y_transformer_linel3l1_voltage}
                sett11y_transformer_phasel1_current={sett11y_transformer_phasel1_current}
                sett11y_transformer_phasel2_current={sett11y_transformer_phasel2_current}
                sett11y_transformer_phasel3_current={sett11y_transformer_phasel3_current}
                sett11y_transformer_freq={sett11y_transformer_freq}
                sett11y_transformer_active_power={sett11y_transformer_active_power}
                sett11y_transformer_passive_power={sett11y_transformer_passive_power}
                sett11y_transformer_power_factor={sett11y_transformer_power_factor}
                sett11y_transformer_input_power={sett11y_transformer_input_power}
                sett11y_transformer_output_power={sett11y_transformer_output_power}

                setfeedback_speed_p1_1={setfeedback_speed_p1_1}
                setfeedback_current_p1_1={setfeedback_current_p1_1}

                setexcavation_jack_ext_2={setexcavation_jack_ext_2}
                setexcavation_jack_ext_5={setexcavation_jack_ext_5}

                setintermediate_jack1_grp1_ext={setintermediate_jack1_grp1_ext}
                setintermediate_jack2_grp1_ext={setintermediate_jack2_grp1_ext}
                setintermediate_jack1_grp2_ext={setintermediate_jack1_grp2_ext}
                setintermediate_jack2_grp2_ext={setintermediate_jack2_grp2_ext}

                setconcentration_co2={setconcentration_co2}
                setconcentration_o2={setconcentration_o2}
                setconcentration_co={setconcentration_co}
                setconcentration_ch4={setconcentration_ch4}
                setconcentration_h2s={setconcentration_h2s}

                setdistance={setdistance}


                // [fixme, + magic refresh table after edit / update]
                handleconfirmDialogEdit={handleconfirmDialogEdit}

                // control POPupB of POPupA
                openDialogOperationResult={openDialogOperationResult}                
            />

            <DialogImportExcelFile
                triggerOpen={dialogimportExcelFileOpen}
                handleClose={closedialogimportExcelFile}

                setimportExcelFile={importExcelFileHandler}
                importExcelFile={importExcelFile}
                onloadprogressExcelFile={onloadprogressExcelFile}

                // [fixme, + magic refresh table after edit / update]
                handleconfirmImportExcel={handleconfirmImportExcel}
            />
            
            <DialogOperationResult
                triggerOpen={dialogOperationResultOpen}
                handleClose={closeDialogOperationResult}

                dialogOperationResultState={dialogOperationResultState}
            />

        </div>
    )
}



function DialogEdit(
    {     
        triggerOpen, handleClose, 

        // [fixme, depends on table, DataElement]        
        date, 
            ring_count,
            angle_tite,
            angle_rotation,
            angle_x_axis_tilt,
            angle_y_axis_tilt,

            pressure_guidance_system_shield,
            pressure_guidance_system_ground,

            temperature_hyd_oil_tank_cutterhead,
            temperature_hyd_oil_tank_propulsion,

            main_drive_water_temperature,
            main_drive_water_flow,
            main_bearing_oil_flow,
            oil_gas_seal_pressure,
            gear_oil_temperature,
            main_drive_grease_flow,

            cutterhead_speed,
            cutterhead_hyd_pump_outlet_pressure,
            cutterhead_torque,
            cutterhead_oil_leakage_temperature,
            cutterhead_oil_charge_pump_pressure,

            excavation_speed_setting,
            excavation_pump_outlet_pressure,
            excavation_average_speed,
            penetration,
            total_force,
            pressure_auxiliary_pump,

            steering_jack_angle,
            steering_jack_axis_angle,
            steering_jack_ext_1,
            steering_jack_ext_2,
            steering_jack_ext_3,
            steering_jack_ext_4,
            steering_jack_pressure_1,
            steering_jack_pressure_2,
            steering_jack_pressure_3,
            steering_jack_pressure_4,

            bentonite_flow_rate,
            bentonite_pressure,

            excavation_chamber_pressure_gauge_1,
            excavation_chamber_pressure_gauge_2,
            excavation_chamber_pressure_gauge_3,

            slurry_inflow_rate,
            slurry_outflow_rate,
            slurry_inflow_speed_setting_p1_1,
            slurry_inflow_speed_setting_p1_2,
            slurry_outflow_speed_setting_p2_1,
            slurry_outflow_speed_setting_p2_2,
            slurry_outflow_speed_setting_p2_3,
            slurry_inflow_pressure_p1_1,
            slurry_inflow_pressure_p1_2,
            slurry_outflow_pressure_p1_2,
            slurry_outflow_pressure_p1_1,
            slurry_inflow_pressure_p2_1,
            slurry_outflow_pressure_p2_1,
            slurry_inflow_pressure_p2_2,
            slurry_outflow_pressure_p2_2,
            slurry_outflow_speed_p2_1,
            slurry_outflow_speed_p2_2,
            slurry_outflow_current_p2_1,
            slurry_outflow_current_p2_2,

            t11y_transformer_phasel1_voltage,
            t11y_transformer_phasel2_voltage,
            t11y_transformer_phasel3_voltage,
            t11y_transformer_linel1l2_voltage,
            t11y_transformer_linel2l3_voltage,
            t11y_transformer_linel3l1_voltage,
            t11y_transformer_phasel1_current,
            t11y_transformer_phasel2_current,
            t11y_transformer_phasel3_current,
            t11y_transformer_freq,
            t11y_transformer_active_power,
            t11y_transformer_passive_power,
            t11y_transformer_power_factor,
            t11y_transformer_input_power,
            t11y_transformer_output_power,

            feedback_speed_p1_1,
            feedback_current_p1_1,

            excavation_jack_ext_2,
            excavation_jack_ext_5,

            intermediate_jack1_grp1_ext,
            intermediate_jack2_grp1_ext,
            intermediate_jack1_grp2_ext,
            intermediate_jack2_grp2_ext,

            concentration_co2,
            concentration_o2,
            concentration_co,
            concentration_ch4,
            concentration_h2s,

            distance,
        setdate, 
            setring_count,
            setangle_tite,
            setangle_rotation,
            setangle_x_axis_tilt,
            setangle_y_axis_tilt,
            
            setpressure_guidance_system_shield,
            setpressure_guidance_system_ground,
            
            settemperature_hyd_oil_tank_cutterhead,
            settemperature_hyd_oil_tank_propulsion,
            
            setmain_drive_water_temperature,
            setmain_drive_water_flow,
            setmain_bearing_oil_flow,
            setoil_gas_seal_pressure,
            setgear_oil_temperature,
            setmain_drive_grease_flow,
            
            setcutterhead_speed,
            setcutterhead_hyd_pump_outlet_pressure,
            setcutterhead_torque,
            setcutterhead_oil_leakage_temperature,
            setcutterhead_oil_charge_pump_pressure,
            
            setexcavation_speed_setting,
            setexcavation_pump_outlet_pressure,
            setexcavation_average_speed,
            setpenetration,
            settotal_force,
            setpressure_auxiliary_pump,
            
            setsteering_jack_angle,
            setsteering_jack_axis_angle,
            setsteering_jack_ext_1,
            setsteering_jack_ext_2,
            setsteering_jack_ext_3,
            setsteering_jack_ext_4,
            setsteering_jack_pressure_1,
            setsteering_jack_pressure_2,
            setsteering_jack_pressure_3,
            setsteering_jack_pressure_4,
            
            setbentonite_flow_rate,
            setbentonite_pressure,
            
            setexcavation_chamber_pressure_gauge_1,
            setexcavation_chamber_pressure_gauge_2,
            setexcavation_chamber_pressure_gauge_3,
            
            setslurry_inflow_rate,
            setslurry_outflow_rate,
            setslurry_inflow_speed_setting_p1_1,
            setslurry_inflow_speed_setting_p1_2,
            setslurry_outflow_speed_setting_p2_1,
            setslurry_outflow_speed_setting_p2_2,
            setslurry_outflow_speed_setting_p2_3,
            setslurry_inflow_pressure_p1_1,
            setslurry_inflow_pressure_p1_2,
            setslurry_outflow_pressure_p1_2,
            setslurry_outflow_pressure_p1_1,
            setslurry_inflow_pressure_p2_1,
            setslurry_outflow_pressure_p2_1,
            setslurry_inflow_pressure_p2_2,
            setslurry_outflow_pressure_p2_2,
            setslurry_outflow_speed_p2_1,
            setslurry_outflow_speed_p2_2,
            setslurry_outflow_current_p2_1,
            setslurry_outflow_current_p2_2,
            
            sett11y_transformer_phasel1_voltage,
            sett11y_transformer_phasel2_voltage,
            sett11y_transformer_phasel3_voltage,
            sett11y_transformer_linel1l2_voltage,
            sett11y_transformer_linel2l3_voltage,
            sett11y_transformer_linel3l1_voltage,
            sett11y_transformer_phasel1_current,
            sett11y_transformer_phasel2_current,
            sett11y_transformer_phasel3_current,
            sett11y_transformer_freq,
            sett11y_transformer_active_power,
            sett11y_transformer_passive_power,
            sett11y_transformer_power_factor,
            sett11y_transformer_input_power,
            sett11y_transformer_output_power,
            
            setfeedback_speed_p1_1,
            setfeedback_current_p1_1,
            
            setexcavation_jack_ext_2,
            setexcavation_jack_ext_5,
            
            setintermediate_jack1_grp1_ext,
            setintermediate_jack2_grp1_ext,
            setintermediate_jack1_grp2_ext,
            setintermediate_jack2_grp2_ext,
            
            setconcentration_co2,
            setconcentration_o2,
            setconcentration_co,
            setconcentration_ch4,
            setconcentration_h2s,
            
            setdistance,

        // [fixme, magic refresh table after edit / update]
        handleconfirmDialogEdit,
        openDialogOperationResult 
    })
    {


    //// CSS related
    const cssStyle = makeStyles((theme: Theme) =>
        createStyles({            
            buttonRow: {
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "16px",
            },
            solidButton: {
                margin: "8px",
                fontWeight: 600,
                "&:hover": {
                    backgroundColor: "#8B0000",
                    color: "#fff",
                },
            },
            input: {
                borderBottom: `1px solid ${theme.palette.divider}`,
                paddingRight: theme.spacing(2),
                display: 'flex',
                alignItems: 'center',
            },
            icon: {
                fontSize: '1.2em',
                marginLeft: theme.spacing(1),
                cursor: 'pointer',
            },
        }))
    const classes = cssStyle();


    //// FORM related
    // state - FORM
    // const [buttonlock, setbuttonlock] = useState(true);
    const [buttonlock, setbuttonlock] = useState(false);        
    
    // [fixme, depends on table, DataElement]
    const handleinput = (event: any) => {
        const { name, value } = event.target;
        switch (name) {

            //// as record UNIQUE ID, so, cannot BE change-ED
            // case 'device':
            //     setdevice(value);
            //     break;
            // case 'timestamp':
            //     settimestamp(value);
            //     break;
            //// schema.sql, unique: tilting_checkpoint, easting, northing, alert, action, alarm, date_of_initial_reading, initial_reading, date
            
            case 'date':
                setdate(value);
                break;
            case 'ring_count':
                setring_count(parsefloat_nonan(value));
                break;
            case 'angle_tite':
                setangle_tite(parsefloat_nonan(value));
                break;
            case 'angle_rotation':
                setangle_rotation(parsefloat_nonan(value));
                break;
            case 'angle_x_axis_tilt':
                setangle_x_axis_tilt(parsefloat_nonan(value));
                break;
            case 'angle_y_axis_tilt':
                setangle_y_axis_tilt(parsefloat_nonan(value));
                break;
            
            case 'pressure_guidance_system_shield':
                setpressure_guidance_system_shield(parsefloat_nonan(value));
                break;
            case 'pressure_guidance_system_ground':
                setpressure_guidance_system_ground(parsefloat_nonan(value));
                break;
            
            case 'temperature_hyd_oil_tank_cutterhead':
                settemperature_hyd_oil_tank_cutterhead(parsefloat_nonan(value));
                break;
            case 'temperature_hyd_oil_tank_propulsion':
                settemperature_hyd_oil_tank_propulsion(parsefloat_nonan(value));
                break;
            
            case 'main_drive_water_temperature':
                setmain_drive_water_temperature(parsefloat_nonan(value));
                break;
            case 'main_drive_water_flow':
                setmain_drive_water_flow(parsefloat_nonan(value));
                break;
            case 'main_bearing_oil_flow':
                setmain_bearing_oil_flow(parsefloat_nonan(value));
                break;
            case 'oil_gas_seal_pressure':
                setoil_gas_seal_pressure(parsefloat_nonan(value));
                break;
            case 'gear_oil_temperature':
                setgear_oil_temperature(parsefloat_nonan(value));
                break;
            case 'main_drive_grease_flow':
                setmain_drive_grease_flow(parsefloat_nonan(value));
                break;
            
            case 'cutterhead_speed':
                setcutterhead_speed(parsefloat_nonan(value));
                break;
            case 'cutterhead_hyd_pump_outlet_pressure':
                setcutterhead_hyd_pump_outlet_pressure(parsefloat_nonan(value));
                break;
            case 'cutterhead_torque':
                setcutterhead_torque(parsefloat_nonan(value));
                break;
            case 'cutterhead_oil_leakage_temperature':
                setcutterhead_oil_leakage_temperature(parsefloat_nonan(value));
                break;
            case 'cutterhead_oil_charge_pump_pressure':
                setcutterhead_oil_charge_pump_pressure(parsefloat_nonan(value));
                break;
            
            case 'excavation_speed_setting':
                setexcavation_speed_setting(parsefloat_nonan(value));
                break;
            case 'excavation_pump_outlet_pressure':
                setexcavation_pump_outlet_pressure(parsefloat_nonan(value));
                break;
            case 'excavation_average_speed':
                setexcavation_average_speed(parsefloat_nonan(value));
                break;
            case 'penetration':
                setpenetration(parsefloat_nonan(value));
                break;
            case 'total_force':
                settotal_force(parsefloat_nonan(value));
                break;
            case 'pressure_auxiliary_pump':
                setpressure_auxiliary_pump(parsefloat_nonan(value));
                break;
            
            case 'steering_jack_angle':
                setsteering_jack_angle(parsefloat_nonan(value));
                break;
            case 'steering_jack_axis_angle':
                setsteering_jack_axis_angle(parsefloat_nonan(value));
                break;
            case 'steering_jack_ext_1':
                setsteering_jack_ext_1(parsefloat_nonan(value));
                break;
            case 'steering_jack_ext_2':
                setsteering_jack_ext_2(parsefloat_nonan(value));
                break;
            case 'steering_jack_ext_3':
                setsteering_jack_ext_3(parsefloat_nonan(value));
                break;
            case 'steering_jack_ext_4':
                setsteering_jack_ext_4(parsefloat_nonan(value));
                break;
            case 'steering_jack_pressure_1':
                setsteering_jack_pressure_1(parsefloat_nonan(value));
                break;
            case 'steering_jack_pressure_2':
                setsteering_jack_pressure_2(parsefloat_nonan(value));
                break;
            case 'steering_jack_pressure_3':
                setsteering_jack_pressure_3(parsefloat_nonan(value));
                break;
            case 'steering_jack_pressure_4':
                setsteering_jack_pressure_4(parsefloat_nonan(value));
                break;
            
            case 'bentonite_flow_rate':
                setbentonite_flow_rate(parsefloat_nonan(value));
                break;
            case 'bentonite_pressure':
                setbentonite_pressure(parsefloat_nonan(value));
                break;
            
            case 'excavation_chamber_pressure_gauge_1':
                setexcavation_chamber_pressure_gauge_1(parsefloat_nonan(value));
                break;
            case 'excavation_chamber_pressure_gauge_2':
                setexcavation_chamber_pressure_gauge_2(parsefloat_nonan(value));
                break;
            case 'excavation_chamber_pressure_gauge_3':
                setexcavation_chamber_pressure_gauge_3(parsefloat_nonan(value));
                break;
            
            case 'slurry_inflow_rate':
                setslurry_inflow_rate(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_rate':
                setslurry_outflow_rate(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_speed_setting_p1_1':
                setslurry_inflow_speed_setting_p1_1(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_speed_setting_p1_2':
                setslurry_inflow_speed_setting_p1_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_speed_setting_p2_1':
                setslurry_outflow_speed_setting_p2_1(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_speed_setting_p2_2':
                setslurry_outflow_speed_setting_p2_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_speed_setting_p2_3':
                setslurry_outflow_speed_setting_p2_3(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_pressure_p1_1':
                setslurry_inflow_pressure_p1_1(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_pressure_p1_2':
                setslurry_inflow_pressure_p1_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_pressure_p1_2':
                setslurry_outflow_pressure_p1_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_pressure_p1_1':
                setslurry_outflow_pressure_p1_1(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_pressure_p2_1':
                setslurry_inflow_pressure_p2_1(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_pressure_p2_1':
                setslurry_outflow_pressure_p2_1(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_pressure_p2_2':
                setslurry_inflow_pressure_p2_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_pressure_p2_2':
                setslurry_outflow_pressure_p2_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_speed_p2_1':
                setslurry_outflow_speed_p2_1(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_speed_p2_2':
                setslurry_outflow_speed_p2_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_current_p2_1':
                setslurry_outflow_current_p2_1(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_current_p2_2':
                setslurry_outflow_current_p2_2(parsefloat_nonan(value));
                break;
            
            case 't11y_transformer_phasel1_voltage':
                sett11y_transformer_phasel1_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_phasel2_voltage':
                sett11y_transformer_phasel2_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_phasel3_voltage':
                sett11y_transformer_phasel3_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_linel1l2_voltage':
                sett11y_transformer_linel1l2_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_linel2l3_voltage':
                sett11y_transformer_linel2l3_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_linel3l1_voltage':
                sett11y_transformer_linel3l1_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_phasel1_current':
                sett11y_transformer_phasel1_current(parsefloat_nonan(value));
                break;
            case 't11y_transformer_phasel2_current':
                sett11y_transformer_phasel2_current(parsefloat_nonan(value));
                break;
            case 't11y_transformer_phasel3_current':
                sett11y_transformer_phasel3_current(parsefloat_nonan(value));
                break;
            case 't11y_transformer_freq':
                sett11y_transformer_freq(parsefloat_nonan(value));
                break;
            case 't11y_transformer_active_power':
                sett11y_transformer_active_power(parsefloat_nonan(value));
                break;
            case 't11y_transformer_passive_power':
                sett11y_transformer_passive_power(parsefloat_nonan(value));
                break;
            case 't11y_transformer_power_factor':
                sett11y_transformer_power_factor(parsefloat_nonan(value));
                break;
            case 't11y_transformer_input_power':
                sett11y_transformer_input_power(parsefloat_nonan(value));
                break;
            case 't11y_transformer_output_power':
                sett11y_transformer_output_power(parsefloat_nonan(value));
                break;
            
            case 'feedback_speed_p1_1':
                setfeedback_speed_p1_1(parsefloat_nonan(value));
                break;
            case 'feedback_current_p1_1':
                setfeedback_current_p1_1(parsefloat_nonan(value));
                break;
            
            case 'excavation_jack_ext_2':
                setexcavation_jack_ext_2(parsefloat_nonan(value));
                break;
            case 'excavation_jack_ext_5':
                setexcavation_jack_ext_5(parsefloat_nonan(value));
                break;
            
            case 'intermediate_jack1_grp1_ext':
                setintermediate_jack1_grp1_ext(parsefloat_nonan(value));
                break;
            case 'intermediate_jack2_grp1_ext':
                setintermediate_jack2_grp1_ext(parsefloat_nonan(value));
                break;
            case 'intermediate_jack1_grp2_ext':
                setintermediate_jack1_grp2_ext(parsefloat_nonan(value));
                break;
            case 'intermediate_jack2_grp2_ext':
                setintermediate_jack2_grp2_ext(parsefloat_nonan(value));
                break;
            
            case 'concentration_co2':
                setconcentration_co2(parsefloat_nonan(value));
                break;
            case 'concentration_o2':
                setconcentration_o2(parsefloat_nonan(value));
                break;
            case 'concentration_co':
                setconcentration_co(parsefloat_nonan(value));
                break;
            case 'concentration_ch4':
                setconcentration_ch4(parsefloat_nonan(value));
                break;
            case 'concentration_h2s':
                setconcentration_h2s(parsefloat_nonan(value));
                break;
            
            case 'distance':
                setdistance(parsefloat_nonan(value));
                break;
            
                    
            default:
                break;
        }
    };
    

    //// startup: script to run
    useEffect(() => {
        // [fixme, depends on table, DataElement]
        setdate(date)
        setring_count(ring_count)
        setangle_tite(angle_tite)
        setangle_rotation(angle_rotation)
        setangle_x_axis_tilt(angle_x_axis_tilt)
        setangle_y_axis_tilt(angle_y_axis_tilt)

        setpressure_guidance_system_shield(pressure_guidance_system_shield)
        setpressure_guidance_system_ground(pressure_guidance_system_ground)

        settemperature_hyd_oil_tank_cutterhead(temperature_hyd_oil_tank_cutterhead)
        settemperature_hyd_oil_tank_propulsion(temperature_hyd_oil_tank_propulsion)

        setmain_drive_water_temperature(main_drive_water_temperature)
        setmain_drive_water_flow(main_drive_water_flow)
        setmain_bearing_oil_flow(main_bearing_oil_flow)
        setoil_gas_seal_pressure(oil_gas_seal_pressure)
        setgear_oil_temperature(gear_oil_temperature)
        setmain_drive_grease_flow(main_drive_grease_flow)

        setcutterhead_speed(cutterhead_speed)
        setcutterhead_hyd_pump_outlet_pressure(cutterhead_hyd_pump_outlet_pressure)
        setcutterhead_torque(cutterhead_torque)
        setcutterhead_oil_leakage_temperature(cutterhead_oil_leakage_temperature)
        setcutterhead_oil_charge_pump_pressure(cutterhead_oil_charge_pump_pressure)

        setexcavation_speed_setting(excavation_speed_setting)
        setexcavation_pump_outlet_pressure(excavation_pump_outlet_pressure)
        setexcavation_average_speed(excavation_average_speed)
        setpenetration(penetration)
        settotal_force(total_force)
        setpressure_auxiliary_pump(pressure_auxiliary_pump)

        setsteering_jack_angle(steering_jack_angle)
        setsteering_jack_axis_angle(steering_jack_axis_angle)
        setsteering_jack_ext_1(steering_jack_ext_1)
        setsteering_jack_ext_2(steering_jack_ext_2)
        setsteering_jack_ext_3(steering_jack_ext_3)
        setsteering_jack_ext_4(steering_jack_ext_4)
        setsteering_jack_pressure_1(steering_jack_pressure_1)
        setsteering_jack_pressure_2(steering_jack_pressure_2)
        setsteering_jack_pressure_3(steering_jack_pressure_3)
        setsteering_jack_pressure_4(steering_jack_pressure_4)

        setbentonite_flow_rate(bentonite_flow_rate)
        setbentonite_pressure(bentonite_pressure)

        setexcavation_chamber_pressure_gauge_1(excavation_chamber_pressure_gauge_1)
        setexcavation_chamber_pressure_gauge_2(excavation_chamber_pressure_gauge_2)
        setexcavation_chamber_pressure_gauge_3(excavation_chamber_pressure_gauge_3)

        setslurry_inflow_rate(slurry_inflow_rate)
        setslurry_outflow_rate(slurry_outflow_rate)
        setslurry_inflow_speed_setting_p1_1(slurry_inflow_speed_setting_p1_1)
        setslurry_inflow_speed_setting_p1_2(slurry_inflow_speed_setting_p1_2)
        setslurry_outflow_speed_setting_p2_1(slurry_outflow_speed_setting_p2_1)
        setslurry_outflow_speed_setting_p2_2(slurry_outflow_speed_setting_p2_2)
        setslurry_outflow_speed_setting_p2_3(slurry_outflow_speed_setting_p2_3)
        setslurry_inflow_pressure_p1_1(slurry_inflow_pressure_p1_1)
        setslurry_inflow_pressure_p1_2(slurry_inflow_pressure_p1_2)
        setslurry_outflow_pressure_p1_2(slurry_outflow_pressure_p1_2)
        setslurry_outflow_pressure_p1_1(slurry_outflow_pressure_p1_1)
        setslurry_inflow_pressure_p2_1(slurry_inflow_pressure_p2_1)
        setslurry_outflow_pressure_p2_1(slurry_outflow_pressure_p2_1)
        setslurry_inflow_pressure_p2_2(slurry_inflow_pressure_p2_2)
        setslurry_outflow_pressure_p2_2(slurry_outflow_pressure_p2_2)
        setslurry_outflow_speed_p2_1(slurry_outflow_speed_p2_1)
        setslurry_outflow_speed_p2_2(slurry_outflow_speed_p2_2)
        setslurry_outflow_current_p2_1(slurry_outflow_current_p2_1)
        setslurry_outflow_current_p2_2(slurry_outflow_current_p2_2)

        sett11y_transformer_phasel1_voltage(t11y_transformer_phasel1_voltage)
        sett11y_transformer_phasel2_voltage(t11y_transformer_phasel2_voltage)
        sett11y_transformer_phasel3_voltage(t11y_transformer_phasel3_voltage)
        sett11y_transformer_linel1l2_voltage(t11y_transformer_linel1l2_voltage)
        sett11y_transformer_linel2l3_voltage(t11y_transformer_linel2l3_voltage)
        sett11y_transformer_linel3l1_voltage(t11y_transformer_linel3l1_voltage)
        sett11y_transformer_phasel1_current(t11y_transformer_phasel1_current)
        sett11y_transformer_phasel2_current(t11y_transformer_phasel2_current)
        sett11y_transformer_phasel3_current(t11y_transformer_phasel3_current)
        sett11y_transformer_freq(t11y_transformer_freq)
        sett11y_transformer_active_power(t11y_transformer_active_power)
        sett11y_transformer_passive_power(t11y_transformer_passive_power)
        sett11y_transformer_power_factor(t11y_transformer_power_factor)
        sett11y_transformer_input_power(t11y_transformer_input_power)
        sett11y_transformer_output_power(t11y_transformer_output_power)

        setfeedback_speed_p1_1(feedback_speed_p1_1)
        setfeedback_current_p1_1(feedback_current_p1_1)

        setexcavation_jack_ext_2(excavation_jack_ext_2)
        setexcavation_jack_ext_5(excavation_jack_ext_5)

        setintermediate_jack1_grp1_ext(intermediate_jack1_grp1_ext)
        setintermediate_jack2_grp1_ext(intermediate_jack2_grp1_ext)
        setintermediate_jack1_grp2_ext(intermediate_jack1_grp2_ext)
        setintermediate_jack2_grp2_ext(intermediate_jack2_grp2_ext)

        setconcentration_co2(concentration_co2)
        setconcentration_o2(concentration_o2)
        setconcentration_co(concentration_co)
        setconcentration_ch4(concentration_ch4)
        setconcentration_h2s(concentration_h2s)

        setdistance(distance)
        // if ( xx ) {
        //     setbuttonlock(false);
        // } else {
        //     setbuttonlock(true);
        // }
    }, [        
        // [fixme, depends on table, DataElement] - put a value that will CHANGE, something CHANGE -> all state CHANGE
        date
    ]);


    return (
        <Dialog className='receiveBtn' open={triggerOpen} onClose={handleClose}>            
            <DialogContent >

                <br/>

                {/* // [fixme, depends on table, DataElement] */}
                <Form className='w-form-group-width'>                                    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> date </Form.Label>
                        <Form.Control disabled type="text" placeholder="YYYY-MM-DD 00:00:00" size="sm"
                            name='date' value={date} onChange={handleinput}
                        />
                    </Form.Group>                    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> ring_count </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='ring_count' value={ring_count} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> angle_tite </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='angle_tite' value={angle_tite} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> angle_rotation </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='angle_rotation' value={angle_rotation} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> angle_x_axis_tilt </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='angle_x_axis_tilt' value={angle_x_axis_tilt} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> angle_y_axis_tilt </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='angle_y_axis_tilt' value={angle_y_axis_tilt} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> pressure_guidance_system_shield </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='pressure_guidance_system_shield' value={pressure_guidance_system_shield} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> pressure_guidance_system_ground </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='pressure_guidance_system_ground' value={pressure_guidance_system_ground} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> temperature_hyd_oil_tank_cutterhead </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='temperature_hyd_oil_tank_cutterhead' value={temperature_hyd_oil_tank_cutterhead} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> temperature_hyd_oil_tank_propulsion </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='temperature_hyd_oil_tank_propulsion' value={temperature_hyd_oil_tank_propulsion} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> main_drive_water_temperature </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='main_drive_water_temperature' value={main_drive_water_temperature} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> main_drive_water_flow </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='main_drive_water_flow' value={main_drive_water_flow} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> main_bearing_oil_flow </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='main_bearing_oil_flow' value={main_bearing_oil_flow} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> oil_gas_seal_pressure </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='oil_gas_seal_pressure' value={oil_gas_seal_pressure} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> gear_oil_temperature </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='gear_oil_temperature' value={gear_oil_temperature} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> main_drive_grease_flow </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='main_drive_grease_flow' value={main_drive_grease_flow} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> cutterhead_speed </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='cutterhead_speed' value={cutterhead_speed} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> cutterhead_hyd_pump_outlet_pressure </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='cutterhead_hyd_pump_outlet_pressure' value={cutterhead_hyd_pump_outlet_pressure} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> cutterhead_torque </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='cutterhead_torque' value={cutterhead_torque} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> cutterhead_oil_leakage_temperature </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='cutterhead_oil_leakage_temperature' value={cutterhead_oil_leakage_temperature} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> cutterhead_oil_charge_pump_pressure </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='cutterhead_oil_charge_pump_pressure' value={cutterhead_oil_charge_pump_pressure} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_speed_setting </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_speed_setting' value={excavation_speed_setting} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_pump_outlet_pressure </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_pump_outlet_pressure' value={excavation_pump_outlet_pressure} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_average_speed </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_average_speed' value={excavation_average_speed} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> penetration </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='penetration' value={penetration} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> total_force </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='total_force' value={total_force} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> pressure_auxiliary_pump </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='pressure_auxiliary_pump' value={pressure_auxiliary_pump} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_angle </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_angle' value={steering_jack_angle} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_axis_angle </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_axis_angle' value={steering_jack_axis_angle} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_ext_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_ext_1' value={steering_jack_ext_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_ext_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_ext_2' value={steering_jack_ext_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_ext_3 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_ext_3' value={steering_jack_ext_3} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_ext_4 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_ext_4' value={steering_jack_ext_4} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_pressure_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_pressure_1' value={steering_jack_pressure_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_pressure_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_pressure_2' value={steering_jack_pressure_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_pressure_3 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_pressure_3' value={steering_jack_pressure_3} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_pressure_4 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_pressure_4' value={steering_jack_pressure_4} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> bentonite_flow_rate </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='bentonite_flow_rate' value={bentonite_flow_rate} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> bentonite_pressure </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='bentonite_pressure' value={bentonite_pressure} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_chamber_pressure_gauge_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_chamber_pressure_gauge_1' value={excavation_chamber_pressure_gauge_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_chamber_pressure_gauge_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_chamber_pressure_gauge_2' value={excavation_chamber_pressure_gauge_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_chamber_pressure_gauge_3 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_chamber_pressure_gauge_3' value={excavation_chamber_pressure_gauge_3} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_rate </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_rate' value={slurry_inflow_rate} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_rate </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_rate' value={slurry_outflow_rate} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_speed_setting_p1_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_speed_setting_p1_1' value={slurry_inflow_speed_setting_p1_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_speed_setting_p1_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_speed_setting_p1_2' value={slurry_inflow_speed_setting_p1_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_speed_setting_p2_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_speed_setting_p2_1' value={slurry_outflow_speed_setting_p2_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_speed_setting_p2_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_speed_setting_p2_2' value={slurry_outflow_speed_setting_p2_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_speed_setting_p2_3 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_speed_setting_p2_3' value={slurry_outflow_speed_setting_p2_3} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_pressure_p1_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_pressure_p1_1' value={slurry_inflow_pressure_p1_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_pressure_p1_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_pressure_p1_2' value={slurry_inflow_pressure_p1_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_pressure_p1_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_pressure_p1_2' value={slurry_outflow_pressure_p1_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_pressure_p1_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_pressure_p1_1' value={slurry_outflow_pressure_p1_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_pressure_p2_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_pressure_p2_1' value={slurry_inflow_pressure_p2_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_pressure_p2_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_pressure_p2_1' value={slurry_outflow_pressure_p2_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_pressure_p2_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_pressure_p2_2' value={slurry_inflow_pressure_p2_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_pressure_p2_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_pressure_p2_2' value={slurry_outflow_pressure_p2_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_speed_p2_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_speed_p2_1' value={slurry_outflow_speed_p2_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_speed_p2_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_speed_p2_2' value={slurry_outflow_speed_p2_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_current_p2_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_current_p2_1' value={slurry_outflow_current_p2_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_current_p2_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_current_p2_2' value={slurry_outflow_current_p2_2} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel1_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel1_voltage' value={t11y_transformer_phasel1_voltage} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel2_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel2_voltage' value={t11y_transformer_phasel2_voltage} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel3_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel3_voltage' value={t11y_transformer_phasel3_voltage} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_linel1l2_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_linel1l2_voltage' value={t11y_transformer_linel1l2_voltage} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_linel2l3_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_linel2l3_voltage' value={t11y_transformer_linel2l3_voltage} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_linel3l1_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_linel3l1_voltage' value={t11y_transformer_linel3l1_voltage} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel1_current </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel1_current' value={t11y_transformer_phasel1_current} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel2_current </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel2_current' value={t11y_transformer_phasel2_current} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel3_current </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel3_current' value={t11y_transformer_phasel3_current} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_freq </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_freq' value={t11y_transformer_freq} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_active_power </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_active_power' value={t11y_transformer_active_power} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_passive_power </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_passive_power' value={t11y_transformer_passive_power} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_power_factor </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_power_factor' value={t11y_transformer_power_factor} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_input_power </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_input_power' value={t11y_transformer_input_power} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_output_power </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_output_power' value={t11y_transformer_output_power} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> feedback_speed_p1_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='feedback_speed_p1_1' value={feedback_speed_p1_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> feedback_current_p1_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='feedback_current_p1_1' value={feedback_current_p1_1} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_jack_ext_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_jack_ext_2' value={excavation_jack_ext_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_jack_ext_5 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_jack_ext_5' value={excavation_jack_ext_5} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> intermediate_jack1_grp1_ext </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='intermediate_jack1_grp1_ext' value={intermediate_jack1_grp1_ext} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> intermediate_jack2_grp1_ext </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='intermediate_jack2_grp1_ext' value={intermediate_jack2_grp1_ext} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> intermediate_jack1_grp2_ext </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='intermediate_jack1_grp2_ext' value={intermediate_jack1_grp2_ext} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> intermediate_jack2_grp2_ext </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='intermediate_jack2_grp2_ext' value={intermediate_jack2_grp2_ext} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> concentration_co2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='concentration_co2' value={concentration_co2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> concentration_o2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='concentration_o2' value={concentration_o2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> concentration_co </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='concentration_co' value={concentration_co} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> concentration_ch4 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='concentration_ch4' value={concentration_ch4} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> concentration_h2s </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='concentration_h2s' value={concentration_h2s} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> distance </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='distance' value={distance} onChange={handleinput}
                        />
                    </Form.Group>
                    

                    <br/>
                    
                    <Button
                        className='mr19i themeBgi'
                        variant="contained"
                        color="secondary"
                        onClick={handleconfirmDialogEdit}
                        disabled={buttonlock}
                    >
                        Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        // className='mr20i'
                        variant="contained"
                        // color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                </Form>

                <br/>
                
            </DialogContent>
        </Dialog>
    );
}


function DialogImportExcelFile(
    { 
        triggerOpen, handleClose, 
        setimportExcelFile, importExcelFile, onloadprogressExcelFile,
        handleconfirmImportExcel
    }
    ) {
    return (
        <Dialog open={triggerOpen} onClose={handleClose}>
            <DialogContent>
                <Form>

                    <br/>

                    {(importExcelFile && onloadprogressExcelFile !== 0) ? (
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                aria-valuenow={onloadprogressExcelFile}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: onloadprogressExcelFile + "%" }}
                            >
                                {onloadprogressExcelFile}%
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <p/>
                            </div>
                        </div>
                    )}

                    <br/>

                    <Card>
                        <br/>
                        <label className="btn btn-default">
                            <input type="file" onChange={setimportExcelFile} />
                        </label>
                    </Card>

                    <br/>
                    <br/>

                    &nbsp;&nbsp;
                    <Button
                            className='mr19i themeBgi'
                            variant="contained"
                            color="secondary"
                            onClick={handleconfirmImportExcel}
                            // disabled={buttonlock}
                        >
                            Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        // className='mr20i'
                        variant="contained"
                        // color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Form>
            </DialogContent>
            <br/>
        </Dialog>
    );
}


function DialogOperationResult({ triggerOpen, handleClose, dialogOperationResultState }) {
    return (
        <Dialog open={triggerOpen} onClose={handleClose}>
            <DialogContent>
                {
                    dialogOperationResultState ? 
                    (
                        <div>
                            <div className='w-dialog-operation-result'>
                                &radic;
                            </div>
                            <p className='red'>Operation Success!</p>
                        </div>
                    ) : (
                        <div>
                            <div className='w-dialog-operation-result'>
                                &Chi;
                            </div>
                            <p className='red'>Operation Failure!</p>
                        </div>
                    )
                }
            </DialogContent>
        </Dialog>
    );
}


//@ts-ignore
function DialogAdd(
    { 
        triggerOpen, handleClose, 

        // [fixme, depends on table, DataElement]
        // device,timestamp,verticaloffset,
        setdate, 
            setring_count,
            setangle_tite,
            setangle_rotation,
            setangle_x_axis_tilt,
            setangle_y_axis_tilt,

            setpressure_guidance_system_shield,
            setpressure_guidance_system_ground,

            settemperature_hyd_oil_tank_cutterhead,
            settemperature_hyd_oil_tank_propulsion,

            setmain_drive_water_temperature,
            setmain_drive_water_flow,
            setmain_bearing_oil_flow,
            setoil_gas_seal_pressure,
            setgear_oil_temperature,
            setmain_drive_grease_flow,

            setcutterhead_speed,
            setcutterhead_hyd_pump_outlet_pressure,
            setcutterhead_torque,
            setcutterhead_oil_leakage_temperature,
            setcutterhead_oil_charge_pump_pressure,

            setexcavation_speed_setting,
            setexcavation_pump_outlet_pressure,
            setexcavation_average_speed,
            setpenetration,
            settotal_force,
            setpressure_auxiliary_pump,

            setsteering_jack_angle,
            setsteering_jack_axis_angle,
            setsteering_jack_ext_1,
            setsteering_jack_ext_2,
            setsteering_jack_ext_3,
            setsteering_jack_ext_4,
            setsteering_jack_pressure_1,
            setsteering_jack_pressure_2,
            setsteering_jack_pressure_3,
            setsteering_jack_pressure_4,

            setbentonite_flow_rate,
            setbentonite_pressure,

            setexcavation_chamber_pressure_gauge_1,
            setexcavation_chamber_pressure_gauge_2,
            setexcavation_chamber_pressure_gauge_3,

            setslurry_inflow_rate,
            setslurry_outflow_rate,
            setslurry_inflow_speed_setting_p1_1,
            setslurry_inflow_speed_setting_p1_2,
            setslurry_outflow_speed_setting_p2_1,
            setslurry_outflow_speed_setting_p2_2,
            setslurry_outflow_speed_setting_p2_3,
            setslurry_inflow_pressure_p1_1,
            setslurry_inflow_pressure_p1_2,
            setslurry_outflow_pressure_p1_2,
            setslurry_outflow_pressure_p1_1,
            setslurry_inflow_pressure_p2_1,
            setslurry_outflow_pressure_p2_1,
            setslurry_inflow_pressure_p2_2,
            setslurry_outflow_pressure_p2_2,
            setslurry_outflow_speed_p2_1,
            setslurry_outflow_speed_p2_2,
            setslurry_outflow_current_p2_1,
            setslurry_outflow_current_p2_2,

            sett11y_transformer_phasel1_voltage,
            sett11y_transformer_phasel2_voltage,
            sett11y_transformer_phasel3_voltage,
            sett11y_transformer_linel1l2_voltage,
            sett11y_transformer_linel2l3_voltage,
            sett11y_transformer_linel3l1_voltage,
            sett11y_transformer_phasel1_current,
            sett11y_transformer_phasel2_current,
            sett11y_transformer_phasel3_current,
            sett11y_transformer_freq,
            sett11y_transformer_active_power,
            sett11y_transformer_passive_power,
            sett11y_transformer_power_factor,
            sett11y_transformer_input_power,
            sett11y_transformer_output_power,

            setfeedback_speed_p1_1,
            setfeedback_current_p1_1,

            setexcavation_jack_ext_2,
            setexcavation_jack_ext_5,

            setintermediate_jack1_grp1_ext,
            setintermediate_jack2_grp1_ext,
            setintermediate_jack1_grp2_ext,
            setintermediate_jack2_grp2_ext,

            setconcentration_co2,
            setconcentration_o2,
            setconcentration_co,
            setconcentration_ch4,
            setconcentration_h2s,

            setdistance,

        // [fixme, magic refresh table after edit / update]
        handleconfirmDialogAdd,
        openDialogOperationResult
    }) 
    {    

    const cssStyle = makeStyles((theme: Theme) =>
        createStyles({
            circleGroup: {
                display: "flex",
                alignItems: "center",
            },
            circleButton: {
                color: "#bdbdbd",
                padding: "0 4px",
                "&:hover": {
                    backgroundColor: "transparent",
                    color: "#8B0000",
                },
            },
            circleSelected: {
                color: "#8B0000",
            },
            buttonRow: {
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "16px",
            },
            solidButton: {
                margin: "8px",
                fontWeight: 600,
                "&:hover": {
                    backgroundColor: "#8B0000",
                    color: "#fff",
                },
            },
        }))

    const classes = cssStyle();

    // initialization: (fixme: not yet) read origin values        
    //  [fixme]
    // useEffect(() => {
    //     console.log('[DialogAdd] initStatus:', initStatus);
    //     setSelectedCircle(initStatus)
    //     console.log('[DialogAdd] selectedCircle:',selectedCircle);
    // }, [initStatus]);

    const handleinput = (event: any) => {
        const { name, value } = event.target;
        switch (name) {
            //// as record UNIQUE ID, so, cannot BE change-ED
            case 'date':
                setdate(value);
                break;
            case 'ring_count':
                setring_count(parsefloat_nonan(value));
                break;
            case 'angle_tite':
                setangle_tite(parsefloat_nonan(value));
                break;
            case 'angle_rotation':
                setangle_rotation(parsefloat_nonan(value));
                break;
            case 'angle_x_axis_tilt':
                setangle_x_axis_tilt(parsefloat_nonan(value));
                break;
            case 'angle_y_axis_tilt':
                setangle_y_axis_tilt(parsefloat_nonan(value));
                break;
            
            case 'pressure_guidance_system_shield':
                setpressure_guidance_system_shield(parsefloat_nonan(value));
                break;
            case 'pressure_guidance_system_ground':
                setpressure_guidance_system_ground(parsefloat_nonan(value));
                break;
            
            case 'temperature_hyd_oil_tank_cutterhead':
                settemperature_hyd_oil_tank_cutterhead(parsefloat_nonan(value));
                break;
            case 'temperature_hyd_oil_tank_propulsion':
                settemperature_hyd_oil_tank_propulsion(parsefloat_nonan(value));
                break;
            
            case 'main_drive_water_temperature':
                setmain_drive_water_temperature(parsefloat_nonan(value));
                break;
            case 'main_drive_water_flow':
                setmain_drive_water_flow(parsefloat_nonan(value));
                break;
            case 'main_bearing_oil_flow':
                setmain_bearing_oil_flow(parsefloat_nonan(value));
                break;
            case 'oil_gas_seal_pressure':
                setoil_gas_seal_pressure(parsefloat_nonan(value));
                break;
            case 'gear_oil_temperature':
                setgear_oil_temperature(parsefloat_nonan(value));
                break;
            case 'main_drive_grease_flow':
                setmain_drive_grease_flow(parsefloat_nonan(value));
                break;
            
            case 'cutterhead_speed':
                setcutterhead_speed(parsefloat_nonan(value));
                break;
            case 'cutterhead_hyd_pump_outlet_pressure':
                setcutterhead_hyd_pump_outlet_pressure(parsefloat_nonan(value));
                break;
            case 'cutterhead_torque':
                setcutterhead_torque(parsefloat_nonan(value));
                break;
            case 'cutterhead_oil_leakage_temperature':
                setcutterhead_oil_leakage_temperature(parsefloat_nonan(value));
                break;
            case 'cutterhead_oil_charge_pump_pressure':
                setcutterhead_oil_charge_pump_pressure(parsefloat_nonan(value));
                break;
            
            case 'excavation_speed_setting':
                setexcavation_speed_setting(parsefloat_nonan(value));
                break;
            case 'excavation_pump_outlet_pressure':
                setexcavation_pump_outlet_pressure(parsefloat_nonan(value));
                break;
            case 'excavation_average_speed':
                setexcavation_average_speed(parsefloat_nonan(value));
                break;
            case 'penetration':
                setpenetration(parsefloat_nonan(value));
                break;
            case 'total_force':
                settotal_force(parsefloat_nonan(value));
                break;
            case 'pressure_auxiliary_pump':
                setpressure_auxiliary_pump(parsefloat_nonan(value));
                break;
            
            case 'steering_jack_angle':
                setsteering_jack_angle(parsefloat_nonan(value));
                break;
            case 'steering_jack_axis_angle':
                setsteering_jack_axis_angle(parsefloat_nonan(value));
                break;
            case 'steering_jack_ext_1':
                setsteering_jack_ext_1(parsefloat_nonan(value));
                break;
            case 'steering_jack_ext_2':
                setsteering_jack_ext_2(parsefloat_nonan(value));
                break;
            case 'steering_jack_ext_3':
                setsteering_jack_ext_3(parsefloat_nonan(value));
                break;
            case 'steering_jack_ext_4':
                setsteering_jack_ext_4(parsefloat_nonan(value));
                break;
            case 'steering_jack_pressure_1':
                setsteering_jack_pressure_1(parsefloat_nonan(value));
                break;
            case 'steering_jack_pressure_2':
                setsteering_jack_pressure_2(parsefloat_nonan(value));
                break;
            case 'steering_jack_pressure_3':
                setsteering_jack_pressure_3(parsefloat_nonan(value));
                break;
            case 'steering_jack_pressure_4':
                setsteering_jack_pressure_4(parsefloat_nonan(value));
                break;
            
            case 'bentonite_flow_rate':
                setbentonite_flow_rate(parsefloat_nonan(value));
                break;
            case 'bentonite_pressure':
                setbentonite_pressure(parsefloat_nonan(value));
                break;
            
            case 'excavation_chamber_pressure_gauge_1':
                setexcavation_chamber_pressure_gauge_1(parsefloat_nonan(value));
                break;
            case 'excavation_chamber_pressure_gauge_2':
                setexcavation_chamber_pressure_gauge_2(parsefloat_nonan(value));
                break;
            case 'excavation_chamber_pressure_gauge_3':
                setexcavation_chamber_pressure_gauge_3(parsefloat_nonan(value));
                break;
            
            case 'slurry_inflow_rate':
                setslurry_inflow_rate(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_rate':
                setslurry_outflow_rate(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_speed_setting_p1_1':
                setslurry_inflow_speed_setting_p1_1(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_speed_setting_p1_2':
                setslurry_inflow_speed_setting_p1_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_speed_setting_p2_1':
                setslurry_outflow_speed_setting_p2_1(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_speed_setting_p2_2':
                setslurry_outflow_speed_setting_p2_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_speed_setting_p2_3':
                setslurry_outflow_speed_setting_p2_3(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_pressure_p1_1':
                setslurry_inflow_pressure_p1_1(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_pressure_p1_2':
                setslurry_inflow_pressure_p1_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_pressure_p1_2':
                setslurry_outflow_pressure_p1_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_pressure_p1_1':
                setslurry_outflow_pressure_p1_1(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_pressure_p2_1':
                setslurry_inflow_pressure_p2_1(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_pressure_p2_1':
                setslurry_outflow_pressure_p2_1(parsefloat_nonan(value));
                break;
            case 'slurry_inflow_pressure_p2_2':
                setslurry_inflow_pressure_p2_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_pressure_p2_2':
                setslurry_outflow_pressure_p2_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_speed_p2_1':
                setslurry_outflow_speed_p2_1(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_speed_p2_2':
                setslurry_outflow_speed_p2_2(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_current_p2_1':
                setslurry_outflow_current_p2_1(parsefloat_nonan(value));
                break;
            case 'slurry_outflow_current_p2_2':
                setslurry_outflow_current_p2_2(parsefloat_nonan(value));
                break;
            
            case 't11y_transformer_phasel1_voltage':
                sett11y_transformer_phasel1_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_phasel2_voltage':
                sett11y_transformer_phasel2_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_phasel3_voltage':
                sett11y_transformer_phasel3_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_linel1l2_voltage':
                sett11y_transformer_linel1l2_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_linel2l3_voltage':
                sett11y_transformer_linel2l3_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_linel3l1_voltage':
                sett11y_transformer_linel3l1_voltage(parsefloat_nonan(value));
                break;
            case 't11y_transformer_phasel1_current':
                sett11y_transformer_phasel1_current(parsefloat_nonan(value));
                break;
            case 't11y_transformer_phasel2_current':
                sett11y_transformer_phasel2_current(parsefloat_nonan(value));
                break;
            case 't11y_transformer_phasel3_current':
                sett11y_transformer_phasel3_current(parsefloat_nonan(value));
                break;
            case 't11y_transformer_freq':
                sett11y_transformer_freq(parsefloat_nonan(value));
                break;
            case 't11y_transformer_active_power':
                sett11y_transformer_active_power(parsefloat_nonan(value));
                break;
            case 't11y_transformer_passive_power':
                sett11y_transformer_passive_power(parsefloat_nonan(value));
                break;
            case 't11y_transformer_power_factor':
                sett11y_transformer_power_factor(parsefloat_nonan(value));
                break;
            case 't11y_transformer_input_power':
                sett11y_transformer_input_power(parsefloat_nonan(value));
                break;
            case 't11y_transformer_output_power':
                sett11y_transformer_output_power(parsefloat_nonan(value));
                break;
            
            case 'feedback_speed_p1_1':
                setfeedback_speed_p1_1(parsefloat_nonan(value));
                break;
            case 'feedback_current_p1_1':
                setfeedback_current_p1_1(parsefloat_nonan(value));
                break;
            
            case 'excavation_jack_ext_2':
                setexcavation_jack_ext_2(parsefloat_nonan(value));
                break;
            case 'excavation_jack_ext_5':
                setexcavation_jack_ext_5(parsefloat_nonan(value));
                break;
            
            case 'intermediate_jack1_grp1_ext':
                setintermediate_jack1_grp1_ext(parsefloat_nonan(value));
                break;
            case 'intermediate_jack2_grp1_ext':
                setintermediate_jack2_grp1_ext(parsefloat_nonan(value));
                break;
            case 'intermediate_jack1_grp2_ext':
                setintermediate_jack1_grp2_ext(parsefloat_nonan(value));
                break;
            case 'intermediate_jack2_grp2_ext':
                setintermediate_jack2_grp2_ext(parsefloat_nonan(value));
                break;
            
            case 'concentration_co2':
                setconcentration_co2(parsefloat_nonan(value));
                break;
            case 'concentration_o2':
                setconcentration_o2(parsefloat_nonan(value));
                break;
            case 'concentration_co':
                setconcentration_co(parsefloat_nonan(value));
                break;
            case 'concentration_ch4':
                setconcentration_ch4(parsefloat_nonan(value));
                break;
            case 'concentration_h2s':
                setconcentration_h2s(parsefloat_nonan(value));
                break;
            
            case 'distance':
                setdistance(parsefloat_nonan(value));
                break;


            default:
                break;
        }
    };

    //// startup: script to run
    //// *** copy ABOVE
    useEffect(() => {
        // [fixme, depends on table, DataElement]
        setdate('')
        setring_count(0)
        setangle_tite(0)
        setangle_rotation(0)
        setangle_x_axis_tilt(0)
        setangle_y_axis_tilt(0)

        setpressure_guidance_system_shield(0)
        setpressure_guidance_system_ground(0)

        settemperature_hyd_oil_tank_cutterhead(0)
        settemperature_hyd_oil_tank_propulsion(0)

        setmain_drive_water_temperature(0)
        setmain_drive_water_flow(0)
        setmain_bearing_oil_flow(0)
        setoil_gas_seal_pressure(0)
        setgear_oil_temperature(0)
        setmain_drive_grease_flow(0)

        setcutterhead_speed(0)
        setcutterhead_hyd_pump_outlet_pressure(0)
        setcutterhead_torque(0)
        setcutterhead_oil_leakage_temperature(0)
        setcutterhead_oil_charge_pump_pressure(0)

        setexcavation_speed_setting(0)
        setexcavation_pump_outlet_pressure(0)
        setexcavation_average_speed(0)
        setpenetration(0)
        settotal_force(0)
        setpressure_auxiliary_pump(0)

        setsteering_jack_angle(0)
        setsteering_jack_axis_angle(0)
        setsteering_jack_ext_1(0)
        setsteering_jack_ext_2(0)
        setsteering_jack_ext_3(0)
        setsteering_jack_ext_4(0)
        setsteering_jack_pressure_1(0)
        setsteering_jack_pressure_2(0)
        setsteering_jack_pressure_3(0)
        setsteering_jack_pressure_4(0)

        setbentonite_flow_rate(0)
        setbentonite_pressure(0)

        setexcavation_chamber_pressure_gauge_1(0)
        setexcavation_chamber_pressure_gauge_2(0)
        setexcavation_chamber_pressure_gauge_3(0)

        setslurry_inflow_rate(0)
        setslurry_outflow_rate(0)
        setslurry_inflow_speed_setting_p1_1(0)
        setslurry_inflow_speed_setting_p1_2(0)
        setslurry_outflow_speed_setting_p2_1(0)
        setslurry_outflow_speed_setting_p2_2(0)
        setslurry_outflow_speed_setting_p2_3(0)
        setslurry_inflow_pressure_p1_1(0)
        setslurry_inflow_pressure_p1_2(0)
        setslurry_outflow_pressure_p1_2(0)
        setslurry_outflow_pressure_p1_1(0)
        setslurry_inflow_pressure_p2_1(0)
        setslurry_outflow_pressure_p2_1(0)
        setslurry_inflow_pressure_p2_2(0)
        setslurry_outflow_pressure_p2_2(0)
        setslurry_outflow_speed_p2_1(0)
        setslurry_outflow_speed_p2_2(0)
        setslurry_outflow_current_p2_1(0)
        setslurry_outflow_current_p2_2(0)

        sett11y_transformer_phasel1_voltage(0)
        sett11y_transformer_phasel2_voltage(0)
        sett11y_transformer_phasel3_voltage(0)
        sett11y_transformer_linel1l2_voltage(0)
        sett11y_transformer_linel2l3_voltage(0)
        sett11y_transformer_linel3l1_voltage(0)
        sett11y_transformer_phasel1_current(0)
        sett11y_transformer_phasel2_current(0)
        sett11y_transformer_phasel3_current(0)
        sett11y_transformer_freq(0)
        sett11y_transformer_active_power(0)
        sett11y_transformer_passive_power(0)
        sett11y_transformer_power_factor(0)
        sett11y_transformer_input_power(0)
        sett11y_transformer_output_power(0)
        
        setfeedback_speed_p1_1(0)
        setfeedback_current_p1_1(0)
        
        setexcavation_jack_ext_2(0)
        setexcavation_jack_ext_5(0)

        setintermediate_jack1_grp1_ext(0)
        setintermediate_jack2_grp1_ext(0)
        setintermediate_jack1_grp2_ext(0)
        setintermediate_jack2_grp2_ext(0)

        setconcentration_co2(0)
        setconcentration_o2(0)
        setconcentration_co(0)
        setconcentration_ch4(0)
        setconcentration_h2s(0)

        setdistance(0)
        // if ( xx ) {
        //     setbuttonlock(false);
        // } else {
        //     setbuttonlock(true);
        // }
    }, [        
        // [fixme, depends on table, DataElement] - put a value that will CHANGE, something CHANGE -> all state CHANGE
        // blast_no, date, sensitive_receiver_and_reference_no
    ]);
    
    return (
        <Dialog open={triggerOpen} onClose={handleClose}>
            <DialogContent>

                <br/>
                
                {/* // [fixme, depends on table, DataElement] */}
                <Form className='w-form-group-width'>                    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> date </Form.Label>
                        <Form.Control type="text" placeholder="YYYY-MM-DD 00:00:00" size="sm"
                            name='date' onChange={handleinput}
                        />
                    </Form.Group>                    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> ring_count </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='ring_count' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> angle_tite </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='angle_tite' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> angle_rotation </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='angle_rotation' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> angle_x_axis_tilt </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='angle_x_axis_tilt' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> angle_y_axis_tilt </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='angle_y_axis_tilt' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> pressure_guidance_system_shield </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='pressure_guidance_system_shield' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> pressure_guidance_system_ground </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='pressure_guidance_system_ground' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> temperature_hyd_oil_tank_cutterhead </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='temperature_hyd_oil_tank_cutterhead' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> temperature_hyd_oil_tank_propulsion </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='temperature_hyd_oil_tank_propulsion' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> main_drive_water_temperature </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='main_drive_water_temperature' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> main_drive_water_flow </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='main_drive_water_flow' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> main_bearing_oil_flow </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='main_bearing_oil_flow' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> oil_gas_seal_pressure </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='oil_gas_seal_pressure' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> gear_oil_temperature </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='gear_oil_temperature' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> main_drive_grease_flow </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='main_drive_grease_flow' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> cutterhead_speed </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='cutterhead_speed' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> cutterhead_hyd_pump_outlet_pressure </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='cutterhead_hyd_pump_outlet_pressure' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> cutterhead_torque </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='cutterhead_torque' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> cutterhead_oil_leakage_temperature </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='cutterhead_oil_leakage_temperature' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> cutterhead_oil_charge_pump_pressure </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='cutterhead_oil_charge_pump_pressure' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_speed_setting </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_speed_setting' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_pump_outlet_pressure </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_pump_outlet_pressure' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_average_speed </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_average_speed' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> penetration </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='penetration' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> total_force </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='total_force' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> pressure_auxiliary_pump </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='pressure_auxiliary_pump' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_angle </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_angle' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_axis_angle </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_axis_angle' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_ext_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_ext_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_ext_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_ext_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_ext_3 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_ext_3' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_ext_4 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_ext_4' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_pressure_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_pressure_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_pressure_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_pressure_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_pressure_3 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_pressure_3' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> steering_jack_pressure_4 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='steering_jack_pressure_4' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> bentonite_flow_rate </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='bentonite_flow_rate' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> bentonite_pressure </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='bentonite_pressure' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_chamber_pressure_gauge_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_chamber_pressure_gauge_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_chamber_pressure_gauge_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_chamber_pressure_gauge_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_chamber_pressure_gauge_3 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_chamber_pressure_gauge_3' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_rate </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_rate' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_rate </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_rate' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_speed_setting_p1_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_speed_setting_p1_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_speed_setting_p1_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_speed_setting_p1_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_speed_setting_p2_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_speed_setting_p2_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_speed_setting_p2_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_speed_setting_p2_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_speed_setting_p2_3 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_speed_setting_p2_3' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_pressure_p1_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_pressure_p1_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_pressure_p1_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_pressure_p1_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_pressure_p1_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_pressure_p1_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_pressure_p1_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_pressure_p1_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_pressure_p2_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_pressure_p2_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_pressure_p2_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_pressure_p2_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_inflow_pressure_p2_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_inflow_pressure_p2_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_pressure_p2_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_pressure_p2_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_speed_p2_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_speed_p2_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_speed_p2_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_speed_p2_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_current_p2_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_current_p2_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> slurry_outflow_current_p2_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='slurry_outflow_current_p2_2' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel1_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel1_voltage' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel2_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel2_voltage' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel3_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel3_voltage' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_linel1l2_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_linel1l2_voltage' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_linel2l3_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_linel2l3_voltage' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_linel3l1_voltage </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_linel3l1_voltage' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel1_current </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel1_current' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel2_current </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel2_current' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_phasel3_current </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_phasel3_current' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_freq </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_freq' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_active_power </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_active_power' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_passive_power </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_passive_power' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_power_factor </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_power_factor' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_input_power </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_input_power' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> t11y_transformer_output_power </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='t11y_transformer_output_power' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> feedback_speed_p1_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='feedback_speed_p1_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> feedback_current_p1_1 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='feedback_current_p1_1' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_jack_ext_2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_jack_ext_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> excavation_jack_ext_5 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='excavation_jack_ext_5' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> intermediate_jack1_grp1_ext </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='intermediate_jack1_grp1_ext' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> intermediate_jack2_grp1_ext </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='intermediate_jack2_grp1_ext' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> intermediate_jack1_grp2_ext </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='intermediate_jack1_grp2_ext' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> intermediate_jack2_grp2_ext </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='intermediate_jack2_grp2_ext' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> concentration_co2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='concentration_co2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> concentration_o2 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='concentration_o2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> concentration_co </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='concentration_co' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> concentration_ch4 </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='concentration_ch4' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> concentration_h2s </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='concentration_h2s' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> distance </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='distance' onChange={handleinput}
                        />
                    </Form.Group>
                    
                    

                    <br/>
                    
                    <Button
                        className='mr19i themeBgi'
                        variant="contained"
                        color="secondary"
                        onClick={handleconfirmDialogAdd}
                        // disabled={buttonlock}
                    >
                        Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        // className='mr20i'
                        variant="contained"
                        // color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                </Form>

                <br/>
                
            </DialogContent>
        </Dialog>
    );
}
