import { combineReducers, applyMiddleware, createStore } from "redux";
import reduxThunk from "redux-thunk";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { loginReducer } from "../auth/loginReducer";

const rootReducer = combineReducers({
    loginReducer,
});

//// [persistor] purpose for debug, see variable in explorer-Application-LocalStorage
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['loginReducer']
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(reduxThunk));
const persistor = persistStore(store);

export { store, persistor };