//@ts-nocheck
import { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { Button, Card, CardActions, CardContent, CardActionArea, CardMedia, Typography} from '@material-ui/core';
import { Col, Row, Container } from "react-bootstrap"
import Plot from 'react-plotly.js';
import { useHistory } from "react-router-dom";

import background from '../../img/loading_curve1.gif'

import { apifetch_get as apifetch_get1 } from '../../services/apifetch/ApiWeather'
import { apifetch_search as apifetch_get2 } from '../../services/apifetch/ApiConvergency'
import { apifetch_get as apifetch_get3 } from '../../services/apifetch/ApiSurveyForBlasting'

export function Home2() {

    /////////////////////////////////////////////////////////////////////////////////
    ////// statics: row1 --- start
    const [loading1, setloading1] = useState<boolean>(true);
    // data container, for Plotly!!
    const [datacandlebox1, setdatacandlebox1]=useState([]);
    const [latestnumber1, setlatestnumber1]=useState(0);

    ////// backend api --- start
    //// api, getALL
    const initPlotData1 = async () => {
        setloading1(true);
        const responsedata = await apifetch_get1(
            {                
                "page_number": 1,
                "item_per_page": 48,
                "sequence": "descending"
            }
        );
        console.log("[initPlotData1] responsedata", responsedata);
        console.log("[initPlotData1] responsedata.weathers", responsedata.weathers);

        transform1_initPlotData1(responsedata.weathers)
    }
    ////// backend api --- end

    const transform1_initPlotData1 = (response_initPlotData: any) => {
        const records = response_initPlotData
        const x_name = "timestamp"
        const y_name = "temperature"
        let x: any = [];
        let y: any = [];
        records.forEach( (datum: any, i: number) => 
            {
                if (i===0) {
                    setlatestnumber1(datum[y_name])
                }
                x.push(datum[x_name]);
                y.push(datum[y_name]);                
            }
        )
        console.log("[transform1_initPlotData1] x:", x)
        console.log("[transform1_initPlotData1] y:", y)
        var result = { 
            x, 
            y,
            type: 'scatter',
            mode: 'lines+markers',
            marker: {color: 'red'},
         }
        setdatacandlebox1(result)
        setloading1(false);
    }    
    ////// statics: row1 --- end
    /////////////////////////////////////////////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////////
    ////// statics: row2 --- start
    const [loading2, setloading2] = useState<boolean>(true);
    // data container, for Plotly!!
    const [datacandlebox2, setdatacandlebox2]=useState([]);
    const [latestnumber2, setlatestnumber2]=useState(0);

    ////// backend api --- start
    //// api, getALL
    const initPlotData2 = async () => {
        setloading2(true);
        const responsedata = await apifetch_get2(
            {                
                "page_number": 1,
                "item_per_page": 20,
                "sequence": "descending",
                "search_param": "A:C"
            }
        );
        console.log("[initPlotData2] responsedata", responsedata);
        console.log("[initPlotData2] responsedata.schema_template", responsedata.schema_template);

        transform1_initPlotData2(responsedata.schema_template)
    }
    ////// backend api --- end

    const transform1_initPlotData2 = (response_initPlotData: any) => {
        const records = response_initPlotData
        const x_name = "date"
        const y_name = "reading"
        let x: any = [];
        let y: any = [];
        records.forEach( (datum: any, i: number) => 
            {
                if (i===0) {
                    setlatestnumber2(datum[y_name])
                }
                x.push(datum[x_name]);
                y.push(datum[y_name]);                
            }
        )
        console.log("[transform1_initPlotData2] x:", x)
        console.log("[transform1_initPlotData2] y:", y)
        var result = { 
            x, 
            y,
            type: 'scatter',
            mode: 'markers',
            marker: {color: 'blue'},
         }
        setdatacandlebox2(result)
        setloading2(false);
    }    
    ////// statics: row2 --- end
    /////////////////////////////////////////////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////////
    ////// statics: row3 (latest state ONLY) --- start
    const [loading3, setloading3] = useState<boolean>(true);
    // data container, for Plotly!!
    const [datacandlebox3, setdatacandlebox3]=useState([]);
    const [latestnumber3a, setlatestnumber3a]=useState(0);
    const [latestnumber3b, setlatestnumber3b]=useState(0);
    const [latestnumber3c, setlatestnumber3c]=useState(0);

    ////// backend api --- start
    //// api, getALL
    const initPlotData3 = async () => {
        setloading3(true);
        const responsedata = await apifetch_get3(
            {                
                "page_number": 1,
                "item_per_page": 2,
                "sequence": "descending"
            }
        );
        console.log("[initPlotData3] responsedata", responsedata);
        console.log("[initPlotData3] responsedata.schema_template", responsedata.schema_template);

        transform1_initPlotData3(responsedata.schema_template)
    }
    ////// backend api --- end

    const transform1_initPlotData3 = (response_initPlotData: any) => {
        const records = response_initPlotData
        const x_name = "created_at"
        const y_name = "end_area_volume"
        const y_name_b = "overbreak_underbreak_volume"
        const y_name_c = "planned_volume"
        let x: any = [];
        let y: any = [];
        records.forEach( (datum: any, i: number) => 
            {
                if (i===0) {
                    setlatestnumber3a(datum[y_name])
                    setlatestnumber3b(datum[y_name_b])
                    setlatestnumber3c(datum[y_name_c])
                }
                x.push(datum[x_name]);
                y.push(datum[y_name]);                
            }
        )
        console.log("[transform1_initPlotData3] x:", x)
        console.log("[transform1_initPlotData3] y:", y)
        var result = { 
            x, 
            y,
            type: 'scatter',
            mode: 'lines+markers',
            marker: {color: 'red'},
         }
        setdatacandlebox3(result)
        setloading3(false);
    }    
    ////// statics: row3 (latest state ONLY) --- end
    /////////////////////////////////////////////////////////////////////////////////


    //// startup, data reading - api, getALL
    useEffect(() => {
        async function initGraph() {
            initPlotData1()
            initPlotData2()
            initPlotData3()
        }
        initGraph();
    }, []);


    //// route, after button click
    const history = useHistory();

    // CSS related
    const card_content_height = 240
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            themeStyleRoot: { 
                padding: theme.spacing(2),
                flexGrow: 1,
            },
            text_center: {
                textAlign: 'center'
            },
            card_root: {
                // maxWidth: 345,
                // height: 300,
            },
            card_content: {
                height: card_content_height,
            },
            card_media: {
                height: 240,
            },
        })
    );
    const classesStyle = useStyles();

    return (
        <div className={classesStyle.themeStyleRoot}>

            //@ts-ignore
            {console.log("[return] datacandlebox1:", datacandlebox1)}

            <br/>            
            <br/>
            <div className="backgroundimage-fix"></div>

            <br/>
        
            <Container>
                <secion>
                    <br />
                    <div className="col s12 m6">
                        <h2> UNI Highlights </h2>
                    </div>
                    <br />
                </secion>
                <Row>
                    <Col xs={12} md={4}>
                        <Card className={classesStyle.card_root}>
                            <CardActionArea>                                
                                <CardContent className={classesStyle.card_content}>
                                    <br/>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Weather
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        temperature
                                    </Typography>
                                    <br/>
                                    <br/>
                                    
                                    { loading1 ? 
                                        (
                                            <CardMedia
                                                image={background}
                                                title="loading"
                                            />
                                        ) : (
                                            <Typography variant="h6" color="textSecondary" component="p" className='w-textcenter'>
                                                {latestnumber1}
                                            </Typography>
                                        )
                                    }

                                    <Typography variant="body2" color="textSecondary" component="p" className='w-textcenter'>
                                        (latest value)
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={()=>history.push('/weather')}>
                                    more
                                </Button>
                            </CardActions>
                        </Card>
                    </Col>
                    <Col xs={12} md={8}>
                        <Card className={classesStyle.card_root}>                            
                            <CardContent className={classesStyle.card_content}>
                                    { loading1 ? 
                                        (
                                            <CardMedia
                                                image={background}
                                                title="loading"
                                            />
                                        ) : (
                                            <Plot
                                                config={{ displayModeBar: false }}
                                                data={
                                                    [
                                                        datacandlebox1,
                                                    ]
                                                }
                                                layout={{
                                                    margin: {
                                                        l: 60,
                                                        r: 50,
                                                        b: 50,
                                                        t: 50,
                                                        pad: 0,
                                                    },
                                                    autosize: true,
                                                    xaxis: {
                                                    type: "datetime",
                                                    // rangeslider: { visible: true },
                                                    // domain: [0, 0.9],
                                                    title: "(lastest 48 data)",
                                                    },
                                                    yaxis: {
                                                        title: "temperature",
                                                    side: "left",
                                                    // domain: [0.84, 1],
                                                    },
                                                    height: card_content_height
                                                }}
                                                useResizeHandler={true}
                                                style={{ width: "100%"}}
                                            />
                                        )
                                    }
                            </CardContent>
                            
                            <CardActions>
                                <Button size="small" color="primary" onClick={()=>history.push('/weather')}>
                                    more
                                </Button>
                            </CardActions>
                        </Card>
                    </Col>                    
                </Row>
                <br/>

                <Row>
                    <Col xs={12} md={4}>
                        <Card className={classesStyle.card_root}>
                            <CardActionArea>                                
                                <CardContent className={classesStyle.card_content}>
                                    <br/>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Convergency
                                    </Typography>
                                    <Typography gutterBottom variant="h7" component="h7">
                                        Markings (A:C)
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        reading
                                    </Typography>
                                    <br/>
                                    <br/>
                                    
                                    { loading1 ? 
                                        (
                                            <CardMedia
                                                image={background}
                                                title="loading"
                                            />
                                        ) : (
                                            <Typography variant="h6" color="textSecondary" component="p" className='w-textcenter'>
                                                {latestnumber2}
                                            </Typography>
                                        )
                                    }

                                    <Typography variant="body2" color="textSecondary" component="p" className='w-textcenter'>
                                        (latest value)
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={()=>history.push('/convergency')}>
                                    more
                                </Button>
                            </CardActions>
                        </Card>
                    </Col>
                    <Col xs={12} md={8}>
                        <Card className={classesStyle.card_root}>                            
                            <CardContent className={classesStyle.card_content}>
                                    { loading1 ? 
                                        (
                                            <CardMedia
                                                image={background}
                                                title="loading"
                                            />
                                        ) : (
                                            <Plot
                                                config={{ displayModeBar: false }}
                                                data={
                                                    [
                                                        datacandlebox2,
                                                    ]
                                                }
                                                layout={{
                                                    margin: {
                                                        l: 60,
                                                        r: 50,
                                                        b: 50,
                                                        t: 50,
                                                        pad: 0,
                                                    },
                                                    autosize: true,
                                                    xaxis: {
                                                    type: "datetime",
                                                    // rangeslider: { visible: true },
                                                    // domain: [0, 0.9],
                                                    title: "(lastest 20 data)",
                                                    },
                                                    yaxis: {
                                                        title: "reading",
                                                    side: "left",
                                                    // domain: [0.84, 1],
                                                    },
                                                    height: card_content_height
                                                }}
                                                useResizeHandler={true}
                                                style={{ width: "100%"}}
                                            />
                                        )
                                    }
                            </CardContent>
                            
                            <CardActions>
                                <Button size="small" color="primary" onClick={()=>history.push('/convergency')}>
                                    more
                                </Button>
                            </CardActions>
                        </Card>
                    </Col>                    
                </Row>
                <br/>

            </Container>
            
            <br/>

            <div className="backgroundimage-fix">
                <img className='imagedisplay-block' src={background} width={100} alt="" />
            </div>
            <div className="backgroundimage-fix">
                <img className='imagedisplay-block' src={background} width={100} alt="" />
            </div>
            <br/>

            <Container>
                <secion>
                    <br />
                    <div className="col s12 m6">
                        <h2> Survey for Blasting - Highlights </h2>
                    </div>
                    <br />
                </secion>
                <Row>
                    <Col xs={12} md={4}>
                        <Card className={classesStyle.card_root}>
                            <CardActionArea>                                
                                <CardContent className={classesStyle.card_content}>
                                    <br/>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Survey for Blasting
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        end area volume
                                    </Typography>
                                    <br/>
                                    <br/>
                                    
                                    { loading3 ? 
                                        (
                                            <CardMedia
                                                image={background}
                                                title="loading"
                                            />
                                        ) : (
                                            <Typography variant="h6" color="textSecondary" component="p" className='w-textcenter'>
                                                {latestnumber3a}
                                            </Typography>
                                        )
                                    }

                                    <Typography variant="body2" color="textSecondary" component="p" className='w-textcenter'>
                                        (latest value)
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={()=>history.push('/survey_for_blasting')}>
                                    more
                                </Button>
                            </CardActions>
                        </Card>
                    </Col>   

                    <Col xs={12} md={4}>
                        <Card className={classesStyle.card_root}>
                            <CardActionArea>                                
                                <CardContent className={classesStyle.card_content}>
                                    <br/>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Survey for Blasting
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        overbreak underbreak volume
                                    </Typography>
                                    <br/>
                                    <br/>
                                    
                                    { loading3 ? 
                                        (
                                            <CardMedia
                                                image={background}
                                                title="loading"
                                            />
                                        ) : (
                                            <Typography variant="h6" color="textSecondary" component="p" className='w-textcenter'>
                                                {latestnumber3b}
                                            </Typography>
                                        )
                                    }

                                    <Typography variant="body2" color="textSecondary" component="p" className='w-textcenter'>
                                        (latest value)
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={()=>history.push('/survey_for_blasting')}>
                                    more
                                </Button>
                            </CardActions>
                        </Card>
                    </Col> 

                    <Col xs={12} md={4}>
                        <Card className={classesStyle.card_root}>
                            <CardActionArea>                                
                                <CardContent className={classesStyle.card_content}>
                                    <br/>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Survey for Blasting
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        planned volume
                                    </Typography>
                                    <br/>
                                    <br/>
                                    
                                    { loading3 ? 
                                        (
                                            <CardMedia
                                                image={background}
                                                title="loading"
                                            />
                                        ) : (
                                            <Typography variant="h6" color="textSecondary" component="p" className='w-textcenter'>
                                                {latestnumber3c}
                                            </Typography>
                                        )
                                    }

                                    <Typography variant="body2" color="textSecondary" component="p" className='w-textcenter'>
                                        (latest value)
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={()=>history.push('/survey_for_blasting')}>
                                    more
                                </Button>
                            </CardActions>
                        </Card>
                    </Col>                  
                </Row>
                <br/>                

            </Container>
            <br/>
            
            <div className="backgroundimage-fix">
                <img className='imagedisplay-block' src={background} width={100} alt="" />
            </div>     
        </div>
    )
}
