const initialState = {
    isAuthenticated: null,
    web3Addr: '',
    web3Privatekey: '',
    email: '',    
    profile_information_version: '',
    profile_information: ''
};

export const loginReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case '@@auth/loginSuccess':
            return {
                ...state,
                isAuthenticated: true,
                web3Addr: actions.payload.address,
                web3Privatekey: actions.payload.private_key,
                email: actions.payload.email,
                profile_information_version: actions.payload.profile_information_version,
                profile_information: actions.payload.profile_information,
            }
        case '@@auth/logoutSuccess':
            return {
                ...state,
                isAuthenticated: false,
                web3Addr: '',
                web3Privatekey: '',         
                email: '',       
                profile_information_version: '',          
                profile_information: '',
                qrcode: ''
            }
        default:
            return state;
    }
}