import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Home } from './components/core/Home'
import CNavbar from "./components/core/CNavbar"
import LoadingSpinner from './components/subcomponents/LoadingSpinner';
import { Login } from './components/auth/Login'
import { TemplateTable } from './components/core/TemplateTable'
import { TemplateTableGraph } from './components/core/TemplateTableGraph';
import { TemplateEffectBackgroundimagefix } from './components/core/TemplateEffectBackgroundimagefix';
import TemplateButtonSplitDropMenu from './components/core/TemplateButtonSplitDropMenu';
import { TemplateButtonSplitDropMenu_Convergency } from './components/core/TemplateButtonSplitDropMenu_Convergency';
import { Home2 } from './components/core/Home2';

import { AirOverPressure } from './components/core/AirOverPressure';
import { Blasting } from './components/core/Blasting';
import { Vibration } from './components/core/Vibration';
import { Weather } from './components/core/Weather';
import { AirDeviceMeasurement } from './components/core/AirDeviceMeasurement';
import { NoiseDeviceLocationMeasurement } from './components/core/NoiseDeviceLocationMeasurement';
import { GroundwaterAllCombined } from './components/core/GroundwaterAllCombined';
import { Mapping } from './components/core/Mapping';
import { BlastingOverbreak } from './components/core/BlastingOverbreak';
import { TenderSchedule } from './components/core/TenderSchedule';
import { SettlementMonitoring } from './components/core/SettlementMonitoring';
import { RockboltStrengthMonitoring } from './components/core/RockboltStrengthMonitoring';
import { SteelBridgeLoadCell } from './components/core/SteelBridgeLoadCell';
import { File } from './components/core/File';

import { TiltingCheckpoint } from './components/core/TiltingCheckpoint';
import { GiStratigraphy } from './components/core/GiStratigraphy';
import { GiSpt } from './components/core/GiSpt';
import { GroundSettlementCheckpoint } from './components/core/GroundSettlementCheckpoint';
import { PipeJackingPrelim } from './components/core/PipeJackingPrelim';
import { SurveyForBlasting } from './components/core/SurveyForBlasting';
import { ChargingAndFiringTime } from './components/core/ChargingAndFiringTime';
import { CutterReplacementRecords } from './components/core/CutterReplacementRecords';
import { CutterTravelDistance } from './components/core/CutterTravelDistance';
import { Convergency } from './components/core/Convergency';
import { NoiseDeviceLocationMeasurement2 } from './components/core/NoiseDeviceLocationMeasurement2';
import { BlastingData2 } from './components/core/BlastingData2';
import { VibrationAndAop } from './components/core/VibrationAndAop';
import { PipeJackingMachine } from './components/core/PipeJackingMachine';
import { AirDeviceMeasurement2 } from './components/core/AirDeviceMeasurement2';
import { Mapping2 } from './components/core/Mapping2';
import { OverbreakSummary } from './components/core/OverbreakSummary';
import { ProbingRecord } from './components/core/ProbingRecord';
import { GroundwaterMonitoring } from './components/core/GroundwaterMonitoring';
import { Weathering } from './components/core/Weathering';
import { SPT } from './components/core/SPT';

import { Provider } from 'react-redux';
import { store } from './redux/store/store';

import AuthGuard from './components/auth/AuthGuard';


function AuthRoutes() {
  const [loading, setLoading] = useState(true);
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

  useEffect(() => {    
    if (isLoggedIn !== true) {
      localStorage.setItem('isLoggedIn', 'false');
    }
    setLoading(false);
  }, [isLoggedIn]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    
    <Switch>        
        <Route exact path="/login" component={Login} />
        {/* <Route exact path="/template_table" component={AuthGuard(TemplateTable)} /> */}
        {/* <Route exact path="/template_table_graph" component={AuthGuard(TemplateTableGraph)} /> */}
        {/* <Route exact path="/template_effect" component={(AuthGuard(TemplateEffectBackgroundimagefix))} /> */}
        {/* <Route exact path="/home" component={AuthGuard(Home)} /> */}
        <Route exact path="/template_buttonsplitdropmenu" component={(AuthGuard(TemplateButtonSplitDropMenu))} />
        <Route exact path="/template_buttonsplitdropmenu_convergency" component={(AuthGuard(TemplateButtonSplitDropMenu_Convergency))} />
        <Route exact path="/home" component={AuthGuard(Home2)} />

        <Route exact path="/air_over_pressure" component={AuthGuard(AirOverPressure)} />
        <Route exact path="/blasting" component={AuthGuard(Blasting)} />
        <Route exact path="/vibration" component={AuthGuard(Vibration)} />
        <Route exact path="/weather" component={AuthGuard(Weather)} />
        <Route exact path="/air_device_measurement" component={AuthGuard(AirDeviceMeasurement)} />
        <Route exact path="/noise_device_location_measurement" component={AuthGuard(NoiseDeviceLocationMeasurement)} />
        <Route exact path="/groundwater_all_combined" component={AuthGuard(GroundwaterAllCombined)} />
        <Route exact path="/mapping" component={AuthGuard(Mapping)} />
        <Route exact path="/blasting_overbreak" component={AuthGuard(BlastingOverbreak)} />
        <Route exact path="/tender_schedule" component={AuthGuard(TenderSchedule)} />
        <Route exact path="/settlement_monitoring" component={AuthGuard(SettlementMonitoring)} />
        <Route exact path="/rockbolt_strength_monitoring" component={AuthGuard(RockboltStrengthMonitoring)} />
        <Route exact path="/steel_bridge_load_cell" component={AuthGuard(SteelBridgeLoadCell)} />
        <Route exact path="/file" component={AuthGuard(File)} />

        <Route exact path="/tilting_checkpoint" component={AuthGuard(TiltingCheckpoint)} />
        <Route exact path="/gi_stratigraphy" component={AuthGuard(GiStratigraphy)} />
        <Route exact path="/gi_spt" component={AuthGuard(GiSpt)} />
        <Route exact path="/ground_settlement_checkpoint" component={AuthGuard(GroundSettlementCheckpoint)} />
        <Route exact path="/pipe_jacking_prelim" component={AuthGuard(PipeJackingPrelim)} />
        <Route exact path="/survey_for_blasting" component={AuthGuard(SurveyForBlasting)} />
        <Route exact path="/charging_and_firing_time" component={AuthGuard(ChargingAndFiringTime)} />
        <Route exact path="/cutter_replacement_records" component={AuthGuard(CutterReplacementRecords)} />
        <Route exact path="/cutter_travel_distance" component={AuthGuard(CutterTravelDistance)} />
        <Route exact path="/convergency" component={AuthGuard(Convergency)} />
        <Route exact path="/device_location_measurement2" component={AuthGuard(NoiseDeviceLocationMeasurement2)} />
        <Route exact path="/blasting_data2" component={AuthGuard(BlastingData2)} />
        <Route exact path="/vibration_and_aop" component={AuthGuard(VibrationAndAop)} />
        <Route exact path="/pipe_jacking_machine" component={AuthGuard(PipeJackingMachine)} />
        <Route exact path="/air_device_measurement2" component={AuthGuard(AirDeviceMeasurement2)} />
        <Route exact path="/mapping2" component={AuthGuard(Mapping2)} />
        <Route exact path="/overbreak_summary" component={AuthGuard(OverbreakSummary)} />
        <Route exact path="/probing_record" component={AuthGuard(ProbingRecord)} />
        <Route exact path="/groundwater_monitoring" component={AuthGuard(GroundwaterMonitoring)} />
        <Route exact path="/weathering" component={AuthGuard(Weathering)} />
        <Route exact path="/spt" component={AuthGuard(SPT)} />
        

        {/* <Route exact path="/" component={AuthGuard(Home2)} /> */}
        <Route exact path="/" component={AuthGuard(Home2)} />
      </Switch>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <CNavbar/>
        <AuthRoutes/>
      </Provider>
    </BrowserRouter>
  );
}

export default App;

