//@ts-nocheck
import React, { useEffect, useState, useRef } from 'react'
import { withStyles, makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles"
import { Card, Container, Form, Image } from "react-bootstrap"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, FormControl, InputAdornment, Input, InputLabel, OutlinedInput, Select, Box, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import { apifetch_get, apifetch_add, apifetch_update, apifetch_search, apifetch_delete, apifetch_importfromexcel, apifetch_exportexcel } from '../../services/apifetch/ApiGroundwaterAllCombined'
import { Refresh, Add, Menu, Search, Edit, Clear, CloudUpload, CloudDownload } from "@material-ui/icons";

import Plot from 'react-plotly.js';
import ImageLoading from "../../img/loading_curve1.gif"
import SwipeableViews from 'react-swipeable-views';

type DataElement = {
    id: number| undefined;

    sol: string | undefined;
    hole_no: string | undefined;
    date: string | undefined;

    groundwater_iflow_single: number| undefined;
    groundwater_iflow_all: number| undefined;

    chainage_from: number| undefined;
    chainage_to: number| undefined;
    length: number| undefined;
    overlap: number | undefined;
    stage: number | undefined;
    hole_type: string | undefined;

    created_at: string;
    updated_at: string;
};


//// css
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#808080',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);
const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'green',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'green',
        },
      },
    },
})(TextField);


//// utility
function parsefloat_nonan(input_string){
    if (input_string !== ''){
        return parseFloat(input_string)
    } else {
        return 0
    }        
}


export function GroundwaterAllCombined() {

    //// data element, initialization
    // [fixme, depends on table, DataElement]
    const [sol, setsol] = useState('');
    const [hole_no, sethole_no] = useState('');       
    const [date, setdate] = useState('');

    const [groundwater_iflow_single, setgroundwater_iflow_single] = useState(0);
    const [groundwater_iflow_all, setgroundwater_iflow_all] = useState(0);

    const [chainage_from, setchainage_from] = useState(0);
    const [chainage_to, setchainage_to] = useState(0);
    const [length, setlength] = useState(0);
    const [overlap, setoverlap] = useState(0);
    const [stage, setstage] = useState(0);
    const [hole_type, sethole_type] = useState('');


    //// data container
    const [templatedata, settemplatedata] = useState<DataElement[]>([])
    const [totalpagenum, settotalpagenum] = useState(5)


    //// pagination (rows per page)
    const page = useRef(0);
    const rowsPerPage = useRef(5);
    // const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event: any, newPage: any) => {
        page.current = newPage
        console.log("[handleChangePage] page.current:", page.current)
        console.log("[handleChangePage] newPage:", newPage)
        // initListData();
        reloadtabledata()
    };
    const handleChangeRowsPerPage = (event: any) => {
        rowsPerPage.current = parseInt(event.target.value);
        initListData();
    };

    
    ////// backend api --- start
    //// api, getALL
    const initListData = async () => {
        const responsedata = await apifetch_get(
            {
                "page_number": page.current+1,
                "item_per_page": rowsPerPage.current,
                "sequence": "descending"
            }
        );
        settemplatedata(responsedata.vibration)
        settotalpagenum(responsedata.count)
        console.log("[initListData] responsedata", responsedata);
        console.log("[initListData] responsedata.vibration", responsedata.vibration);
    }
    //// api, search
    const [searchText, setsearchText] = useState('')
    const searchHandler = (e: any) => {
        setsearchText(e.target.value)
    }
    const searchtemplatedata = async () => {
        const responsedata = await apifetch_search(
            {
                "page_number": page.current+1, 
                "item_per_page": rowsPerPage.current,
                "sequence": "descending",
                "search_param": searchText
            }
        );
        settemplatedata(responsedata.vibration)
        settotalpagenum(responsedata.count)
        console.log("[searchtemplatedata] responsedata:", responsedata);
    }
    ////// backend api --- end


    //// startup, data reading - api, getALL
    useEffect(() => {
        async function init() {
            // getALL list
            initListData()
        }

        init();
    }, []);


    //// non-startup, data reading
    //    - if there exist 'searchText', then response the needed data
    //    - if there exist NO 'searchText', then response 'getALL'
    const reloadtabledata = () => {
        if (searchText) {
            searchtemplatedata()
        } else {
            initListData();
        }
    }


    //// for POPup, DialogEdit, input    
    const [dialogEditOpen, setdialogEditOpen] = useState(false);    
    const openDialogEdit = (
        // [fixme, depends on table, DataElement]
        sol: any,
        hole_no: any,
        date: any,

        groundwater_iflow_single: any,
        groundwater_iflow_all: any,

        chainage_from: any,
        chainage_to: any,
        length: any,
        overlap: any,
        stage: any,
        hole_type: any,
    ) => {
        // [fixme, depends on table, DataElement]
        setsol(sol)
        sethole_no(hole_no)
        setdate(date)
        
        setgroundwater_iflow_single(groundwater_iflow_single)
        setgroundwater_iflow_all(groundwater_iflow_all)

        setchainage_from(chainage_from)
        setchainage_to(chainage_to)
        setlength(length)
        
        setoverlap(overlap)
        setstage(stage)
        sethole_type(hole_type)

        setdialogEditOpen(true);
    };
    const closeDialogEdit = () => {
        setdialogEditOpen(false);
    };
    //// button handler - 'confirm'
    const handleconfirmDialogEdit = async () => {

        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_update(
            {
                "sol": sol,
                "hole_no": hole_no,
                "date": date,
                
                "groundwater_iflow_single": groundwater_iflow_single,
                "groundwater_iflow_all": groundwater_iflow_all,

                "chainage_from": chainage_from,
                "chainage_to": chainage_to,

                "length": length,
                "overlap": overlap,
                "stage": stage,
                "hole_type": hole_type
            }
        )
        console.log(" [handleconfirmDialogEdit] responsedata", responsedata);

        // [fixme, depends on table, DataElement]
        setsol('')
        sethole_no('')
        setdate('')

        setgroundwater_iflow_single(0)
        setgroundwater_iflow_all(0)

        setchainage_from(0)
        setchainage_to(0)

        setlength(0)
        setoverlap(0)
        setstage(0)
        sethole_type('')
        
        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }
    }


    //// button handler - 'delete', no POPup
    const handleDelete = async (inputId) => {

        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_delete(
            {
                "ids": [inputId]
            }
        )
        console.log(" [handleDelete] responsedata", responsedata);
        
        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }
    }


    //// button handler - 'export excel', no POPup
    const handleExportExcel = async () => {

        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_exportexcel("groundwater_measurement.xlsx")
        console.log(" [handleExportExcel] responsedata", responsedata);
    }


    //// for POPup, DialogimportExcelFile, 'import Excel'
    const [dialogimportExcelFileOpen, setdialogimportExcelFileOpen] = useState(false);
    const [importExcelFile, setimportExcelFile] = useState('');
    const [onloadprogressExcelFile, setonloadprogressExcelFile] = useState(0);
    const importExcelFileHandler = (e: any) => {
        console.log("[importExcelFileHandler] e.target:", e.target)        
        console.log("[importExcelFileHandler] e.target.files[0]:", e.target.files[0])
        setimportExcelFile(e.target.files[0])
    }
    const opendialogimportExcelFile = (type) => {
        
        // // [fixme]
        // if (type == 1) {
        //     setdialogOperationResultState(true)
        // } else {
        //     setdialogOperationResultState(false)
        // }

        // closeDialogEdit()
        setonloadprogressExcelFile(0)
        setdialogimportExcelFileOpen(true);
    };
    const closedialogimportExcelFile = () => {
        setdialogimportExcelFileOpen(false);
    };
    const handleconfirmImportExcel = async () => {
        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_importfromexcel(importExcelFile, 
            (event) => {
                setonloadprogressExcelFile(
                    Math.round((100 * event.loaded) / event.total)
                )
                console.log("[handleconfirmImportExcel] Math.round((100 * event.loaded) / event.total):", Math.round((100 * event.loaded) / event.total))
                console.log("[handleconfirmImportExcel] onloadprogressExcelFile:", onloadprogressExcelFile)
            }
        )
        console.log(" [handleconfirmImportExcel] responsedata", responsedata)

        // fixme: operationStatus, loading status bar
        setonloadprogressExcelFile(0)

        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }

        closedialogimportExcelFile()
    }    


    //// for POPup, DialogOperationResult, input -> result (success / failure)
    const [dialogOperationResultOpen, setdialogOperationResultOpen] = useState(false);
    const [dialogOperationResultState, setdialogOperationResultState] = useState(true);
    const openDialogOperationResult = (type) => {
        
        // [fixme]
        if (type === 1) {
            setdialogOperationResultState(true)
        } else {
            setdialogOperationResultState(false)
        }

        closeDialogEdit()
        setdialogOperationResultOpen(true);
    };
    const closeDialogOperationResult = () => {
        setdialogOperationResultOpen(false);
    };


    //// for POPup, DialogAdd
    const [dialogAddOpen, setdialogAddOpen] = useState(false);
    const openDialogAdd = (type: any) => {   
        setdialogAddOpen(true);
    };
    const closeDialogAdd = () => {
        setdialogAddOpen(false);
    };
    const handleconfirmDialogAdd = async () => {

        // [fixme, depends on table, DataElement]        
        const responsedata = await apifetch_add(
            {
                "sol": sol,
                "hole_no": hole_no,
                "date": date,
                
                "groundwater_iflow_single": groundwater_iflow_single,
                "groundwater_iflow_all": groundwater_iflow_all,

                "chainage_from": chainage_from,
                "chainage_to": chainage_to,

                "length": length,
                "overlap": overlap,
                "stage": stage,
                "hole_type": hole_type
            }
        )
        console.log(" [handleconfirmDialogAdd] responsedata:", responsedata)

        // [fixme, depends on table, DataElement]
        setsol('')
        sethole_no('')
        setdate('')

        setgroundwater_iflow_single(0)
        setgroundwater_iflow_all(0)

        setchainage_from(0)
        setchainage_to(0)

        setlength(0)
        setoverlap(0)
        setstage(0)
        sethole_type('')
        
        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }

        closeDialogAdd()
    }
    



    //// CSS related
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            themeStyleRoot: {
                padding: theme.spacing(2),
                flexGrow: 1,
            },
            text_center: {
                textAlign: 'center'
            },
            button_icon: {
                color: "#fff",
                backgroundColor: "#2aa10f",
                height: "30px",
                width: "30px",
            },
            textfield_input: {
                marginLeft: theme.spacing(1),
                flex: 1,
            },
            iconButton_menu: {
                padding: 10,
            },
            iconButton_search: {
                padding: 10,
            },
            margin: {
                margin: theme.spacing(0),
                height: "20px",
            },
            themeTabRoot: {
                backgroundColor: theme.palette.background.paper,
                width: "100%",
            },            
        })
    );
    const classesStyle = useStyles();



    //////////////////////////////////////////////////////////////////////////////
    ////// [Graph / Plot] logic --- start
    //      - category is 'date'
    //        - block is 'legend' on right
    //        - 'date' data in a group -> present in 'candle box' format
    //        - skip '0'?
    const [loading, setLoading] = useState<boolean>(true);
    
    // for Plotly!!
    const [datacandlebox1, setdatacandlebox1]=useState([]);
    // const [datacandlebox2, setdatacandlebox2]=useState([]);
    const rowsPerPageGraph = useRef(50);
    

    ////// backend api --- start
    //// api, getALL
    const initPlotData = async () => {

        setLoading(true);
        const responsedata = await apifetch_get(
            {
                // "page_number": page.current+1,
                "page_number": 1,
                "item_per_page": rowsPerPageGraph.current,
                // "item_per_page": 200,
                "sequence": "descending"
            }
        );
        console.log("[initPlotData] responsedata", responsedata);
        console.log("[initPlotData] responsedata.vibration", responsedata.vibration);

        transform1_initPlotData(responsedata.vibration)
    }
    //// api, search
    // const [searchTextGraph, setsearchTextGraph] = useState('')
    // const searchHandlerGraph = (e: any) => {
    //     setsearchTextGraph(e.target.value)
    // }
    // const searchtemplatedataGraph = async () => {
    //     const responsedata = await apifetch_search(
    //         {
    //             // "page_number": page.current+1,
    //             "page_number": 1,
    //             "item_per_page": rowsPerPageGraph.current,
    //             "sequence": "descending",
    //             "search_param": searchText
    //         }
    //     );        
    //     console.log("[searchtemplatedataGraph] responsedata:", responsedata);
    //     transform1_initPlotData(responsedata.vibration)
    // }
    ////// backend api --- end


    //// transform 1 --- category
    //     - x : category of DATE (no time)
    //     - y1: @DATE CATEGORY -> ARRAY of DATA
    //          - non zero guard
    //          - e.g. 2023-06-02T00:00:00Z, 2023-06-02T01:00:00Z, SAME @DATE CATEGORY
    //          - x to y1, x to y2, x to y3 ...
    const transform1_initPlotData = (response_initPlotData) => {

        let timestamp_splits_date_pre: string;
        let category_counter: number;

        let x: any = [];
        let y: any = [];
        let y_array_element: any = [];
        let y2: any = [];
        let y2_array_element: any = [];
        let y3: any = [];
        let y3_array_element: any = [];

        const records = response_initPlotData        

        const data_y_length = records.length;
        // (only for my console visualization, not related to any STATE variable)
        category_counter = -1;
        records.forEach( (datum: any, i: number) => {

        //// CASE 5 --- category DIFFERENT, last one --- save / commit (y_array_element) to (y, finish a category)
        //              - do it before guard ZERO!!!!!        
            if (i == data_y_length - 1) {
                console.log(" *** [last one] category new, category_counter:", category_counter);

                y.push(y_array_element);
                y2.push(y2_array_element);
                y3.push(y3_array_element);
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y_array_element:",
                    y_array_element
                );
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y2_array_element:",
                    y2_array_element
                );
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y3_array_element:",
                    y3_array_element
                );
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y:", y);
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y2:", y2);
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y3:", y3);
            }
            
            //// guard / filter / skip, non ZERO value (use 'return' like 'continue', in for loop)
            // if (datum["groundwater_iflow_single"] === 0 || datum["humidity"] === 0){
            //     return;
            // }

            const timestamp_splits_date_current = datum["date"].split(" ");
            console.log(
                "[useEffect2] idea... timestamp_splits_date_current[0]:",
                timestamp_splits_date_current[0]
            );
            
            if (timestamp_splits_date_current[0] !== timestamp_splits_date_pre) {

                category_counter = category_counter +1;

                timestamp_splits_date_pre = timestamp_splits_date_current[0];
                console.log(
                    "category new, timestamp_splits_date_current[0]:",
                    timestamp_splits_date_current[0]
                );
                console.log(
                    "category new, timestamp_splits_date_pre:",
                    timestamp_splits_date_pre
                );

                x.push(timestamp_splits_date_current[0]);

        //// CASE 1 --- category DIFFERENT, first one --- accumulate (y_array_element) to (y_array_element)
                if (category_counter == 0) {

                    console.log(
                        i, 
                        "category new, category_counter:", category_counter);

                    y_array_element.push(datum["groundwater_iflow_single"]);
                    y2_array_element.push(datum["humidity"]);
                    y3_array_element.push(datum["rainfall"]);
                    console.log(
                        i,
                        "[1st] category new", category_counter, ", y_array_element:",
                        y_array_element
                    );
                    console.log(
                        i,
                        "[1st] category new", category_counter, ", y2_array_element:",
                        y2_array_element
                    );
                    console.log(
                        i,
                        "[1st] category new", category_counter, ", y3_array_element:",
                        y3_array_element
                    );

                    return
                }                 
                
        //// CASE 2 --- category DIFFERENT, NOT first one 
        //    --2a- save / commit (y_array_element) to (y, finish a category)
        //    --2b- accumulate (y_array_element) to ( NEW y_array_element)

                //    --2a- save / commit (y_array_element) to (y, finish a category)
                y.push(y_array_element);
                y2.push(y2_array_element);
                y3.push(y3_array_element);
                console.log(
                    i,
                    "category new", category_counter, ", y_array_element:",
                    y_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y2_array_element:",
                    y2_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y3_array_element:",
                    y3_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y:", y);
                console.log(
                    i,
                    "category new", category_counter, ", y2:", y2);
                console.log(
                    i,
                    "category new", category_counter, ", y3:", y3);
                

                //    --2b- accumulate (y_array_element) to ( NEW y_array_element)
                y_array_element = [];
                y2_array_element = [];
                y3_array_element = [];
                y_array_element.push(datum["groundwater_iflow_single"]);
                y2_array_element.push(datum["humidity"]);
                y3_array_element.push(datum["rainfall"]);
                console.log(
                    i,
                    "category new", category_counter,", y_array_element:",
                    y_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y2_array_element:",
                    y2_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y3_array_element:",
                    y3_array_element
                );

            } else {

        //// CASE 3 --- category SAME, NOT last one --- accumulate (y_array_element) to (y_array_element)
                console.log(
                    "category old, timestamp_splits_date_current:",
                    timestamp_splits_date_current[0]
                );
                console.log(
                    "category old, timestamp_splits_date_pre:",
                    timestamp_splits_date_pre
                );
                y_array_element.push(datum["groundwater_iflow_single"]);
                y2_array_element.push(datum["humidity"]);
                y3_array_element.push(datum["rainfall"]);
                console.log(
                    i,
                    "category old", category_counter, ", datum[groundwater_iflow_single]:",
                    datum["groundwater_iflow_single"]
                );
                console.log(
                    i,
                    "category old", category_counter, ", y_array_element:", y_array_element);
                console.log(
                    i,
                    "category old", category_counter, ", y2_array_element:", y2_array_element);
                console.log(
                    i,
                    "category old", category_counter, ", y3_array_element:", y3_array_element);

        //// CASE 4 --- category SAME, last one --- save / commit (y_array_element) to (y, finish a category)
                if (i == data_y_length - 1) {
                    console.log(
                        i,
                        "[last one] category old, category_counter:", category_counter);
                    y.push(y_array_element);
                    y2.push(y2_array_element);
                    y3.push(y3_array_element);
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y_array_element:",
                        y_array_element
                    );
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y2_array_element:",
                        y2_array_element
                    );
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y3_array_element:",
                        y3_array_element
                    );
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y:", y);
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y2:", y2);
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y3:", y3);
        
                    category_counter = 0;
                    y_array_element = [];
                    y2_array_element = [];
                    y3_array_element = [];

                    console.log("... [cleaning] after [last one] category old ...")
                    console.log(
                        "[cleaning, check] y_array_element:",
                        y_array_element
                    );
                    console.log(
                        "[cleaning, check] y2_array_element:",
                        y2_array_element
                    );
                    console.log(
                        "[cleaning, check] y3_array_element:",
                        y3_array_element
                    );
                }
            }
        })
        console.log(" [transform1_initPlotData, end] x:", x)
        console.log(" [transform1_initPlotData, end] y:", y)
        transform2_transform1data_candlebox(x, y)
    }
    
    //// transform 2 --- candle data format, fit for plotly
    function transform2_transform1data_candlebox(dataploy_x, dataploy_y) {        
        let data_candlebox: any = [];
        if (dataploy_x !== undefined && dataploy_y !== undefined) {
            for (var i = 0; i < dataploy_x.length; i++) {
                var result = {
                    type: "box",
                    y: dataploy_y[i],
                    name: dataploy_x[i],
                    boxpoints: "all",
                    jitter: 0.5,
                    whiskerwidth: 0.2,
                    fillcolor: "cls",
                    marker: {
                        size: 5,
                    },
                    line: {
                        width: 1,
                    },
                };            
                data_candlebox.push(result);
            }          
            console.log("[transform2, endA] data_candlebox", data_candlebox);

            // setdatacandlebox1( ... datacandlebox1, data_candlebox)
            setdatacandlebox1( data_candlebox )

        } else {
          console.log("[transform2, endB] !data_candlebox, dataploy_x", dataploy_x);
        }
        
        setLoading(false);
    }
    
    //// startup, data reading - api, getALL
    useEffect(() => {
        async function initGraph() {
            initPlotData()
        }
        initGraph();
    }, []);

    //// non-startup, data reading
    //    - (table) if there exist 'searchText', then response the needed data
    //    - if there exist NO 'searchText', then response 'getALL'
    const reloadgraphdata = () => {
        // if (searchTextGraph) {
        //     searchtemplatedataGraph()
        // } else {
        //     initPlotData();
        // }
        initPlotData();
    }

    //// number of records to show on graph
    const handleChangeRowsPerPageGraph = (event: any) => {
        rowsPerPageGraph.current = parseInt(event.target.value);
        reloadgraphdata()
    };

    ////// [Graph / Plot] logic --- end
    //////////////////////////////////////////////////////////////////////////////




    //////////////////////////////////////////////////////////////////////////////
    ////// [Tab / Panel] logic --- start
    const theme = useTheme();
    const [tabvalue, settabvalue] = React.useState(0);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        settabvalue(newValue);
    }    
    const handleTabChangeIndex = (index: number) => {
        settabvalue(index);
    };    

    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: any;
        value: any;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    function tabProps(index: any) {
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
    }
    ////// [Tab / Panel] logic --- end
    //////////////////////////////////////////////////////////////////////////////




    return (
        <div className={classesStyle.themeStyleRoot}>
            <Container>
                <br />
                <br />
                <br />

                <h1 className='tal pb10'> Groundwater Measurement </h1>








                {/* ////// [Tab / Panel] UI --- start */}
                {/* <Paper>
                    <div className={classesStyle.themeTabRoot}>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={tabvalue}
                            onChangeIndex={handleTabChangeIndex}
                        >
                            <TabPanel value={tabvalue} index={0} dir={theme.direction}>
                                abc
                            </TabPanel>
                            <TabPanel value={tabvalue} index={1} dir={theme.direction}>
                                123
                            </TabPanel>
                            <TabPanel value={tabvalue} index={2} dir={theme.direction}>
                                ABC
                            </TabPanel>
                        </SwipeableViews>
                        <AppBar position="static" color="transparent">
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >                                
                                <Tab label="Tab1" {...tabProps(0)} />
                                <Tab label="Tab2" {...tabProps(1)} />
                                <Tab label="Tab3" {...tabProps(2)} />
                            </Tabs>
                        </AppBar>
                    </div>
                </Paper> */}
                {/* ////// [Tab / Panel] UI --- end */}








                
                <hr />
                <br /> 

                







                {/* ////// [Graph / Plot] UI --- start */}                
                {/* {console.log("[main, return] dataPlot.x:", dataPlot.x)}
                {console.log("[main, return] dataPlot.y:", dataPlot.y)} */}                
                {console.log("[main, return] loading:", loading)}
                {console.log("[main, return] datacandlebox1", datacandlebox1)}

                <h3 className='w-grey'> &nbsp; Graph
                    &nbsp;&nbsp;                    
                    <IconButton onClick={reloadgraphdata} className={classesStyle.button_icon}>
                        <Refresh />
                    </IconButton>                    
                </h3>

                <br/>
                {loading ? (
                    <div className="text-center">
                        <Table>
                        <tbody>
                            <tr>
                                <Image src={ImageLoading} fluid />                                
                            </tr>
                            <tr>
                                {/* <h5>Loading</h5> */}
                            </tr>
                        </tbody>
                        </Table>
                    </div>
                    ) : (
                    <div>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="age-native-simple"> &nbsp;&nbsp; Number of Display Records</InputLabel>
                            <Select
                                native
                                value={rowsPerPageGraph.current}
                                onChange={handleChangeRowsPerPageGraph}
                            >
                                <option aria-label="None" value="" />
                                <option value={50}>  &nbsp;&nbsp;&nbsp;&nbsp; 50</option>
                                <option value={500}> &nbsp;&nbsp;&nbsp;&nbsp; 500</option>
                                <option value={1000}>&nbsp;&nbsp;&nbsp;&nbsp; 1000</option>
                                <option value={2000}>&nbsp;&nbsp;&nbsp;&nbsp; 2000</option>
                                <option value={5000}>&nbsp;&nbsp;&nbsp;&nbsp; 5000</option>
                            </Select>
                        </FormControl> 
                        {/* <br/>
                        <br/> */}
                        {/* <FormControl fullWidth>                    
                            <Input
                                placeholder="&nbsp; keyword search"                        
                                // id="outlined-adornment-amount"
                                onChange={searchHandlerGraph}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <IconButton type="submit" className={classesStyle.iconButton_menu} aria-label="search" onClick={searchtemplatedataGraph}>
                                            <Menu />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton type="submit" className={classesStyle.iconButton_search} aria-label="search" onClick={searchtemplatedataGraph}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                // labelWidth={60}
                            />
                        </FormControl> */}

                        <Plot
                            config={{ displayModeBar: false }}
                            data={datacandlebox1}
                            layout={{
                                margin: {
                                    l: 60,
                                    r: 50,
                                    b: 50,
                                    t: 50,
                                    pad: 0,
                                },
                                autosize: true,
                                xaxis: {
                                type: "date",
                                rangeslider: { visible: true },
                                // domain: [0, 0.9],
                                // title: "Device - AM1",
                                },
                                yaxis: {
                                    title: "groundwater inflow single",
                                side: "left",
                                // domain: [0.84, 1],
                                },
                            }}
                            useResizeHandler={true}
                            style={{ width: "100%", height: "100%" }}
                        />
                    </div>
                    )
                }
                {/* ////// [Graph / Plot] UI --- end */}










                <hr/>
                <br/>

                <h3 className='w-grey'> &nbsp; Database
                    &nbsp;&nbsp;
                    <IconButton onClick={() => openDialogAdd("add")} 
                        className={classesStyle.button_icon}
                        // className={[classesStyle.button_add, 'themeBgi'].join(' ')}
                        >
                        <Add />
                    </IconButton>
                    &nbsp;
                    <IconButton onClick={opendialogimportExcelFile} className={classesStyle.button_icon}>
                        <CloudUpload />
                    </IconButton>
                    &nbsp;
                    <IconButton onClick={handleExportExcel} className={classesStyle.button_icon}>
                        <CloudDownload />
                    </IconButton>
                    &nbsp;
                    <IconButton onClick={reloadtabledata} className={classesStyle.button_icon}>
                        <Refresh />
                    </IconButton>                    
                </h3>
                
                <br/>
                <FormControl fullWidth>                    
                    <Input
                        placeholder="&nbsp; keyword search"                        
                        // id="outlined-adornment-amount"
                        onChange={searchHandler}
                        startAdornment={
                            <InputAdornment position="end">
                                <IconButton type="submit" className={classesStyle.iconButton_menu} aria-label="search" onClick={searchtemplatedata}>
                                    <Menu />
                                </IconButton>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton type="submit" className={classesStyle.iconButton_search} aria-label="search" onClick={searchtemplatedata}>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                        // labelWidth={60}
                    />
                </FormControl>                
                
                <br/>
                <br/>

                <TableContainer component={Paper} variant="outlined">
                <Table className='tableBg' aria-label="simple table">
                        <TableHead>
                            <TableRow>                                
                                <StyledTableCell width={80} align="center"> sol </StyledTableCell>
                                <StyledTableCell width={80} align="center"> hole_no </StyledTableCell>
                                <StyledTableCell width={80} align="center"> date </StyledTableCell>

                                <StyledTableCell width={80} align="center"> groundwater_iflow_single </StyledTableCell>
                                <StyledTableCell width={80} align="center"> groundwater_iflow_all </StyledTableCell>

                                <StyledTableCell width={80} align="center"> chainage_from </StyledTableCell>                
                                <StyledTableCell width={80} align="center"> chainage_to </StyledTableCell>

                                <StyledTableCell width={80} align="center"> length </StyledTableCell>
                                <StyledTableCell width={80} align="center"> overlap </StyledTableCell>
                                <StyledTableCell width={80} align="center"> stage </StyledTableCell>
                                <StyledTableCell width={80} align="center"> hole_type </StyledTableCell>

                                <StyledTableCell width={100} align="center">operation</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templatedata ?
                                (
                                    <>
                                        {templatedata.map((row, index) => (
                                            <StyledTableRow key={row.id} className="table-striped">
                                                <TableCell align="center" width={80}>{row.sol}</TableCell>
                                                <TableCell align="center" width={80}>{row.hole_no}</TableCell>
                                                <TableCell align="center" width={80}>{row.date}</TableCell>
                                                
                                                <TableCell align="center" width={80}>{row.groundwater_iflow_single}</TableCell>
                                                <TableCell align="center" width={80}>{row.groundwater_iflow_all}</TableCell>

                                                <TableCell align="center" width={80}>{row.chainage_from}</TableCell>
                                                <TableCell align="center" width={80}>{row.chainage_to}</TableCell>

                                                <TableCell align="center" width={80}>{row.length}</TableCell>
                                                <TableCell align="center" width={80}>{row.overlap}</TableCell>
                                                <TableCell align="center" width={80}>{row.stage}</TableCell>
                                                <TableCell align="center" width={80}>{row.hole_type}</TableCell>
                                                
                                                <TableCell align="center">
                                                    <IconButton className={classesStyle.button_icon} onClick=
                                                        {
                                                            () => { 
                                                                // [fixme, depends on table, DataElement], caution: SEQUENCE of 'openDialogEdit()'
                                                                openDialogEdit(
                                                                    row.sol,
                                                                    row.hole_no,
                                                                    row.date,

                                                                    row.groundwater_iflow_single,
                                                                    row.groundwater_iflow_all,

                                                                    row.chainage_from,
                                                                    row.chainage_to,

                                                                    row.length,
                                                                    row.overlap,
                                                                    row.stage,
                                                                    row.hole_type
                                                                ) 
                                                            }
                                                        } 
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                    
                                                    <IconButton className={classesStyle.button_icon} onClick=
                                                        {
                                                            () => {handleDelete(row.id)}
                                                        } 
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                    
                                                </TableCell>

                                                

                                            </StyledTableRow>
                                        ))}
                                    </>
                                ) : (
                                    <div> &nbsp;&nbsp;&nbsp;&nbsp; no record found </div>
                                )
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalpagenum}
                    rowsPerPage={rowsPerPage.current}
                    page={page.current}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Container>

            <DialogAdd
                triggerOpen={dialogAddOpen}
                handleClose={closeDialogAdd}

                setsol={setsol}
                sethole_no={sethole_no}
                setdate={setdate}

                setgroundwater_iflow_single={setgroundwater_iflow_single}
                setgroundwater_iflow_all={setgroundwater_iflow_all}

                setchainage_from={setchainage_from}
                setchainage_to={setchainage_to}

                setlength={setlength}
                setoverlap={setoverlap}
                setstage={setstage}
                sethole_type={sethole_type}

                // [fixme, + magic refresh table after edit / update]
                handleconfirmDialogAdd={handleconfirmDialogAdd}

                // control POPupB of POPupA
                openDialogOperationResult={openDialogOperationResult}
            />

            <DialogEdit
                
                // control POPupA Dialog: on / off
                triggerOpen={dialogEditOpen}
                handleClose={closeDialogEdit}                
                
                // [fixme, depends on table, DataElement]
                sol={sol}
                hole_no={hole_no}
                date={date}

                groundwater_iflow_single={groundwater_iflow_single}
                groundwater_iflow_all={groundwater_iflow_all}

                chainage_from={chainage_from}
                chainage_to={chainage_to}

                length={length}
                overlap={overlap}
                stage={stage}
                hole_type={hole_type}


                setsol={setsol}
                sethole_no={sethole_no}
                setdate={setdate}

                setgroundwater_iflow_single={setgroundwater_iflow_single}
                setgroundwater_iflow_all={setgroundwater_iflow_all}

                setchainage_from={setchainage_from}
                setchainage_to={setchainage_to}

                setlength={setlength}
                setoverlap={setoverlap}
                setstage={setstage}
                sethole_type={sethole_type}

                // [fixme, + magic refresh table after edit / update]
                handleconfirmDialogEdit={handleconfirmDialogEdit}

                // control POPupB of POPupA
                openDialogOperationResult={openDialogOperationResult}                
            />

            <DialogImportExcelFile
                triggerOpen={dialogimportExcelFileOpen}
                handleClose={closedialogimportExcelFile}

                setimportExcelFile={importExcelFileHandler}
                importExcelFile={importExcelFile}
                onloadprogressExcelFile={onloadprogressExcelFile}

                // [fixme, + magic refresh table after edit / update]
                handleconfirmImportExcel={handleconfirmImportExcel}
            />
            
            <DialogOperationResult
                triggerOpen={dialogOperationResultOpen}
                handleClose={closeDialogOperationResult}

                dialogOperationResultState={dialogOperationResultState}
            />

        </div>
    )
}



function DialogEdit(
    {     
        triggerOpen, handleClose, 

        // [fixme, depends on table, DataElement]
        sol,hole_no,date,groundwater_iflow_single,groundwater_iflow_all,chainage_from,chainage_to,length,overlap,stage,hole_type,
        setsol,sethole_no,setdate,setgroundwater_iflow_single,setgroundwater_iflow_all,setchainage_from,setchainage_to,setlength,setoverlap,setstage,sethole_type,

        // [fixme, magic refresh table after edit / update]
        handleconfirmDialogEdit,
        openDialogOperationResult 
    })
    {


    //// CSS related
    const cssStyle = makeStyles((theme: Theme) =>
        createStyles({            
            buttonRow: {
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "16px",
            },
            solidButton: {
                margin: "8px",
                fontWeight: 600,
                "&:hover": {
                    backgroundColor: "#8B0000",
                    color: "#fff",
                },
            },
            input: {
                borderBottom: `1px solid ${theme.palette.divider}`,
                paddingRight: theme.spacing(2),
                display: 'flex',
                alignItems: 'center',
            },
            icon: {
                fontSize: '1.2em',
                marginLeft: theme.spacing(1),
                cursor: 'pointer',
            },
        }))
    const classes = cssStyle();


    //// FORM related
    // state - FORM
    // const [buttonlock, setbuttonlock] = useState(true);
    const [buttonlock, setbuttonlock] = useState(false);    
    
    // [fixme, depends on table, DataElement]
    // [fixme, depends on table, DataElement]
    const handleinput = (event: any) => {
        const { name, value } = event.target;
        switch (name) {

            //// as record UNIQUE ID, so, cannot BE change-ED
            // case 'sol':
            //     setsol(value);
            //     break;
            // case 'hole_no':
            //     sethole_no(value);
            //     break;
            // case 'date':
            //     setdate(value);
            //     break;
            
            case 'groundwater_iflow_single':
                setgroundwater_iflow_single(parsefloat_nonan(value));
                break;            
            case 'groundwater_iflow_all':
                setmeasurement_noise_level_l10(parsefloat_nonan(value));
                break;
                
            case 'chainage_from':
                setchainage_from(parsefloat_nonan(value));
                break;
            case 'chainage_to':
                setchainage_to(parsefloat_nonan(value));
                break;
            
            case 'length':
                setlength(parsefloat_nonan(value));
                break;
            case 'overlap':
                setoverlap(parsefloat_nonan(value));
                break;
            case 'stage':
                setstage(parsefloat_nonan(value));
                break;
            case 'hole_type':
                sethole_type((value));
                break;
            
            default:
                break;
        }
    };
    

    //// startup: script to run
    useEffect(() => {
        // [fixme, depends on table, DataElement]
        setsol(sol)
        sethole_no(hole_no)
        setdate(date)

        setgroundwater_iflow_single(groundwater_iflow_single)
        setgroundwater_iflow_all(groundwater_iflow_all)

        setchainage_from(chainage_from)
        setchainage_to(chainage_to)

        setlength(length)
        setoverlap(overlap)
        setstage(stage)
        sethole_type(hole_type)
        // if ( xx ) {
        //     setbuttonlock(false);
        // } else {
        //     setbuttonlock(true);
        // }
    }, [        
        // [fixme, depends on table, DataElement] - put a value that will CHANGE, something CHANGE -> all state CHANGE
        sol, hole_no, date
    ]);


    return (
        <Dialog className='receiveBtn' open={triggerOpen} onClose={handleClose}>            
            <DialogContent >

                <br/>

                {/* // [fixme, depends on table, DataElement] */}
                <Form className='w-form-group-width'>                    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> sol </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='sol' value={sol} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> hole_no </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='hole_no' value={hole_no} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> date </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='date' value={date} onChange={handleinput}
                        />
                    </Form.Group>
                    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> groundwater_iflow_single </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='groundwater_iflow_single' value={groundwater_iflow_single} onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> groundwater_iflow_all </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='groundwater_iflow_all' value={groundwater_iflow_all} onChange={handleinput}
                        />                        
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> chainage_from </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='chainage_from' value={chainage_from} onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> chainage_to </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='chainage_to' value={chainage_to} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> length </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='length' value={length} onChange={handleinput}
                        />               
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> overlap </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='overlap' value={overlap} onChange={handleinput}
                        />               
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> stage </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='stage' value={stage} onChange={handleinput}
                        />               
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> hole_type </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='hole_type' value={hole_type} onChange={handleinput}
                        />
                    </Form.Group>

                    <br/>
                    
                    <Button
                        className='mr19i themeBgi'
                        variant="contained"
                        color="secondary"
                        onClick={handleconfirmDialogEdit}
                        disabled={buttonlock}
                    >
                        Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        // className='mr20i'
                        variant="contained"
                        // color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                </Form>

                <br/>
                
            </DialogContent>
        </Dialog>
    );
}


function DialogImportExcelFile(
    { 
        triggerOpen, handleClose, 
        setimportExcelFile, importExcelFile, onloadprogressExcelFile,
        handleconfirmImportExcel
    }
    ) {
    return (
        <Dialog open={triggerOpen} onClose={handleClose}>
            <DialogContent>
                <Form>

                    <br/>

                    {(importExcelFile && onloadprogressExcelFile !== 0) ? (
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                aria-valuenow={onloadprogressExcelFile}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: onloadprogressExcelFile + "%" }}
                            >
                                {onloadprogressExcelFile}%
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <p/>
                            </div>
                        </div>
                    )}

                    <br/>

                    <Card>
                        <br/>
                        <label className="btn btn-default">
                            <input type="file" onChange={setimportExcelFile} />
                        </label>
                    </Card>

                    <br/>
                    <br/>

                    &nbsp;&nbsp;
                    <Button
                            className='mr19i themeBgi'
                            variant="contained"
                            color="secondary"
                            onClick={handleconfirmImportExcel}
                            // disabled={buttonlock}
                        >
                            Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        // className='mr20i'
                        variant="contained"
                        // color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Form>
            </DialogContent>
            <br/>
        </Dialog>
    );
}


function DialogOperationResult({ triggerOpen, handleClose, dialogOperationResultState }) {
    return (
        <Dialog open={triggerOpen} onClose={handleClose}>
            <DialogContent>
                {
                    dialogOperationResultState ? 
                    (
                        <div>
                            <div className='w-dialog-operation-result'>
                                &radic;
                            </div>
                            <p className='red'>Operation Success!</p>
                        </div>
                    ) : (
                        <div>
                            <div className='w-dialog-operation-result'>
                                &Chi;
                            </div>
                            <p className='red'>Operation Failure!</p>
                        </div>
                    )
                }
            </DialogContent>
        </Dialog>
    );
}


//@ts-ignore
function DialogAdd(
    { 
        triggerOpen, handleClose, 

        // [fixme, depends on table, DataElement]
        // sol,hole_no,date,groundwater_iflow_single,groundwater_iflow_all,chainage_from,chainage_to,length,overlap,stage,hole_type,
        setsol,sethole_no,setdate,setgroundwater_iflow_single,setgroundwater_iflow_all,setchainage_from,setchainage_to,setlength,setoverlap,setstage,sethole_type,

        // [fixme, magic refresh table after edit / update]
        handleconfirmDialogAdd,
        openDialogOperationResult
    }) 
    {    

    const cssStyle = makeStyles((theme: Theme) =>
        createStyles({
            circleGroup: {
                display: "flex",
                alignItems: "center",
            },
            circleButton: {
                color: "#bdbdbd",
                padding: "0 4px",
                "&:hover": {
                    backgroundColor: "transparent",
                    color: "#8B0000",
                },
            },
            circleSelected: {
                color: "#8B0000",
            },
            buttonRow: {
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "16px",
            },
            solidButton: {
                margin: "8px",
                fontWeight: 600,
                "&:hover": {
                    backgroundColor: "#8B0000",
                    color: "#fff",
                },
            },
        }))

    const classes = cssStyle();

    // initialization: (fixme: not yet) read origin values        
    //  [fixme]
    // useEffect(() => {
    //     console.log('[DialogAdd] initStatus:', initStatus);
    //     setSelectedCircle(initStatus)
    //     console.log('[DialogAdd] selectedCircle:',selectedCircle);
    // }, [initStatus]);

    const handleinput = (event: any) => {
        const { name, value } = event.target;
        switch (name) {
            //// as record UNIQUE ID, so, cannot BE change-ED
            case 'sol':
                setsol(value);
                break;
            case 'hole_no':
                sethole_no(value);
                break;
            case 'date':
                setdate(value);
                break;
            
            case 'groundwater_iflow_single':
                setgroundwater_iflow_single(parsefloat_nonan(value));
                break;            
            case 'groundwater_iflow_all':
                setmeasurement_noise_level_l10(parsefloat_nonan(value));
                break;
                
            case 'chainage_from':
                setchainage_from(parsefloat_nonan(value));
                break;
            case 'chainage_to':
                setchainage_to(parsefloat_nonan(value));
                break;
            
            case 'length':
                setlength(parsefloat_nonan(value));
                break;
            case 'overlap':
                setoverlap(parsefloat_nonan(value));
                break;
            case 'stage':
                setstage(parsefloat_nonan(value));
                break;
            case 'hole_type':
                sethole_type((value));
                break;
            
            default:
                break;
        }
    };

    //// startup: script to run
    useEffect(() => {
        // [fixme, depends on table, DataElement]
        setsol('')
        sethole_no('')
        setdate('')

        setgroundwater_iflow_single(0)
        setgroundwater_iflow_all(0)

        setchainage_from(0)
        setchainage_to(0)

        setlength(0)
        setoverlap(0)
        setstage(0)
        sethole_type('')
        // if ( xx ) {
        //     setbuttonlock(false);
        // } else {
        //     setbuttonlock(true);
        // }
    }, [        
        // [fixme, depends on table, DataElement] - put a value that will CHANGE, something CHANGE -> all state CHANGE
        // blast_no, date, sensitive_receiver_and_reference_no
    ]);
    
    return (
        <Dialog open={triggerOpen} onClose={handleClose}>
            <DialogContent>

                <br/>
                
                {/* // [fixme, depends on table, DataElement] */}
                <Form className='w-form-group-width'>                    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> sol </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='sol'  onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> hole_no </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='hole_no'  onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> date </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='date'  onChange={handleinput}
                        />                        
                    </Form.Group>
                    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> groundwater_iflow_single </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='groundwater_iflow_single'  onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> groundwater_iflow_all </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='groundwater_iflow_all'  onChange={handleinput}
                        />                        
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> chainage_from </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='chainage_from'  onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> chainage_to </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='chainage_to'  onChange={handleinput}
                        />                        
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> length </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='length'  onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> overlap </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='overlap'  onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> stage </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='stage'  onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> hole_type </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='hole_type'  onChange={handleinput}
                        />                        
                    </Form.Group>

                    <br/>
                    
                    <Button
                        className='mr19i themeBgi'
                        variant="contained"
                        color="secondary"
                        onClick={handleconfirmDialogAdd}
                        // disabled={buttonlock}
                    >
                        Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        // className='mr20i'
                        variant="contained"
                        // color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                </Form>

                <br/>
                
            </DialogContent>
        </Dialog>
    );
}