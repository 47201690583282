// react 17.0.2

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "./css/style.css";

import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  // <React.StrictMode>
  <SnackbarProvider anchorOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}>
    <App/>
  {/* </React.StrictMode>, */}
  </SnackbarProvider>,
  document.getElementById('root')
);