//@ts-nocheck
import React, { useEffect, useState, useRef } from 'react'
import { withStyles, makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles"
import { Card, Container, Form, Image } from "react-bootstrap"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, FormControl, InputAdornment, Input, InputLabel, OutlinedInput, Select, Box, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import { apifetch_get, apifetch_add, apifetch_update, apifetch_search, apifetch_delete, apifetch_importfromexcel, apifetch_exportexcel } from '../../services/apifetch/ApiMapping2'
import { Refresh, Add, Menu, Search, Edit, Clear, CloudUpload, CloudDownload } from "@material-ui/icons";

import Plot from 'react-plotly.js';
import ImageLoading from "../../img/loading_curve1.gif"
import SwipeableViews from 'react-swipeable-views';

type DataElement = {
    id: number| undefined;

    sol: string | undefined;
    chainage: string| undefined;
    elevated_extent: string| undefined;
    level_extent: string| undefined;
    blast_no: string| undefined;
    date: string | undefined;

    rqd: number| undefined;
    jn: number| undefined;
    jr: number| undefined;
    ja: number| undefined;
    jw: number| undefined;
    srf: number| undefined;
    q_value: number| undefined;
    q_pi: number| undefined;
    gsi: number| undefined;
    
    remarks: string | undefined;
    pw: string | undefined;

    dip_direction_1: number| undefined;
    dip_1: number| undefined;
    dip_direction_2: number| undefined;
    dip_2: number| undefined;
    dip_direction_3: number| undefined;
    dip_3: number| undefined;
    dip_direction_4: number| undefined;
    dip_4: number| undefined;
    dip_direction_5: number| undefined;
    dip_5: number| undefined;
    dip_direction_6: number| undefined;
    dip_6: number| undefined;
    dip_direction_7: number| undefined;
    dip_7: number| undefined;
    dip_direction_8: number| undefined;
    dip_8: number| undefined;

    dip_direction_r1: number| undefined;
    dip_r1: number| undefined;
    dip_direction_r2: number| undefined;
    dip_r2: number| undefined;
    dip_direction_r3: number| undefined;
    dip_r3: number| undefined;
    dip_direction_r4: number| undefined;
    dip_r4: number| undefined;
    dip_direction_r5: number| undefined;
    dip_r5: number| undefined;
    dip_direction_r6: number| undefined;
    dip_r6: number| undefined;
    dip_direction_r7: number| undefined;
    dip_r7: number| undefined;
    dip_direction_r8: number| undefined;
    dip_r8: number| undefined;
    
    condition: string | undefined;
    measurement: string | undefined;
    location: string | undefined;
    crown: string | undefined;
    wall: string | undefined;

    created_at: string | undefined;
    updated_at: string | undefined;
};


//// css
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#808080',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);
const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'green',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'green',
        },
      },
    },
})(TextField);


//// utility
function parsefloat_nonan(input_string){
    if (input_string !== ''){
        return parseFloat(input_string)
    } else {
        return 0
    }        
}


export function Mapping2() {

    //// data element, initialization
    // [fixme, depends on table, DataElement]
    const [sol, setsol] = useState('');
    const [chainage, setchainage] = useState('');
    const [elevated_extent, setelevated_extent] = useState('');
    const [level_extent, setlevel_extent] = useState('');
    const [blast_no, setblast_no] = useState('');
    const [date, setdate] = useState('');

    const [rqd, setrqd] = useState(0);
    const [jn, setjn] = useState(0);
    const [jr, setjr] = useState(0);
    const [ja, setja] = useState(0);
    const [jw, setjw] = useState(0);
    const [srf, setsrf] = useState(0);
    const [q_value, setq_value] = useState(0);
    const [q_pi, setq_pi] = useState(0);
    const [gsi, setgsi] = useState(0);
    
    const [remarks, setremarks] = useState('');
    const [pw, setpw] = useState('');

    const [dip_direction_1, setdip_direction_1] = useState(0);
    const [dip_1, setdip_1] = useState(0);
    const [dip_direction_2, setdip_direction_2] = useState(0);
    const [dip_2, setdip_2] = useState(0);
    const [dip_direction_3, setdip_direction_3] = useState(0);
    const [dip_3, setdip_3] = useState(0);
    const [dip_direction_4, setdip_direction_4] = useState(0);
    const [dip_4, setdip_4] = useState(0);
    const [dip_direction_5, setdip_direction_5] = useState(0);
    const [dip_5, setdip_5] = useState(0);
    const [dip_direction_6, setdip_direction_6] = useState(0);
    const [dip_6, setdip_6] = useState(0);
    const [dip_direction_7, setdip_direction_7] = useState(0);
    const [dip_7, setdip_7] = useState(0);
    const [dip_direction_8, setdip_direction_8] = useState(0);
    const [dip_8, setdip_8] = useState(0);
    
    const [dip_direction_r1, setdip_direction_r1] = useState(0);
    const [dip_r1, setdip_r1] = useState(0);
    const [dip_direction_r2, setdip_direction_r2] = useState(0);
    const [dip_r2, setdip_r2] = useState(0);
    const [dip_direction_r3, setdip_direction_r3] = useState(0);
    const [dip_r3, setdip_r3] = useState(0);
    const [dip_direction_r4, setdip_direction_r4] = useState(0);
    const [dip_r4, setdip_r4] = useState(0);
    const [dip_direction_r5, setdip_direction_r5] = useState(0);
    const [dip_r5, setdip_r5] = useState(0);
    const [dip_direction_r6, setdip_direction_r6] = useState(0);
    const [dip_r6, setdip_r6] = useState(0);
    const [dip_direction_r7, setdip_direction_r7] = useState(0);
    const [dip_r7, setdip_r7] = useState(0);
    const [dip_direction_r8, setdip_direction_r8] = useState(0);
    const [dip_r8, setdip_r8] = useState(0);
    
    const [condition, setcondition] = useState('');
    const [measurement, setmeasurement] = useState('');
    const [location, setlocation] = useState('');
    const [crown, setcrown] = useState('');
    const [wall, setwall] = useState('');


    //// data container
    const [templatedata, settemplatedata] = useState<DataElement[]>([])
    const [totalpagenum, settotalpagenum] = useState(5)


    //// pagination (rows per page)
    const page = useRef(0);
    const rowsPerPage = useRef(5);
    // const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event: any, newPage: any) => {
        page.current = newPage
        console.log("[handleChangePage] page.current:", page.current)
        console.log("[handleChangePage] newPage:", newPage)
        // initListData();
        reloadtabledata()
    };
    const handleChangeRowsPerPage = (event: any) => {
        rowsPerPage.current = parseInt(event.target.value);
        initListData();
    };

    
    ////// backend api --- start
    //// api, getALL
    const initListData = async () => {
        const responsedata = await apifetch_get(
            {
                "page_number": page.current+1,
                "item_per_page": rowsPerPage.current,
                "sequence": "descending"
            }
        );
        settemplatedata(responsedata.mapping_data)
        settotalpagenum(responsedata.count)
        console.log("[initListData] responsedata", responsedata);
        console.log("[initListData] responsedata.mapping_data", responsedata.mapping_data);
    }
    //// api, search
    const [searchText, setsearchText] = useState('')
    const searchHandler = (e: any) => {
        setsearchText(e.target.value)
    }
    const searchtemplatedata = async () => {
        const responsedata = await apifetch_search(
            {
                "page_number": page.current+1, 
                "item_per_page": rowsPerPage.current,
                "sequence": "descending",
                "search_param": searchText
            }
        );
        settemplatedata(responsedata.mapping_data)
        settotalpagenum(responsedata.count)
        console.log("[searchtemplatedata] responsedata:", responsedata);
    }
    ////// backend api --- end


    //// startup, data reading - api, getALL
    useEffect(() => {
        async function init() {
            // getALL list
            initListData()
        }

        init();
    }, []);


    //// non-startup, data reading
    //    - if there exist 'searchText', then response the needed data
    //    - if there exist NO 'searchText', then response 'getALL'
    const reloadtabledata = () => {
        if (searchText) {
            searchtemplatedata()
        } else {
            initListData();
        }
    }


    //// for POPup, DialogEdit, input    
    const [dialogEditOpen, setdialogEditOpen] = useState(false);    
    const openDialogEdit = (
        // [fixme, depends on table, DataElement]
        sol: any,
        chainage: any,
        elevated_extent: any,
        level_extent: any,
        blast_no: any,
        date: any,

        rqd: any,
        jn: any,
        jr: any,
        ja: any,
        jw: any,
        srf: any,
        q_value: any,
        q_pi: any,
        gsi: any,
        remarks: any,
        pw: any,

        dip_direction_1: any,
        dip_1: any,
        dip_direction_2: any,
        dip_2: any,
        dip_direction_3: any,
        dip_3: any,
        dip_direction_4: any,
        dip_4: any,
        dip_direction_5: any,
        dip_5: any,
        dip_direction_6: any,
        dip_6: any,
        dip_direction_7: any,
        dip_7: any,
        dip_direction_8: any,
        dip_8: any,

        dip_direction_r1: any,
        dip_r1: any,
        dip_direction_r2: any,
        dip_r2: any,
        dip_direction_r3: any,
        dip_r3: any,
        dip_direction_r4: any,
        dip_r4: any,
        dip_direction_r5: any,
        dip_r5: any,
        dip_direction_r6: any,
        dip_r6: any,
        dip_direction_r7: any,
        dip_r7: any,
        dip_direction_r8: any,
        dip_r8: any,

        condition: any,
        measurement: any,
        location: any,
        crown: any,
        wall: any,
    ) => {
        // [fixme, depends on table, DataElement]
        setsol(sol)
        setchainage(chainage)
        setelevated_extent(elevated_extent)
        setlevel_extent(level_extent)
        setblast_no(blast_no)
        setdate(date)

        setrqd(rqd)
        setjn(jn)
        setjr(jr)
        setja(ja)
        setjw(jw)
        setsrf(srf)
        setq_value(q_value)
        setq_pi(q_pi)
        setgsi(gsi)

        setremarks(remarks)
        setpw(pw)
        
        setdip_direction_1(dip_direction_1)
        setdip_1(dip_1)        
        setdip_direction_2(dip_direction_2)
        setdip_2(dip_2)
        setdip_direction_3(dip_direction_3)
        setdip_3(dip_3)
        setdip_direction_4(dip_direction_4)
        setdip_4(dip_4)
        setdip_direction_5(dip_direction_5)
        setdip_5(dip_5)
        setdip_direction_6(dip_direction_6)
        setdip_6(dip_6)
        setdip_direction_7(dip_direction_7)
        setdip_7(dip_7)
        setdip_direction_8(dip_direction_8)
        setdip_8(dip_8)
        
        setdip_direction_r1(dip_direction_r1)
        setdip_r1(dip_r1)        
        setdip_direction_r2(dip_direction_r2)
        setdip_r2(dip_r2)
        setdip_direction_r3(dip_direction_r3)
        setdip_r3(dip_r3)
        setdip_direction_r4(dip_direction_r4)
        setdip_r4(dip_r4)
        setdip_direction_r5(dip_direction_r5)
        setdip_r5(dip_r5)
        setdip_direction_r6(dip_direction_r6)
        setdip_r6(dip_r6)
        setdip_direction_r7(dip_direction_r7)
        setdip_r7(dip_r7)
        setdip_direction_r8(dip_direction_r8)
        setdip_r8(dip_r8)
        
        setcondition(condition)
        setmeasurement(measurement)
        setlocation(location)
        setcrown(crown)
        setwall(wall)
        

        setdialogEditOpen(true);
    };
    const closeDialogEdit = () => {
        setdialogEditOpen(false);
    };
    //// button handler - 'confirm'
    const handleconfirmDialogEdit = async () => {

        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_update(
            {
                "sol": sol,
                "chainage": chainage,
                "elevated_extent": elevated_extent,
                "level_extent": level_extent,
                "blast_no": blast_no,
                "date": date,

                "rqd": rqd,
                "jn": jn,
                "jr": jr,
                "ja": ja,
                "jw": jw,
                "srf": srf,
                "q_value": q_value,
                "q_pi": q_pi,
                "gsi": gsi,

                "remarks": remarks,
                "pw": pw,

                "dip_direction_1": dip_direction_1,
                "dip_1": dip_1,
                "dip_direction_2": dip_direction_2,
                "dip_2": dip_2,
                "dip_direction_3": dip_direction_3,
                "dip_3": dip_3,
                "dip_direction_4": dip_direction_4,
                "dip_4": dip_4,
                "dip_direction_5": dip_direction_5,
                "dip_5": dip_5,
                "dip_direction_6": dip_direction_6,
                "dip_6": dip_6,
                "dip_direction_7": dip_direction_7,
                "dip_7": dip_7,
                "dip_direction_8": dip_direction_8,
                "dip_8": dip_8,
                
                "dip_direction_r1": dip_direction_r1,
                "dip_r1": dip_r1,
                "dip_direction_r2": dip_direction_r2,
                "dip_r2": dip_r2,
                "dip_direction_r3": dip_direction_r3,
                "dip_r3": dip_r3,
                "dip_direction_r4": dip_direction_r4,
                "dip_r4": dip_r4,
                "dip_direction_r5": dip_direction_r5,
                "dip_r5": dip_r5,
                "dip_direction_r6": dip_direction_r6,
                "dip_r6": dip_r6,
                "dip_direction_r7": dip_direction_r7,
                "dip_r7": dip_r7,
                "dip_direction_r8": dip_direction_r8,
                "dip_r8": dip_r8,
                
                "condition": condition,
                "measurement": measurement,
                "location": location,
                "crown": crown,
                "wall": wall                
            }
        )
        console.log(" [handleconfirmDialogEdit] responsedata", responsedata);

        // [fixme, depends on table, DataElement]
        setsol('')
        setchainage('')
        setelevated_extent('')
        setlevel_extent('')
        setblast_no('')
        setdate('')        

        setrqd(0)
        setjn(0)
        setjr(0)
        setja(0)
        setjw(0)
        setsrf(0)
        setq_value(0)
        setq_pi(0)
        setgsi(0)

        setremarks('')
        setpw('')

        setdip_direction_1(0)
        setdip_1(0)
        setdip_direction_2(0)
        setdip_2(0)
        setdip_direction_3(0)
        setdip_3(0)
        setdip_direction_4(0)
        setdip_4(0)
        setdip_direction_5(0)
        setdip_5(0)
        setdip_direction_6(0)
        setdip_6(0)
        setdip_direction_7(0)
        setdip_7(0)
        setdip_direction_8(0)
        setdip_8(0)
        
        setdip_direction_r1(0)
        setdip_r1(0)
        setdip_direction_r2(0)
        setdip_r2(0)
        setdip_direction_r3(0)
        setdip_r3(0)
        setdip_direction_r4(0)
        setdip_r4(0)
        setdip_direction_r5(0)
        setdip_r5(0)
        setdip_direction_r6(0)
        setdip_r6(0)
        setdip_direction_r7(0)
        setdip_r7(0)
        setdip_direction_r8(0)
        setdip_r8(0)

        setcondition('')
        setmeasurement('')
        setlocation('')
        setcrown('')
        setwall('')
        
        
        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }
    }


    //// button handler - 'delete', no POPup
    const handleDelete = async (inputId) => {

        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_delete(
            {
                "ids": [inputId]
            }
        )
        console.log(" [handleDelete] responsedata", responsedata);
        
        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }
    }


    //// button handler - 'export excel', no POPup
    const handleExportExcel = async () => {

        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_exportexcel("mapping.xlsx")
        console.log(" [handleExportExcel] responsedata", responsedata);
    }


    //// for POPup, DialogimportExcelFile, 'import Excel'
    const [dialogimportExcelFileOpen, setdialogimportExcelFileOpen] = useState(false);
    const [importExcelFile, setimportExcelFile] = useState('');
    const [onloadprogressExcelFile, setonloadprogressExcelFile] = useState(0);
    const importExcelFileHandler = (e: any) => {
        console.log("[importExcelFileHandler] e.target:", e.target)        
        console.log("[importExcelFileHandler] e.target.files[0]:", e.target.files[0])
        setimportExcelFile(e.target.files[0])
    }
    const opendialogimportExcelFile = (type) => {
        
        // // [fixme]
        // if (type == 1) {
        //     setdialogOperationResultState(true)
        // } else {
        //     setdialogOperationResultState(false)
        // }

        // closeDialogEdit()
        setonloadprogressExcelFile(0)
        setdialogimportExcelFileOpen(true);
    };
    const closedialogimportExcelFile = () => {
        setdialogimportExcelFileOpen(false);
    };
    const handleconfirmImportExcel = async () => {
        // [fixme, depends on table, DataElement]
        const responsedata = await apifetch_importfromexcel(importExcelFile, 
            (event) => {
                setonloadprogressExcelFile(
                    Math.round((100 * event.loaded) / event.total)
                )
                console.log("[handleconfirmImportExcel] Math.round((100 * event.loaded) / event.total):", Math.round((100 * event.loaded) / event.total))
                console.log("[handleconfirmImportExcel] onloadprogressExcelFile:", onloadprogressExcelFile)
            }
        )
        console.log(" [handleconfirmImportExcel] responsedata", responsedata)

        // fixme: operationStatus, loading status bar
        setonloadprogressExcelFile(0)

        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }

        closedialogimportExcelFile()
    }    


    //// for POPup, DialogOperationResult, input -> result (success / failure)
    const [dialogOperationResultOpen, setdialogOperationResultOpen] = useState(false);
    const [dialogOperationResultState, setdialogOperationResultState] = useState(true);
    const openDialogOperationResult = (type) => {
        
        // [fixme]
        if (type === 1) {
            setdialogOperationResultState(true)
        } else {
            setdialogOperationResultState(false)
        }

        closeDialogEdit()
        setdialogOperationResultOpen(true);
    };
    const closeDialogOperationResult = () => {
        setdialogOperationResultOpen(false);
    };


    //// for POPup, DialogAdd
    const [dialogAddOpen, setdialogAddOpen] = useState(false);
    const openDialogAdd = (type: any) => {   
        setdialogAddOpen(true);
    };
    const closeDialogAdd = () => {
        setdialogAddOpen(false);
    };
    const handleconfirmDialogAdd = async () => {

        // [fixme, depends on table, DataElement]        
        const responsedata = await apifetch_add(
            {
                "sol": sol,
                "chainage": chainage,
                "elevated_extent": elevated_extent,
                "level_extent": level_extent,
                "blast_no": blast_no,
                "date": date,

                "rqd": rqd,
                "jn": jn,
                "jr": jr,
                "ja": ja,
                "jw": jw,
                "srf": srf,
                "q_value": q_value,
                "q_pi": q_pi,
                "gsi": gsi,

                "remarks": remarks,
                "pw": pw,

                "dip_direction_1": dip_direction_1,
                "dip_1": dip_1,
                "dip_direction_2": dip_direction_2,
                "dip_2": dip_2,
                "dip_direction_3": dip_direction_3,
                "dip_3": dip_3,
                "dip_direction_4": dip_direction_4,
                "dip_4": dip_4,
                "dip_direction_5": dip_direction_5,
                "dip_5": dip_5,
                "dip_direction_6": dip_direction_6,
                "dip_6": dip_6,
                "dip_direction_7": dip_direction_7,
                "dip_7": dip_7,
                "dip_direction_8": dip_direction_8,
                "dip_8": dip_8,
                
                "dip_direction_r1": dip_direction_r1,
                "dip_r1": dip_r1,
                "dip_direction_r2": dip_direction_r2,
                "dip_r2": dip_r2,
                "dip_direction_r3": dip_direction_r3,
                "dip_r3": dip_r3,
                "dip_direction_r4": dip_direction_r4,
                "dip_r4": dip_r4,
                "dip_direction_r5": dip_direction_r5,
                "dip_r5": dip_r5,
                "dip_direction_r6": dip_direction_r6,
                "dip_r6": dip_r6,
                "dip_direction_r7": dip_direction_r7,
                "dip_r7": dip_r7,
                "dip_direction_r8": dip_direction_r8,
                "dip_r8": dip_r8,
                
                "condition": condition,
                "measurement": measurement,
                "location": location,
                "crown": crown,
                "wall": wall
            }
        )
        console.log(" [handleconfirmDialogAdd] responsedata:", responsedata)

        // [fixme, depends on table, DataElement]
        setsol('')
        setchainage('')
        setelevated_extent('')
        setlevel_extent('')
        setblast_no('')
        setdate('')        

        setrqd(0)
        setjn(0)
        setjr(0)
        setja(0)
        setjw(0)
        setsrf(0)
        setq_value(0)
        setq_pi(0)
        setgsi(0)

        setremarks('')
        setpw('')

        setdip_direction_1(0)
        setdip_1(0)
        setdip_direction_2(0)
        setdip_2(0)
        setdip_direction_3(0)
        setdip_3(0)
        setdip_direction_4(0)
        setdip_4(0)
        setdip_direction_5(0)
        setdip_5(0)
        setdip_direction_6(0)
        setdip_6(0)
        setdip_direction_7(0)
        setdip_7(0)
        setdip_direction_8(0)
        setdip_8(0)
        
        setdip_direction_r1(0)
        setdip_r1(0)
        setdip_direction_r2(0)
        setdip_r2(0)
        setdip_direction_r3(0)
        setdip_r3(0)
        setdip_direction_r4(0)
        setdip_r4(0)
        setdip_direction_r5(0)
        setdip_r5(0)
        setdip_direction_r6(0)
        setdip_r6(0)
        setdip_direction_r7(0)
        setdip_r7(0)
        setdip_direction_r8(0)
        setdip_r8(0)
        
        setcondition('')
        setmeasurement('')
        setlocation('')
        setcrown('')
        setwall('')
        
        // [fixme, magic refresh table after edit / update]
        reloadtabledata()

        if (responsedata.code && responsedata.code===1) {
            openDialogOperationResult(1)            
        } else {
            openDialogOperationResult(0)
        }

        closeDialogAdd()
    }
    



    //// CSS related
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            themeStyleRoot: {
                padding: theme.spacing(2),
                flexGrow: 1,
            },
            text_center: {
                textAlign: 'center'
            },
            button_icon: {
                color: "#fff",
                backgroundColor: "#2aa10f",
                height: "30px",
                width: "30px",
            },
            textfield_input: {
                marginLeft: theme.spacing(1),
                flex: 1,
            },
            iconButton_menu: {
                padding: 10,
            },
            iconButton_search: {
                padding: 10,
            },
            margin: {
                margin: theme.spacing(0),
                height: "20px",
            },
            themeTabRoot: {
                backgroundColor: theme.palette.background.paper,
                width: "100%",
            },            
        })
    );
    const classesStyle = useStyles();



    //////////////////////////////////////////////////////////////////////////////
    ////// [Graph / Plot] logic --- start
    //      - category is 'date'
    //        - block is 'legend' on right
    //        - 'date' data in a group -> present in 'candle box' format
    //        - skip '0'?
    const [loading, setLoading] = useState<boolean>(true);
    
    // for Plotly!!
    const [datacandlebox1, setdatacandlebox1]=useState([]);
    // const [datacandlebox2, setdatacandlebox2]=useState([]);
    const rowsPerPageGraph = useRef(50);
    

    ////// backend api --- start
    //// api, getALL
    const initPlotData = async () => {

        setLoading(true);
        const responsedata = await apifetch_get(
            {
                // "page_number": page.current+1,
                "page_number": 1,
                "item_per_page": rowsPerPageGraph.current,
                // "item_per_page": 200,
                "sequence": "descending"
            }
        );
        console.log("[initPlotData] responsedata", responsedata);
        console.log("[initPlotData] responsedata.mapping_data", responsedata.mapping_data);

        transform1_initPlotData(responsedata.mapping_data)
    }
    //// api, search
    // const [searchTextGraph, setsearchTextGraph] = useState('')
    // const searchHandlerGraph = (e: any) => {
    //     setsearchTextGraph(e.target.value)
    // }
    // const searchtemplatedataGraph = async () => {
    //     const responsedata = await apifetch_search(
    //         {
    //             // "page_number": page.current+1,
    //             "page_number": 1,
    //             "item_per_page": rowsPerPageGraph.current,
    //             "sequence": "descending",
    //             "search_param": searchText
    //         }
    //     );        
    //     console.log("[searchtemplatedataGraph] responsedata:", responsedata);
    //     transform1_initPlotData(responsedata.mapping_data)
    // }
    ////// backend api --- end


    //// transform 1 --- category
    //     - x : category of DATE (no time)
    //     - y1: @DATE CATEGORY -> ARRAY of DATA
    //          - non zero guard
    //          - e.g. 2023-06-02T00:00:00Z, 2023-06-02T01:00:00Z, SAME @DATE CATEGORY
    //          - x to y1, x to y2, x to y3 ...
    const transform1_initPlotData = (response_initPlotData) => {

        let timestamp_splits_date_pre: string;
        let category_counter: number;

        let x: any = [];
        let y: any = [];
        let y_array_element: any = [];
        let y2: any = [];
        let y2_array_element: any = [];
        let y3: any = [];
        let y3_array_element: any = [];

        const records = response_initPlotData        

        const data_y_length = records.length;
        // (only for my console visualization, not related to any STATE variable)
        category_counter = -1;
        records.forEach( (datum: any, i: number) => {

        //// CASE 5 --- category DIFFERENT, last one --- save / commit (y_array_element) to (y, finish a category)
        //              - do it before guard ZERO!!!!!        
            if (i == data_y_length - 1) {
                console.log(" *** [last one] category new, category_counter:", category_counter);

                y.push(y_array_element);
                y2.push(y2_array_element);
                y3.push(y3_array_element);
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y_array_element:",
                    y_array_element
                );
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y2_array_element:",
                    y2_array_element
                );
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y3_array_element:",
                    y3_array_element
                );
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y:", y);
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y2:", y2);
                console.log(
                    i,
                    "[last one] category new", category_counter, ", y3:", y3);
            }
            
            //// guard / filter / skip, non ZERO value (use 'return' like 'continue', in for loop)
            // if (datum["chainage"] === 0 || datum["humidity"] === 0){
            //     return;
            // }

            const timestamp_splits_date_current = datum["date"].split(" ");
            console.log(
                "[useEffect2] idea... timestamp_splits_date_current[0]:",
                timestamp_splits_date_current[0]
            );
            
            if (timestamp_splits_date_current[0] !== timestamp_splits_date_pre) {

                category_counter = category_counter +1;

                timestamp_splits_date_pre = timestamp_splits_date_current[0];
                console.log(
                    "category new, timestamp_splits_date_current[0]:",
                    timestamp_splits_date_current[0]
                );
                console.log(
                    "category new, timestamp_splits_date_pre:",
                    timestamp_splits_date_pre
                );

                x.push(timestamp_splits_date_current[0]);

        //// CASE 1 --- category DIFFERENT, first one --- accumulate (y_array_element) to (y_array_element)
                if (category_counter == 0) {

                    console.log(
                        i, 
                        "category new, category_counter:", category_counter);

                    y_array_element.push(datum["chainage"]);
                    y2_array_element.push(datum["humidity"]);
                    y3_array_element.push(datum["rainfall"]);
                    console.log(
                        i,
                        "[1st] category new", category_counter, ", y_array_element:",
                        y_array_element
                    );
                    console.log(
                        i,
                        "[1st] category new", category_counter, ", y2_array_element:",
                        y2_array_element
                    );
                    console.log(
                        i,
                        "[1st] category new", category_counter, ", y3_array_element:",
                        y3_array_element
                    );

                    return
                }                 
                
        //// CASE 2 --- category DIFFERENT, NOT first one 
        //    --2a- save / commit (y_array_element) to (y, finish a category)
        //    --2b- accumulate (y_array_element) to ( NEW y_array_element)

                //    --2a- save / commit (y_array_element) to (y, finish a category)
                y.push(y_array_element);
                y2.push(y2_array_element);
                y3.push(y3_array_element);
                console.log(
                    i,
                    "category new", category_counter, ", y_array_element:",
                    y_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y2_array_element:",
                    y2_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y3_array_element:",
                    y3_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y:", y);
                console.log(
                    i,
                    "category new", category_counter, ", y2:", y2);
                console.log(
                    i,
                    "category new", category_counter, ", y3:", y3);
                

                //    --2b- accumulate (y_array_element) to ( NEW y_array_element)
                y_array_element = [];
                y2_array_element = [];
                y3_array_element = [];
                y_array_element.push(datum["chainage"]);
                y2_array_element.push(datum["humidity"]);
                y3_array_element.push(datum["rainfall"]);
                console.log(
                    i,
                    "category new", category_counter,", y_array_element:",
                    y_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y2_array_element:",
                    y2_array_element
                );
                console.log(
                    i,
                    "category new", category_counter, ", y3_array_element:",
                    y3_array_element
                );

            } else {

        //// CASE 3 --- category SAME, NOT last one --- accumulate (y_array_element) to (y_array_element)
                console.log(
                    "category old, timestamp_splits_date_current:",
                    timestamp_splits_date_current[0]
                );
                console.log(
                    "category old, timestamp_splits_date_pre:",
                    timestamp_splits_date_pre
                );
                y_array_element.push(datum["chainage"]);
                y2_array_element.push(datum["humidity"]);
                y3_array_element.push(datum["rainfall"]);
                console.log(
                    i,
                    "category old", category_counter, ", datum[chainage]:",
                    datum["chainage"]
                );
                console.log(
                    i,
                    "category old", category_counter, ", y_array_element:", y_array_element);
                console.log(
                    i,
                    "category old", category_counter, ", y2_array_element:", y2_array_element);
                console.log(
                    i,
                    "category old", category_counter, ", y3_array_element:", y3_array_element);

        //// CASE 4 --- category SAME, last one --- save / commit (y_array_element) to (y, finish a category)
                if (i == data_y_length - 1) {
                    console.log(
                        i,
                        "[last one] category old, category_counter:", category_counter);
                    y.push(y_array_element);
                    y2.push(y2_array_element);
                    y3.push(y3_array_element);
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y_array_element:",
                        y_array_element
                    );
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y2_array_element:",
                        y2_array_element
                    );
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y3_array_element:",
                        y3_array_element
                    );
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y:", y);
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y2:", y2);
                    console.log(
                        i,
                        "[last one] category old", category_counter, ", y3:", y3);
        
                    category_counter = 0;
                    y_array_element = [];
                    y2_array_element = [];
                    y3_array_element = [];

                    console.log("... [cleaning] after [last one] category old ...")
                    console.log(
                        "[cleaning, check] y_array_element:",
                        y_array_element
                    );
                    console.log(
                        "[cleaning, check] y2_array_element:",
                        y2_array_element
                    );
                    console.log(
                        "[cleaning, check] y3_array_element:",
                        y3_array_element
                    );
                }
            }
        })
        console.log(" [transform1_initPlotData, end] x:", x)
        console.log(" [transform1_initPlotData, end] y:", y)
        transform2_transform1data_candlebox(x, y)
    }
    
    //// transform 2 --- candle data format, fit for plotly
    function transform2_transform1data_candlebox(dataploy_x, dataploy_y) {        
        let data_candlebox: any = [];
        if (dataploy_x !== undefined && dataploy_y !== undefined) {
            for (var i = 0; i < dataploy_x.length; i++) {
                var result = {
                type: "box",
                y: dataploy_y[i],
                name: dataploy_x[i],
                boxpoints: "all",
                jitter: 0.5,
                whiskerwidth: 0.2,
                fillcolor: "cls",
                marker: {
                    size: 2,
                },
                line: {
                    width: 1,
                },
                };            
                data_candlebox.push(result);
            }          
            console.log("[transform2, endA] data_candlebox", data_candlebox);

            // setdatacandlebox1( ... datacandlebox1, data_candlebox)
            // setdatacandlebox1( data_candlebox )

        } else {
          console.log("[transform2, endB] !data_candlebox, dataploy_x", dataploy_x);
        }
        
        setLoading(false);
    }
    
    //// startup, data reading - api, getALL
    useEffect(() => {
        async function initGraph() {
            initPlotData()
        }
        initGraph();
    }, []);

    //// non-startup, data reading
    //    - (table) if there exist 'searchText', then response the needed data
    //    - if there exist NO 'searchText', then response 'getALL'
    const reloadgraphdata = () => {
        // if (searchTextGraph) {
        //     searchtemplatedataGraph()
        // } else {
        //     initPlotData();
        // }
        initPlotData();
    }

    //// number of records to show on graph
    const handleChangeRowsPerPageGraph = (event: any) => {
        rowsPerPageGraph.current = parseInt(event.target.value);
        reloadgraphdata()
    };

    ////// [Graph / Plot] logic --- end
    //////////////////////////////////////////////////////////////////////////////




    //////////////////////////////////////////////////////////////////////////////
    ////// [Tab / Panel] logic --- start
    const theme = useTheme();
    const [tabvalue, settabvalue] = React.useState(0);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        settabvalue(newValue);
    }    
    const handleTabChangeIndex = (index: number) => {
        settabvalue(index);
    };    

    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: any;
        value: any;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    function tabProps(index: any) {
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
    }
    ////// [Tab / Panel] logic --- end
    //////////////////////////////////////////////////////////////////////////////




    return (
        <div className={classesStyle.themeStyleRoot}>
            <Container>
                <br />
                <br />
                <br />

                <h1 className='tal pb10'> Asset Mapping </h1>








                {/* ////// [Tab / Panel] UI --- start */}
                {/* <Paper>
                    <div className={classesStyle.themeTabRoot}>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={tabvalue}
                            onChangeIndex={handleTabChangeIndex}
                        >
                            <TabPanel value={tabvalue} index={0} dir={theme.direction}>
                                abc
                            </TabPanel>
                            <TabPanel value={tabvalue} index={1} dir={theme.direction}>
                                123
                            </TabPanel>
                            <TabPanel value={tabvalue} index={2} dir={theme.direction}>
                                ABC
                            </TabPanel>
                        </SwipeableViews>
                        <AppBar position="static" color="transparent">
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >                                
                                <Tab label="Tab1" {...tabProps(0)} />
                                <Tab label="Tab2" {...tabProps(1)} />
                                <Tab label="Tab3" {...tabProps(2)} />
                            </Tabs>
                        </AppBar>
                    </div>
                </Paper> */}
                {/* ////// [Tab / Panel] UI --- end */}








                
                {/* <hr />
                <br />  */}

                







                {/* ////// [Graph / Plot] UI --- start */}                
                {/* {console.log("[main, return] dataPlot.x:", dataPlot.x)}
                {console.log("[main, return] dataPlot.y:", dataPlot.y)} */}                
                {console.log("[main, return] loading:", loading)}
                {console.log("[main, return] datacandlebox1", datacandlebox1)}

                {/* <h3 className='w-grey'> &nbsp; Graph
                    &nbsp;&nbsp;                    
                    <IconButton onClick={reloadgraphdata} className={classesStyle.button_icon}>
                        <Refresh />
                    </IconButton>                    
                </h3> */}

                <br/>
                {/* {loading ? (
                    <div className="text-center">
                        <Table>
                        <tbody>
                            <tr>
                                <Image src={ImageLoading} fluid />                                
                            </tr>
                            <tr>                                
                            </tr>
                        </tbody>
                        </Table>
                    </div>
                    ) : (
                    <div>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="age-native-simple"> &nbsp;&nbsp; Number of Display Records</InputLabel>
                            <Select
                                native
                                value={rowsPerPageGraph.current}
                                onChange={handleChangeRowsPerPageGraph}
                            >
                                <option aria-label="None" value="" />
                                <option value={50}>  &nbsp;&nbsp;&nbsp;&nbsp; 50</option>
                                <option value={500}> &nbsp;&nbsp;&nbsp;&nbsp; 500</option>
                                <option value={1000}>&nbsp;&nbsp;&nbsp;&nbsp; 1000</option>
                                <option value={2000}>&nbsp;&nbsp;&nbsp;&nbsp; 2000</option>
                                <option value={5000}>&nbsp;&nbsp;&nbsp;&nbsp; 5000</option>
                            </Select>
                        </FormControl>  */}
                        {/* <br/>
                        <br/> */}
                        {/* <FormControl fullWidth>                    
                            <Input
                                placeholder="&nbsp; keyword search"                        
                                // id="outlined-adornment-amount"
                                onChange={searchHandlerGraph}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <IconButton type="submit" className={classesStyle.iconButton_menu} aria-label="search" onClick={searchtemplatedataGraph}>
                                            <Menu />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton type="submit" className={classesStyle.iconButton_search} aria-label="search" onClick={searchtemplatedataGraph}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                // labelWidth={60}
                            />
                        </FormControl> */}

                        {/* <Plot
                            config={{ displayModeBar: false }}
                            data={datacandlebox1}
                            layout={{
                                margin: {
                                    l: 60,
                                    r: 50,
                                    b: 50,
                                    t: 50,
                                    pad: 0,
                                },
                                autosize: true,
                                xaxis: {
                                type: "date",
                                rangeslider: { visible: true },
                                // domain: [0, 0.9],
                                // title: "Device - AM1",
                                },
                                yaxis: {
                                    title: "chainage",
                                side: "left",
                                // domain: [0.84, 1],
                                },
                            }}
                            useResizeHandler={true}
                            style={{ width: "100%", height: "100%" }}
                        />
                    </div>
                    )
                } */}
                {/* ////// [Graph / Plot] UI --- end */}










                <hr/>
                <br/>

                <h3 className='w-grey'> &nbsp; Database
                    &nbsp;&nbsp;
                    <IconButton onClick={() => openDialogAdd("add")} 
                        className={classesStyle.button_icon}
                        // className={[classesStyle.button_add, 'themeBgi'].join(' ')}
                        >
                        <Add />
                    </IconButton>
                    &nbsp;
                    <IconButton onClick={opendialogimportExcelFile} className={classesStyle.button_icon}>
                        <CloudUpload />
                    </IconButton>
                    &nbsp;
                    <IconButton onClick={handleExportExcel} className={classesStyle.button_icon}>
                        <CloudDownload />
                    </IconButton>
                    &nbsp;
                    <IconButton onClick={reloadtabledata} className={classesStyle.button_icon}>
                        <Refresh />
                    </IconButton>                    
                </h3>
                
                <br/>
                <FormControl fullWidth>                    
                    <Input
                        placeholder="&nbsp; keyword search"                        
                        // id="outlined-adornment-amount"
                        onChange={searchHandler}
                        startAdornment={
                            <InputAdornment position="end">
                                <IconButton type="submit" className={classesStyle.iconButton_menu} aria-label="search" onClick={searchtemplatedata}>
                                    <Menu />
                                </IconButton>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton type="submit" className={classesStyle.iconButton_search} aria-label="search" onClick={searchtemplatedata}>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                        // labelWidth={60}
                    />
                </FormControl>                
                
                <br/>
                <br/>

                <TableContainer component={Paper} variant="outlined">
                    <Table className='tableBg' aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell width={80} align="center"> sol </StyledTableCell>
                                <StyledTableCell width={80} align="center"> chainage </StyledTableCell>
                                <StyledTableCell width={80} align="center"> elevated_extent </StyledTableCell>
                                <StyledTableCell width={80} align="center"> level_extent </StyledTableCell>
                                <StyledTableCell width={80} align="center"> blast_no </StyledTableCell>
                                <StyledTableCell width={80} align="center"> date </StyledTableCell>

                                <StyledTableCell width={80} align="center"> rqd </StyledTableCell>                
                                <StyledTableCell width={80} align="center"> jn </StyledTableCell>
                                <StyledTableCell width={80} align="center"> jr </StyledTableCell>
                                <StyledTableCell width={80} align="center"> ja </StyledTableCell>
                                <StyledTableCell width={80} align="center"> jw </StyledTableCell>
                                <StyledTableCell width={80} align="center"> srf </StyledTableCell>
                                <StyledTableCell width={80} align="center"> q_value </StyledTableCell>
                                <StyledTableCell width={80} align="center"> q_pi </StyledTableCell>
                                <StyledTableCell width={80} align="center"> gsi </StyledTableCell>

                                <StyledTableCell width={80} align="center"> remarks </StyledTableCell>
                                <StyledTableCell width={80} align="center"> pw </StyledTableCell>
                                
                                <StyledTableCell width={80} align="center"> dip_direction_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_3 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_3 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_4 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_4 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_5 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_5 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_6 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_6 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_7 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_7 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_8 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_8 </StyledTableCell>
                                
                                <StyledTableCell width={80} align="center"> dip_direction_r1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_r1 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_r2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_r2 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_r3 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_r3 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_r4 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_r4 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_r5 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_r5 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_r6 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_r6 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_r7 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_r7 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_direction_r8 </StyledTableCell>
                                <StyledTableCell width={80} align="center"> dip_r8 </StyledTableCell>
                                
                                <StyledTableCell width={80} align="center"> condition </StyledTableCell>
                                <StyledTableCell width={80} align="center"> measurement </StyledTableCell>
                                <StyledTableCell width={80} align="center"> location </StyledTableCell>
                                <StyledTableCell width={80} align="center"> crown </StyledTableCell>
                                <StyledTableCell width={80} align="center"> wall </StyledTableCell>
                                

                                <StyledTableCell width={100} align="center">operation</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templatedata ?
                                (
                                    <>
                                        {templatedata.map((row, index) => (
                                            <StyledTableRow key={row.id} className="table-striped">                                                
                                                <TableCell align="center" width={80}>{row.sol}</TableCell>
                                                <TableCell align="center" width={80}>{row.chainage}</TableCell>
                                                <TableCell align="center" width={80}>{row.elevated_extent}</TableCell>
                                                <TableCell align="center" width={80}>{row.level_extent}</TableCell>
                                                <TableCell align="center" width={80}>{row.blast_no}</TableCell>
                                                <TableCell align="center" width={80}>{row.date}</TableCell>

                                                <TableCell align="center" width={80}>{row.rqd}</TableCell>
                                                <TableCell align="center" width={80}>{row.jn}</TableCell>
                                                <TableCell align="center" width={80}>{row.jr}</TableCell>
                                                <TableCell align="center" width={80}>{row.ja}</TableCell>
                                                <TableCell align="center" width={80}>{row.jw}</TableCell>
                                                <TableCell align="center" width={80}>{row.srf}</TableCell>
                                                <TableCell align="center" width={80}>{row.q_value}</TableCell>
                                                <TableCell align="center" width={80}>{row.q_pi}</TableCell>
                                                <TableCell align="center" width={80}>{row.gsi}</TableCell>

                                                <TableCell align="center" width={80}>{row.remarks}</TableCell>
                                                <TableCell align="center" width={80}>{row.pw}</TableCell>

                                                <TableCell align="center" width={80}>{row.dip_direction_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_1}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_2}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_3}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_3}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_4}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_4}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_5}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_5}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_6}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_6}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_7}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_7}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_8}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_8}</TableCell>
                                                
                                                <TableCell align="center" width={80}>{row.dip_direction_r1}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_r1}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_r2}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_r2}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_r3}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_r3}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_r4}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_r4}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_r5}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_r5}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_r6}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_r6}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_r7}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_r7}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_direction_r8}</TableCell>
                                                <TableCell align="center" width={80}>{row.dip_r8}</TableCell>
                                                
                                                <TableCell align="center" width={80}>{row.condition}</TableCell>
                                                <TableCell align="center" width={80}>{row.measurement}</TableCell>
                                                <TableCell align="center" width={80}>{row.location}</TableCell>
                                                <TableCell align="center" width={80}>{row.crown}</TableCell>
                                                <TableCell align="center" width={80}>{row.wall}</TableCell>
                                                
                                                
                                                <TableCell align="center">
                                                    <IconButton className={classesStyle.button_icon} onClick=
                                                        {
                                                            () => { 
                                                                // [fixme, depends on table, DataElement], caution: SEQUENCE of 'openDialogEdit()'
                                                                openDialogEdit(                                                                    
                                                                    row.sol,
                                                                    row.chainage,
                                                                    row.elevated_extent,
                                                                    row.level_extent,
                                                                    row.blast_no,
                                                                    row.date,

                                                                    row.rqd,
                                                                    row.jn,
                                                                    row.jr,
                                                                    row.ja,
                                                                    row.jw,
                                                                    row.srf,
                                                                    row.q_value,
                                                                    row.q_pi,
                                                                    row.gsi,

                                                                    row.remarks,
                                                                    row.pw,

                                                                    row.dip_direction_1,
                                                                    row.dip_1,
                                                                    row.dip_direction_2,
                                                                    row.dip_2,
                                                                    row.dip_direction_3,
                                                                    row.dip_3,
                                                                    row.dip_direction_4,
                                                                    row.dip_4,
                                                                    row.dip_direction_5,
                                                                    row.dip_5,
                                                                    row.dip_direction_6,
                                                                    row.dip_6,
                                                                    row.dip_direction_7,
                                                                    row.dip_7,
                                                                    row.dip_direction_8,
                                                                    row.dip_8,
                                                                    
                                                                    row.dip_direction_r1,
                                                                    row.dip_r1,
                                                                    row.dip_direction_r2,
                                                                    row.dip_r2,
                                                                    row.dip_direction_r3,
                                                                    row.dip_r3,
                                                                    row.dip_direction_r4,
                                                                    row.dip_r4,
                                                                    row.dip_direction_r5,
                                                                    row.dip_r5,
                                                                    row.dip_direction_r6,
                                                                    row.dip_r6,
                                                                    row.dip_direction_r7,
                                                                    row.dip_r7,
                                                                    row.dip_direction_r8,
                                                                    row.dip_r8,
                                                                    
                                                                    row.condition,
                                                                    row.measurement,
                                                                    row.location,
                                                                    row.crown,
                                                                    row.wall
                                                                    
                                                                ) 
                                                            }
                                                        } 
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                    
                                                    <IconButton className={classesStyle.button_icon} onClick=
                                                        {
                                                            () => {handleDelete(row.id)}
                                                        } 
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                    
                                                </TableCell>

                                                

                                            </StyledTableRow>
                                        ))}
                                    </>
                                ) : (
                                    <div> &nbsp;&nbsp;&nbsp;&nbsp; no record found </div>
                                )
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalpagenum}
                    rowsPerPage={rowsPerPage.current}
                    page={page.current}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Container>

            <DialogAdd
                triggerOpen={dialogAddOpen}
                handleClose={closeDialogAdd}

                setsol={setsol}
                setchainage={setchainage}
                setelevated_extent={setelevated_extent}
                setlevel_extent={setlevel_extent}
                setblast_no={setblast_no}
                setdate={setdate}
                
                setrqd={setrqd}
                setjn={setjn}
                setjr={setjr}
                setja={setja}
                setjw={setjw}
                setsrf={setsrf}
                setq_value={setq_value}
                setq_pi={setq_pi}
                setgsi={setgsi}

                setremarks={setremarks}
                setpw={setpw}

                setdip_direction_1={setdip_direction_1}
                setdip_1={setdip_1}
                setdip_direction_2={setdip_direction_2}
                setdip_2={setdip_2}
                setdip_direction_3={setdip_direction_3}
                setdip_3={setdip_3}
                setdip_direction_4={setdip_direction_4}
                setdip_4={setdip_4}
                setdip_direction_5={setdip_direction_5}
                setdip_5={setdip_5}
                setdip_direction_6={setdip_direction_6}
                setdip_6={setdip_6}
                setdip_direction_7={setdip_direction_7}
                setdip_7={setdip_7}
                setdip_direction_8={setdip_direction_8}
                setdip_8={setdip_8}
                
                setdip_direction_r1={setdip_direction_r1}
                setdip_r1={setdip_r1}
                setdip_direction_r2={setdip_direction_r2}
                setdip_r2={setdip_r2}
                setdip_direction_r3={setdip_direction_r3}
                setdip_r3={setdip_r3}
                setdip_direction_r4={setdip_direction_r4}
                setdip_r4={setdip_r4}
                setdip_direction_r5={setdip_direction_r5}
                setdip_r5={setdip_r5}
                setdip_direction_r6={setdip_direction_r6}
                setdip_r6={setdip_r6}
                setdip_direction_r7={setdip_direction_r7}
                setdip_r7={setdip_r7}
                setdip_direction_r8={setdip_direction_r8}
                setdip_r8={setdip_r8}
                
                setcondition={setcondition}
                setmeasurement={setmeasurement}
                setlocation={setlocation}
                setcrown={setcrown}
                setwall={setwall}
                

                // [fixme, + magic refresh table after edit / update]
                handleconfirmDialogAdd={handleconfirmDialogAdd}

                // control POPupB of POPupA
                openDialogOperationResult={openDialogOperationResult}
            />

            <DialogEdit
                
                // control POPupA Dialog: on / off
                triggerOpen={dialogEditOpen}
                handleClose={closeDialogEdit}                
                
                // [fixme, depends on table, DataElement]
                sol={sol}
                chainage={chainage}
                elevated_extent={elevated_extent}
                level_extent={level_extent}
                blast_no={blast_no}
                date={date}

                rqd={rqd}
                jn={jn}
                jr={jr}
                ja={ja}
                jw={jw}
                srf={srf}
                q_value={q_value}
                q_pi={q_pi}
                gsi={gsi}

                remarks={remarks}
                pw={pw}

                dip_direction_1={dip_direction_1}
                dip_1={dip_1}
                dip_direction_2={dip_direction_2}
                dip_2={dip_2}
                dip_direction_3={dip_direction_3}
                dip_3={dip_3}
                dip_direction_4={dip_direction_4}
                dip_4={dip_4}
                dip_direction_5={dip_direction_5}
                dip_5={dip_5}
                dip_direction_6={dip_direction_6}
                dip_6={dip_6}
                dip_direction_7={dip_direction_7}
                dip_7={dip_7}
                dip_direction_8={dip_direction_8}
                dip_8={dip_8}
                
                dip_direction_r1={dip_direction_r1}
                dip_r1={dip_r1}
                dip_direction_r2={dip_direction_r2}
                dip_r2={dip_r2}
                dip_direction_r3={dip_direction_r3}
                dip_r3={dip_r3}
                dip_direction_r4={dip_direction_r4}
                dip_r4={dip_r4}
                dip_direction_r5={dip_direction_r5}
                dip_r5={dip_r5}
                dip_direction_r6={dip_direction_r6}
                dip_r6={dip_r6}
                dip_direction_r7={dip_direction_r7}
                dip_r7={dip_r7}
                dip_direction_r8={dip_direction_r8}
                dip_r8={dip_r8}
                
                condition={condition}
                measurement={measurement}
                location={location}
                crown={crown}
                wall={wall}
                


                setsol={setsol}
                setchainage={setchainage}
                setelevated_extent={setelevated_extent}
                setlevel_extent={setlevel_extent}
                setblast_no={setblast_no}
                setdate={setdate}
                
                setrqd={setrqd}
                setjn={setjn}
                setjr={setjr}
                setja={setja}
                setjw={setjw}
                setsrf={setsrf}
                setq_value={setq_value}
                setq_pi={setq_pi}
                setgsi={setgsi}

                setremarks={setremarks}
                setpw={setpw}

                setdip_direction_1={setdip_direction_1}
                setdip_1={setdip_1}
                setdip_direction_2={setdip_direction_2}
                setdip_2={setdip_2}
                setdip_direction_3={setdip_direction_3}
                setdip_3={setdip_3}
                setdip_direction_4={setdip_direction_4}
                setdip_4={setdip_4}
                setdip_direction_5={setdip_direction_5}
                setdip_5={setdip_5}
                setdip_direction_6={setdip_direction_6}
                setdip_6={setdip_6}
                setdip_direction_7={setdip_direction_7}
                setdip_7={setdip_7}
                setdip_direction_8={setdip_direction_8}
                setdip_8={setdip_8}
                
                setdip_direction_r1={setdip_direction_r1}
                setdip_r1={setdip_r1}
                setdip_direction_r2={setdip_direction_r2}
                setdip_r2={setdip_r2}
                setdip_direction_r3={setdip_direction_r3}
                setdip_r3={setdip_r3}
                setdip_direction_r4={setdip_direction_r4}
                setdip_r4={setdip_r4}
                setdip_direction_r5={setdip_direction_r5}
                setdip_r5={setdip_r5}
                setdip_direction_r6={setdip_direction_r6}
                setdip_r6={setdip_r6}
                setdip_direction_r7={setdip_direction_r7}
                setdip_r7={setdip_r7}
                setdip_direction_r8={setdip_direction_r8}
                setdip_r8={setdip_r8}
                
                setcondition={setcondition}
                setmeasurement={setmeasurement}
                setlocation={setlocation}
                setcrown={setcrown}
                setwall={setwall}

                // [fixme, + magic refresh table after edit / update]
                handleconfirmDialogEdit={handleconfirmDialogEdit}

                // control POPupB of POPupA
                openDialogOperationResult={openDialogOperationResult}                
            />

            <DialogImportExcelFile
                triggerOpen={dialogimportExcelFileOpen}
                handleClose={closedialogimportExcelFile}

                setimportExcelFile={importExcelFileHandler}
                importExcelFile={importExcelFile}
                onloadprogressExcelFile={onloadprogressExcelFile}

                // [fixme, + magic refresh table after edit / update]
                handleconfirmImportExcel={handleconfirmImportExcel}
            />
            
            <DialogOperationResult
                triggerOpen={dialogOperationResultOpen}
                handleClose={closeDialogOperationResult}

                dialogOperationResultState={dialogOperationResultState}
            />

        </div>
    )
}



function DialogEdit(
    {     
        triggerOpen, handleClose, 

        // [fixme, depends on table, DataElement]
        sol,
            chainage,
            elevated_extent,
            level_extent,
            blast_no,
            date,

            rqd,
            jn,
            jr,
            ja,
            jw,
            srf,
            q_value,
            q_pi,
            gsi,
            remarks,
            pw,

            dip_direction_1,
            dip_1,
            dip_direction_2,
            dip_2,
            dip_direction_3,
            dip_3,
            dip_direction_4,
            dip_4,
            dip_direction_5,
            dip_5,
            dip_direction_6,
            dip_6,
            dip_direction_7,
            dip_7,
            dip_direction_8,
            dip_8,

            dip_direction_r1,
            dip_r1,
            dip_direction_r2,
            dip_r2,
            dip_direction_r3,
            dip_r3,
            dip_direction_r4,
            dip_r4,
            dip_direction_r5,
            dip_r5,
            dip_direction_r6,
            dip_r6,
            dip_direction_r7,
            dip_r7,
            dip_direction_r8,
            dip_r8,

            condition,
            measurement,
            location,
            crown,
            wall,
        setsol,
            setchainage,
            setelevated_extent,
            setlevel_extent,
            setblast_no,
            setdate,
            
            setrqd,
            setjn,
            setjr,
            setja,
            setjw,
            setsrf,
            setq_value,
            setq_pi,
            setgsi,
            setremarks,
            setpw,
            
            setdip_direction_1,
            setdip_1,
            setdip_direction_2,
            setdip_2,
            setdip_direction_3,
            setdip_3,
            setdip_direction_4,
            setdip_4,
            setdip_direction_5,
            setdip_5,
            setdip_direction_6,
            setdip_6,
            setdip_direction_7,
            setdip_7,
            setdip_direction_8,
            setdip_8,
            
            setdip_direction_r1,
            setdip_r1,
            setdip_direction_r2,
            setdip_r2,
            setdip_direction_r3,
            setdip_r3,
            setdip_direction_r4,
            setdip_r4,
            setdip_direction_r5,
            setdip_r5,
            setdip_direction_r6,
            setdip_r6,
            setdip_direction_r7,
            setdip_r7,
            setdip_direction_r8,
            setdip_r8,
            
            setcondition,
            setmeasurement,
            setlocation,
            setcrown,
            setwall,

        // [fixme, magic refresh table after edit / update]
        handleconfirmDialogEdit,
        openDialogOperationResult 
    })
    {


    //// CSS related
    const cssStyle = makeStyles((theme: Theme) =>
        createStyles({            
            buttonRow: {
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "16px",
            },
            solidButton: {
                margin: "8px",
                fontWeight: 600,
                "&:hover": {
                    backgroundColor: "#8B0000",
                    color: "#fff",
                },
            },
            input: {
                borderBottom: `1px solid ${theme.palette.divider}`,
                paddingRight: theme.spacing(2),
                display: 'flex',
                alignItems: 'center',
            },
            icon: {
                fontSize: '1.2em',
                marginLeft: theme.spacing(1),
                cursor: 'pointer',
            },
        }))
    const classes = cssStyle();


    //// FORM related
    // state - FORM
    // const [buttonlock, setbuttonlock] = useState(true);
    const [buttonlock, setbuttonlock] = useState(false);    
    
    // [fixme, depends on table, DataElement]
    // [fixme, depends on table, DataElement]
    const handleinput = (event: any) => {
        const { name, value } = event.target;
        switch (name) {

            //// as record UNIQUE ID, so, cannot BE change-ED
            case 'sol':
                setsol((value));
                break;
            case 'chainage':
                setchainage((value));
                break;
            case 'elevated_extent':
                setelevated_extent(value);
                break;
            case 'level_extent':
                setlevel_extent(value);
                break;            
            case 'blast_no':
                setblast_no(value);
                break;
            case 'date':
                setdate((value));
                break;
            
            case 'rqd':
                setrqd(parsefloat_nonan(value));
                break;
            case 'jn':
                setjn(parsefloat_nonan(value));
                break;
            case 'jr':
                setjr(parsefloat_nonan(value));
                break;
            case 'ja':
                setja(parsefloat_nonan(value));
                break;
            case 'jw':
                setjw(parsefloat_nonan(value));
                break;
            case 'srf':
                setsrf(parsefloat_nonan(value));
                break;
            case 'q_value':
                setq_value(parsefloat_nonan(value));
                break;
            case 'q_pi':
                setq_pi(parsefloat_nonan(value));
                break;
            case 'gsi':
                setgsi(parsefloat_nonan(value));
                break;
            
            case 'remarks':
                setremarks((value));
                break;            
            case 'pw':
                setpw((value));
                break;

            case 'dip_direction_1':
                setdip_direction_1(parsefloat_nonan(value));
                break;
            case 'dip_1':
                setdip_1(parsefloat_nonan(value));
                break;
            case 'dip_direction_2':
                setdip_direction_2(parsefloat_nonan(value));
                break;
            case 'dip_2':
                setdip_2(parsefloat_nonan(value));
                break;
            case 'dip_direction_3':
                setdip_direction_3(parsefloat_nonan(value));
                break;
            case 'dip_3':
                setdip_3(parsefloat_nonan(value));
                break;
            case 'dip_direction_4':
                setdip_direction_4(parsefloat_nonan(value));
                break;
            case 'dip_4':
                setdip_4(parsefloat_nonan(value));
                break;
            case 'dip_direction_5':
                setdip_direction_5(parsefloat_nonan(value));
                break;
            case 'dip_5':
                setdip_5(parsefloat_nonan(value));
                break;
            case 'dip_direction_6':
                setdip_direction_6(parsefloat_nonan(value));
                break;
            case 'dip_6':
                setdip_6(parsefloat_nonan(value));
                break;
            case 'dip_direction_7':
                setdip_direction_7(parsefloat_nonan(value));
                break;
            case 'dip_7':
                setdip_7(parsefloat_nonan(value));
                break;
            case 'dip_direction_8':
                setdip_direction_8(parsefloat_nonan(value));
                break;
            case 'dip_8':
                setdip_8(parsefloat_nonan(value));
                break;
                        
            case 'dip_direction_r1':
                setdip_direction_r1(parsefloat_nonan(value));
                break;
            case 'dip_r1':
                setdip_r1(parsefloat_nonan(value));
                break;
            case 'dip_direction_r2':
                setdip_direction_r2(parsefloat_nonan(value));
                break;
            case 'dip_r2':
                setdip_r2(parsefloat_nonan(value));
                break;
            case 'dip_direction_r3':
                setdip_direction_r3(parsefloat_nonan(value));
                break;
            case 'dip_r3':
                setdip_r3(parsefloat_nonan(value));
                break;
            case 'dip_direction_r4':
                setdip_direction_r4(parsefloat_nonan(value));
                break;
            case 'dip_r4':
                setdip_r4(parsefloat_nonan(value));
                break;
            case 'dip_direction_r5':
                setdip_direction_r5(parsefloat_nonan(value));
                break;
            case 'dip_r5':
                setdip_r5(parsefloat_nonan(value));
                break;
            case 'dip_direction_r6':
                setdip_direction_r6(parsefloat_nonan(value));
                break;
            case 'dip_r6':
                setdip_r6(parsefloat_nonan(value));
                break;
            case 'dip_direction_r7':
                setdip_direction_r7(parsefloat_nonan(value));
                break;
            case 'dip_r7':
                setdip_r7(parsefloat_nonan(value));
                break;
            case 'dip_direction_r8':
                setdip_direction_r8(parsefloat_nonan(value));
                break;
            case 'dip_r8':
                setdip_r8(parsefloat_nonan(value));
                break;
            
            case 'condition':
                setcondition((value));
                break;
            case 'measurement':
                setmeasurement((value));
                break;
            case 'location':
                setlocation((value));
                break;
            case 'crown':
                setcrown((value));
                break;
            case 'wall':
                setwall((value));
                break;

            
            default:
                break;
        }
    };
    

    //// startup: script to run
    useEffect(() => {
        // [fixme, depends on table, DataElement]
        setsol(sol)
        setchainage(chainage)
        setelevated_extent(elevated_extent)
        setlevel_extent(level_extent)
        setblast_no(blast_no)
        setdate(date)

        setrqd(rqd)
        setjn(jn)
        setjr(jr)
        setja(ja)
        setjw(jw)
        setsrf(srf)
        setq_value(q_value)
        setq_pi(q_pi)
        setgsi(gsi)

        setremarks(remarks)
        setpw(pw)
        
        setdip_direction_1(dip_direction_1)
        setdip_1(dip_1)        
        setdip_direction_2(dip_direction_2)
        setdip_2(dip_2)
        setdip_direction_3(dip_direction_3)
        setdip_3(dip_3)
        setdip_direction_4(dip_direction_4)
        setdip_4(dip_4)
        setdip_direction_5(dip_direction_5)
        setdip_5(dip_5)
        setdip_direction_6(dip_direction_6)
        setdip_6(dip_6)
        setdip_direction_7(dip_direction_7)
        setdip_7(dip_7)
        setdip_direction_8(dip_direction_8)
        setdip_8(dip_8)
        
        setdip_direction_r1(dip_direction_r1)
        setdip_r1(dip_r1)        
        setdip_direction_r2(dip_direction_r2)
        setdip_r2(dip_r2)
        setdip_direction_r3(dip_direction_r3)
        setdip_r3(dip_r3)
        setdip_direction_r4(dip_direction_r4)
        setdip_r4(dip_r4)
        setdip_direction_r5(dip_direction_r5)
        setdip_r5(dip_r5)
        setdip_direction_r6(dip_direction_r6)
        setdip_r6(dip_r6)
        setdip_direction_r7(dip_direction_r7)
        setdip_r7(dip_r7)
        setdip_direction_r8(dip_direction_r8)
        setdip_r8(dip_r8)
        
        setcondition(condition)
        setmeasurement(measurement)
        setlocation(location)
        setcrown(crown)
        setwall(wall)
        // if ( xx ) {
        //     setbuttonlock(false);
        // } else {
        //     setbuttonlock(true);
        // }
    }, [        
        // [fixme, depends on table, DataElement] - put a value that will CHANGE, something CHANGE -> all state CHANGE
        sol,
        chainage,
        elevated_extent,
        level_extent,
        blast_no,
        date
    ]);


    return (
        <Dialog className='receiveBtn' open={triggerOpen} onClose={handleClose}>            
            <DialogContent >

                <br/>

                {/* // [fixme, depends on table, DataElement] */}
                <Form className='w-form-group-width'>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> sol </Form.Label>
                        <Form.Control disabled type="text" placeholder="0" size="sm"
                            name='sol' value={sol} onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> chainage </Form.Label>
                        <Form.Control disabled type="text" placeholder="0" size="sm"
                            name='chainage' value={chainage} onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> elevated_extent </Form.Label>
                        <Form.Control disabled type="text" placeholder="0"  size="sm"
                            name='elevated_extent' value={elevated_extent} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> level_extent </Form.Label>
                        <Form.Control disabled type="text" placeholder="0"  size="sm"
                            name='level_extent' value={level_extent} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> blast_no </Form.Label>
                        <Form.Control disabled type="text" placeholder="0"  size="sm"
                            name='blast_no' value={blast_no} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> date </Form.Label>
                        <Form.Control disabled type="text" placeholder="YYYY-MM-DD 00:00:00"  size="sm"
                            name='date' value={date} onChange={handleinput}
                        />
                    </Form.Group>                    

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> rqd </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='rqd' value={rqd} onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> jn </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='jn' value={jn} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> jr </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='jr' value={jr} onChange={handleinput}
                        />               
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> ja </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='ja' value={ja} onChange={handleinput}
                        />               
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> jw </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='jw' value={jw} onChange={handleinput}
                        />               
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> srf </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='srf' value={srf} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> q_value </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='q_value' value={q_value} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> q_pi </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='q_pi' value={q_pi} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> gsi </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='gsi' value={gsi} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> remarks </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='remarks' value={remarks} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> pw </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='pw' value={pw} onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_1 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_1' value={dip_direction_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_1 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_1' value={dip_1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_2 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_2' value={dip_direction_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_2 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_2' value={dip_2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_3 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_3' value={dip_direction_3} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_3 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_3' value={dip_3} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_4 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_4' value={dip_direction_4} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_4 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_4' value={dip_4} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_5 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_5' value={dip_direction_5} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_5 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_5' value={dip_5} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_6 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_6' value={dip_direction_6} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_6 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_6' value={dip_6} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_7 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_7' value={dip_direction_7} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_7 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_7' value={dip_7} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_8 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_8' value={dip_direction_8} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_8 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_8' value={dip_8} onChange={handleinput}
                        />
                    </Form.Group>
                    

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r1 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r1' value={dip_direction_r1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r1 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r1' value={dip_r1} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r2 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r2' value={dip_direction_r2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r2 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r2' value={dip_r2} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r3 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r3' value={dip_direction_r3} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r3 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r3' value={dip_r3} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r4 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r4' value={dip_direction_r4} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r4 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r4' value={dip_r4} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r5 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r5' value={dip_direction_r5} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r5 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r5' value={dip_r5} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r6 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r6' value={dip_direction_r6} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r6 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r6' value={dip_r6} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r7 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r7' value={dip_direction_r7} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r7 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r7' value={dip_r7} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r8 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r8' value={dip_direction_r8} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r8 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r8' value={dip_r8} onChange={handleinput}
                        />
                    </Form.Group>
                                        
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> condition </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='condition' value={condition} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> measurement </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='measurement' value={measurement} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> location </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='location' value={location} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> crown </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='crown' value={crown} onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> wall </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='wall' value={wall} onChange={handleinput}
                        />
                    </Form.Group>
                    

                    <br/>
                    
                    <Button
                        className='mr19i themeBgi'
                        variant="contained"
                        color="secondary"
                        onClick={handleconfirmDialogEdit}
                        disabled={buttonlock}
                    >
                        Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        // className='mr20i'
                        variant="contained"
                        // color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                </Form>

                <br/>
                
            </DialogContent>
        </Dialog>
    );
}


function DialogImportExcelFile(
    { 
        triggerOpen, handleClose, 
        setimportExcelFile, importExcelFile, onloadprogressExcelFile,
        handleconfirmImportExcel
    }
    ) {
    return (
        <Dialog open={triggerOpen} onClose={handleClose}>
            <DialogContent>
                <Form>

                    <br/>

                    {(importExcelFile && onloadprogressExcelFile !== 0) ? (
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                aria-valuenow={onloadprogressExcelFile}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: onloadprogressExcelFile + "%" }}
                            >
                                {onloadprogressExcelFile}%
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <p/>
                            </div>
                        </div>
                    )}

                    <br/>

                    <Card>
                        <br/>
                        <label className="btn btn-default">
                            <input type="file" onChange={setimportExcelFile} />
                        </label>
                    </Card>

                    <br/>
                    <br/>

                    &nbsp;&nbsp;
                    <Button
                            className='mr19i themeBgi'
                            variant="contained"
                            color="secondary"
                            onClick={handleconfirmImportExcel}
                            // disabled={buttonlock}
                        >
                            Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        // className='mr20i'
                        variant="contained"
                        // color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Form>
            </DialogContent>
            <br/>
        </Dialog>
    );
}


function DialogOperationResult({ triggerOpen, handleClose, dialogOperationResultState }) {
    return (
        <Dialog open={triggerOpen} onClose={handleClose}>
            <DialogContent>
                {
                    dialogOperationResultState ? 
                    (
                        <div>
                            <div className='w-dialog-operation-result'>
                                &radic;
                            </div>
                            <p className='red'>Operation Success!</p>
                        </div>
                    ) : (
                        <div>
                            <div className='w-dialog-operation-result'>
                                &Chi;
                            </div>
                            <p className='red'>Operation Failure!</p>
                        </div>
                    )
                }
            </DialogContent>
        </Dialog>
    );
}


//@ts-ignore
function DialogAdd(
    { 
        triggerOpen, handleClose, 

        // [fixme, depends on table, DataElement]
        // file,date,rev,chainage,sol,rqd,jn,jr,ja,jw,srf,q_value,q_pi,gsi,dip_direction_1,dip_1,dip_direction_2,dip_2,dip_direction_3,dip_3,dip_direction_4,dip_4,dip_direction_5,dip_5,dip_direction_6,dip_6,dip_direction_7,dip_7,dip_direction_8,dip_8,dip_direction_9,dip_9,pw,condition,measurement,location,crown,wall,remarks,
        setsol,
            setchainage,
            setelevated_extent,
            setlevel_extent,
            setblast_no,
            setdate,

            setrqd,
            setjn,
            setjr,
            setja,
            setjw,
            setsrf,
            setq_value,
            setq_pi,
            setgsi,
            setremarks,
            setpw,

            setdip_direction_1,
            setdip_1,
            setdip_direction_2,
            setdip_2,
            setdip_direction_3,
            setdip_3,
            setdip_direction_4,
            setdip_4,
            setdip_direction_5,
            setdip_5,
            setdip_direction_6,
            setdip_6,
            setdip_direction_7,
            setdip_7,
            setdip_direction_8,
            setdip_8,

            setdip_direction_r1,
            setdip_r1,
            setdip_direction_r2,
            setdip_r2,
            setdip_direction_r3,
            setdip_r3,
            setdip_direction_r4,
            setdip_r4,
            setdip_direction_r5,
            setdip_r5,
            setdip_direction_r6,
            setdip_r6,
            setdip_direction_r7,
            setdip_r7,
            setdip_direction_r8,
            setdip_r8,

            setcondition,
            setmeasurement,
            setlocation,
            setcrown,
            setwall,

        // [fixme, magic refresh table after edit / update]
        handleconfirmDialogAdd,
        openDialogOperationResult
    }) 
    {    

    const cssStyle = makeStyles((theme: Theme) =>
        createStyles({
            circleGroup: {
                display: "flex",
                alignItems: "center",
            },
            circleButton: {
                color: "#bdbdbd",
                padding: "0 4px",
                "&:hover": {
                    backgroundColor: "transparent",
                    color: "#8B0000",
                },
            },
            circleSelected: {
                color: "#8B0000",
            },
            buttonRow: {
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "16px",
            },
            solidButton: {
                margin: "8px",
                fontWeight: 600,
                "&:hover": {
                    backgroundColor: "#8B0000",
                    color: "#fff",
                },
            },
        }))

    const classes = cssStyle();

    // initialization: (fixme: not yet) read origin values        
    //  [fixme]
    // useEffect(() => {
    //     console.log('[DialogAdd] initStatus:', initStatus);
    //     setSelectedCircle(initStatus)
    //     console.log('[DialogAdd] selectedCircle:',selectedCircle);
    // }, [initStatus]);

    const handleinput = (event: any) => {
        const { name, value } = event.target;
        switch (name) {
            //// as record UNIQUE ID, so, cannot BE change-ED
            case 'sol':
                setsol((value));
                break;
            case 'chainage':
                setchainage((value));
                break;
            case 'elevated_extent':
                setelevated_extent(value);
                break;
            case 'level_extent':
                setlevel_extent(value);
                break;            
            case 'blast_no':
                setblast_no(value);
                break;
            case 'date':
                setdate((value));
                break;
            
            case 'rqd':
                setrqd(parsefloat_nonan(value));
                break;
            case 'jn':
                setjn(parsefloat_nonan(value));
                break;
            case 'jr':
                setjr(parsefloat_nonan(value));
                break;
            case 'ja':
                setja(parsefloat_nonan(value));
                break;
            case 'jw':
                setjw(parsefloat_nonan(value));
                break;
            case 'srf':
                setsrf(parsefloat_nonan(value));
                break;
            case 'q_value':
                setq_value(parsefloat_nonan(value));
                break;
            case 'q_pi':
                setq_pi(parsefloat_nonan(value));
                break;
            case 'gsi':
                setgsi(parsefloat_nonan(value));
                break;
            
            case 'remarks':
                setremarks((value));
                break;            
            case 'pw':
                setpw((value));
                break;

            case 'dip_direction_1':
                setdip_direction_1(parsefloat_nonan(value));
                break;
            case 'dip_1':
                setdip_1(parsefloat_nonan(value));
                break;
            case 'dip_direction_2':
                setdip_direction_2(parsefloat_nonan(value));
                break;
            case 'dip_2':
                setdip_2(parsefloat_nonan(value));
                break;
            case 'dip_direction_3':
                setdip_direction_3(parsefloat_nonan(value));
                break;
            case 'dip_3':
                setdip_3(parsefloat_nonan(value));
                break;
            case 'dip_direction_4':
                setdip_direction_4(parsefloat_nonan(value));
                break;
            case 'dip_4':
                setdip_4(parsefloat_nonan(value));
                break;
            case 'dip_direction_5':
                setdip_direction_5(parsefloat_nonan(value));
                break;
            case 'dip_5':
                setdip_5(parsefloat_nonan(value));
                break;
            case 'dip_direction_6':
                setdip_direction_6(parsefloat_nonan(value));
                break;
            case 'dip_6':
                setdip_6(parsefloat_nonan(value));
                break;
            case 'dip_direction_7':
                setdip_direction_7(parsefloat_nonan(value));
                break;
            case 'dip_7':
                setdip_7(parsefloat_nonan(value));
                break;
            case 'dip_direction_8':
                setdip_direction_8(parsefloat_nonan(value));
                break;
            case 'dip_8':
                setdip_8(parsefloat_nonan(value));
                break;
                        
            case 'dip_direction_r1':
                setdip_direction_r1(parsefloat_nonan(value));
                break;
            case 'dip_r1':
                setdip_r1(parsefloat_nonan(value));
                break;
            case 'dip_direction_r2':
                setdip_direction_r2(parsefloat_nonan(value));
                break;
            case 'dip_r2':
                setdip_r2(parsefloat_nonan(value));
                break;
            case 'dip_direction_r3':
                setdip_direction_r3(parsefloat_nonan(value));
                break;
            case 'dip_r3':
                setdip_r3(parsefloat_nonan(value));
                break;
            case 'dip_direction_r4':
                setdip_direction_r4(parsefloat_nonan(value));
                break;
            case 'dip_r4':
                setdip_r4(parsefloat_nonan(value));
                break;
            case 'dip_direction_r5':
                setdip_direction_r5(parsefloat_nonan(value));
                break;
            case 'dip_r5':
                setdip_r5(parsefloat_nonan(value));
                break;
            case 'dip_direction_r6':
                setdip_direction_r6(parsefloat_nonan(value));
                break;
            case 'dip_r6':
                setdip_r6(parsefloat_nonan(value));
                break;
            case 'dip_direction_r7':
                setdip_direction_r7(parsefloat_nonan(value));
                break;
            case 'dip_r7':
                setdip_r7(parsefloat_nonan(value));
                break;
            case 'dip_direction_r8':
                setdip_direction_r8(parsefloat_nonan(value));
                break;
            case 'dip_r8':
                setdip_r8(parsefloat_nonan(value));
                break;
            
            case 'condition':
                setcondition((value));
                break;
            case 'measurement':
                setmeasurement((value));
                break;
            case 'location':
                setlocation((value));
                break;
            case 'crown':
                setcrown((value));
                break;
            case 'wall':
                setwall((value));
                break;
            
            default:
                break;
        }
    };

    //// startup: script to run
    useEffect(() => {
        // [fixme, depends on table, DataElement]
        setsol('')
        setchainage('')
        setelevated_extent('')
        setlevel_extent('')
        setblast_no('')
        setdate('')        

        setrqd(0)
        setjn(0)
        setjr(0)
        setja(0)
        setjw(0)
        setsrf(0)
        setq_value(0)
        setq_pi(0)
        setgsi(0)

        setremarks('')
        setpw('')

        setdip_direction_1(0)
        setdip_1(0)
        setdip_direction_2(0)
        setdip_2(0)
        setdip_direction_3(0)
        setdip_3(0)
        setdip_direction_4(0)
        setdip_4(0)
        setdip_direction_5(0)
        setdip_5(0)
        setdip_direction_6(0)
        setdip_6(0)
        setdip_direction_7(0)
        setdip_7(0)
        setdip_direction_8(0)
        setdip_8(0)
        
        setdip_direction_r1(0)
        setdip_r1(0)
        setdip_direction_r2(0)
        setdip_r2(0)
        setdip_direction_r3(0)
        setdip_r3(0)
        setdip_direction_r4(0)
        setdip_r4(0)
        setdip_direction_r5(0)
        setdip_r5(0)
        setdip_direction_r6(0)
        setdip_r6(0)
        setdip_direction_r7(0)
        setdip_r7(0)
        setdip_direction_r8(0)
        setdip_r8(0)
        
        setcondition('')
        setmeasurement('')
        setlocation('')
        setcrown('')
        setwall('')
        // if ( xx ) {
        //     setbuttonlock(false);
        // } else {
        //     setbuttonlock(true);
        // }
    }, [        
        // [fixme, depends on table, DataElement] - put a value that will CHANGE, something CHANGE -> all state CHANGE
        // blast_no, date, sensitive_receiver_and_reference_no
    ]);
    
    return (
        <Dialog open={triggerOpen} onClose={handleClose}>
            <DialogContent>

                <br/>
                
                {/* // [fixme, depends on table, DataElement] */}
                <Form className='w-form-group-width'>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> sol </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='sol' onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> chainage </Form.Label>
                        <Form.Control type="text" placeholder="0" size="sm"
                            name='chainage' onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> elevated_extent </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='elevated_extent' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> level_extent </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='level_extent' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> blast_no </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='blast_no' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> date </Form.Label>
                        <Form.Control type="text" placeholder="YYYY-MM-DD 00:00:00"  size="sm"
                            name='date' onChange={handleinput}
                        />
                    </Form.Group>                    

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> rqd </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='rqd' onChange={handleinput}
                        />                        
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> jn </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='jn' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> jr </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='jr' onChange={handleinput}
                        />               
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> ja </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='ja' onChange={handleinput}
                        />               
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> jw </Form.Label>
                        <Form.Control type="number" placeholder="0" size="sm"
                            name='jw' onChange={handleinput}
                        />               
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> srf </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='srf' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> q_value </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='q_value' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> q_pi </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='q_pi' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> gsi </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='gsi' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> remarks </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='remarks' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> pw </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='pw' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_1 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_1 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_2 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_2 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_3 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_3' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_3 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_3' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_4 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_4' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_4 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_4' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_5 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_5' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_5 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_5' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_6 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_6' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_6 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_6' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_7 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_7' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_7 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_7' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_8 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_8' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_8 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_8' onChange={handleinput}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r1 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r1 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r1' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r2 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r2 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r2' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r3 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r3' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r3 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r3' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r4 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r4' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r4 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r4' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r5 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r5' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r5 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r5' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r6 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r6' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r6 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r6' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r7 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r7' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r7 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r7' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_direction_r8 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_direction_r8' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> dip_r8 </Form.Label>
                        <Form.Control type="number" placeholder="0"  size="sm"
                            name='dip_r8' onChange={handleinput}
                        />
                    </Form.Group>                    
                    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> condition </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='condition' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> measurement </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='measurement' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> location </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='location' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> crown </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='crown' onChange={handleinput}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> wall </Form.Label>
                        <Form.Control type="text" placeholder="0"  size="sm"
                            name='wall' onChange={handleinput}
                        />
                    </Form.Group>
                    

                    <br/>
                    
                    <Button
                        className='mr19i themeBgi'
                        variant="contained"
                        color="secondary"
                        onClick={handleconfirmDialogAdd}
                        // disabled={buttonlock}
                    >
                        Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        // className='mr20i'
                        variant="contained"
                        // color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                </Form>

                <br/>
                
            </DialogContent>
        </Dialog>
    );
}