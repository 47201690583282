import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';


export default function TemplateButtonSplitDropMenu() {


  //////////////////////////////////////////////////////////////////////////////
  {/* ////// [buttonDownload with dropMenu] 1 ------ start */}
  {/* buttonDownload - dropMenu, setting 4-1 */}
  const buttonDownloadDropMenu_options = ['aforapple', 'bforboy', 'cforcat'];
  const [buttonDownloadDropMenu_selectedIndex, set_buttonDownloadDropMenu_SelectedIndex] = React.useState(0);  
  const buttonDownloadDropMenu_anchorRef = React.useRef<HTMLDivElement>(null);
  const [buttonDownloadDropMenu_open, set_buttonDownloadDropMenu_Open] = React.useState(false);
  {/* buttonDownload - dropMenu, setting 4-2 */}
  const buttonDownload_handleToggle = () => {
    set_buttonDownloadDropMenu_Open((prevOpen) => !prevOpen);
  };
  const buttonDownload_handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (buttonDownloadDropMenu_anchorRef.current && buttonDownloadDropMenu_anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    set_buttonDownloadDropMenu_Open(false);
  };
  {/* buttonDownload, setting 2-1 */}
  const buttonDownload_handleClick = () => {
    console.info(`You clicked ${buttonDownloadDropMenu_options[buttonDownloadDropMenu_selectedIndex]}`);
  };
  const buttonDownload_handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    set_buttonDownloadDropMenu_SelectedIndex(index);
    set_buttonDownloadDropMenu_Open(false);
  };
  {/* ////// [buttonDownload with dropMenu] 1 ------ end */}
  //////////////////////////////////////////////////////////////////////////////

  
  return (
    <Grid container direction="column" alignItems="center">
       
        <br/>
        <br/>
        <br/>
        <br/>

        {/* //////////////////////////////////////////////////////////////////////////////         */}
        {/* ////// [buttonDownload with dropMenu] 2 ------ start */}
        <ButtonGroup variant="contained" color="primary" ref={buttonDownloadDropMenu_anchorRef} aria-label="split button">
          {/* buttonDownload, setting 2-2 */}
          <Button onClick={buttonDownload_handleClick}>{buttonDownloadDropMenu_options[buttonDownloadDropMenu_selectedIndex]}</Button>
          {/* buttonDownload - dropMenu, setting 4-3 */}
          <Button
              color="secondary"
              size="small"
              aria-controls={buttonDownloadDropMenu_open ? 'split-button-menu' : undefined}
              aria-expanded={buttonDownloadDropMenu_open ? 'true' : undefined}
              // aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={buttonDownload_handleToggle}
          >
              <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        {/* buttonDownload - dropMenu, setting 4-4 */}
        <Popper open={buttonDownloadDropMenu_open} anchorEl={buttonDownloadDropMenu_anchorRef.current} role={undefined} transition disablePortal>
          {
            ({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                // style={{
                //   transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                // }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={buttonDownload_handleClose}>
                    <MenuList id="split-button-menu">
                      {
                        buttonDownloadDropMenu_options.map(
                          (option, index) => (
                            <MenuItem
                              key={option}
                              // disabled={index === 2}
                              selected={index === buttonDownloadDropMenu_selectedIndex}
                              onClick={(event) => buttonDownload_handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          )
                        )
                      }
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )
          }
        </Popper>
        {/* ////// [buttonDownload with dropMenu] 2 ------ end */}
        {/* ////////////////////////////////////////////////////////////////////////////// */}
        
    </Grid>
  );
}