import { useState } from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { Button, Card, Container, Form, Row } from "react-bootstrap"

import { useDispatch } from "react-redux";
import { actionLogin } from "../../redux/auth/loginAction";

export function Login() {

    //// data, form input
    const [userEmail, setUserEmail] = useState('');
    const setFormEmail = (e: any) => setUserEmail(e.target.value)
    const [userPassword, setUserPassword] = useState('');
    const setFormPassword = (e: any) => setUserPassword(e.target.value)

    //// route, after login
    const history = useHistory();

    //// REDux related
    const dispatch = useDispatch();
    //// login
    const loginAndGetDatajson = async() => {
        if (!userPassword) {
            return
        }
        // fixme
        //@ts-ignore
        dispatch(actionLogin(userEmail, userPassword, 
            () => {
                localStorage.setItem('isLoggedIn', 'true');
                history.push('/');
            }
        ))

    }


    // CSS related
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            themeStyleRoot: { 
                padding: theme.spacing(2),
                flexGrow: 1,
            },
            text_center: {
                textAlign: 'center'
            },
        })
    );
    const classesStyle = useStyles();

    return (
        <div className={classesStyle.themeStyleRoot}>
            <Container>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Card>
                    <Card.Body>

                        <Card.Title className={classesStyle.text_center}> 
                            Login 
                        </Card.Title>  

                        <br/>        
                        
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email Address"
                                    onChange={setFormEmail}
                                    value={userEmail}
                                />
                                <Form.Label style={{ marginTop: 10 }}>Password</Form.Label>
                                <Form.Control
                                    required
                                    type='password'
                                    placeholder="Password"
                                    onChange={setFormPassword}
                                    value={userPassword}
                                />
                            </Form.Group>

                            <br/>

                            <Button variant="secondary" type="submit" onClick={loginAndGetDatajson} block>
                                Login
                            </Button>
                        
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}