import "bootstrap/dist/css/bootstrap.min.css"

import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { Link } from "react-router-dom"

import { useDispatch, useSelector } from 'react-redux';
import { logoutSuccess } from "../../redux/auth/loginAction";


export default function CNavbar() {

    //// REDux - READ state
    const loginReducer = useSelector((state: any) => { return state.loginReducer });
    //// REDux - WRITE state
    const dispatch = useDispatch();

    console.log("*** loginReducer.isAuthenticated:", loginReducer.isAuthenticated)

    const loginoutHandler = () => {
        // handle logout logic
        if (loginReducer.isAuthenticated) {
            dispatch(logoutSuccess())
            localStorage.removeItem('isLoggedIn');
        }
    }

    return(
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">

            <Navbar.Brand as={Link} to="/">
                <span className="icon-universe">&nbsp;</span>
                Digital Asset
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Navbar.Collapse id="responsive-navbar-nav">

                {loginReducer.isAuthenticated ? (
                
                    <Nav className="mr-auto">
                        <NavDropdown title={<> <span className="icon-element_gold">&nbsp; </span> Asset </>} id="basic-nav-dropdown">
                            
                            {/* <NavDropdown.Item as={Link} to="/file">
                                <span className="icon-element_gold">&nbsp;</span>
                                -- Digital Records
                            </NavDropdown.Item> */}

                            {/* <NavDropdown.Item as={Link} to="/mapping"> */}
                            <NavDropdown.Item as={Link} to="/mapping2">
                                <span className="icon-element_gold">&nbsp;</span>
                                Asset Mapping
                            </NavDropdown.Item>
                            
                            {/* <NavDropdown.Item as={Link} to="/steel_bridge_load_cell">
                                <span className="icon-element_gold">&nbsp;</span>
                                -- Piezometer Monitoring
                            </NavDropdown.Item> */}

                            {/* <NavDropdown.Item as={Link} to="/rockbolt_strength_monitoring">
                                <span className="icon-element_gold">&nbsp;</span>
                                -- Rockbolt Strength Monitoring
                            </NavDropdown.Item> */}

                            {/* <NavDropdown.Item as={Link} to="/settlement_monitoring">
                                <span className="icon-element_gold">&nbsp;</span>
                                -- Settlement Monitoring
                            </NavDropdown.Item> */}
                            <NavDropdown.Item as={Link} to="/charging_and_firing_time">
                                <span className="icon-element_gold">&nbsp;</span>
                                Charging And Firing Time
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item as={Link} to="/cutter_replacement_records">
                                <span className="icon-element_gold">&nbsp;</span>
                                Cutter Replacement Records
                            </NavDropdown.Item> */}
                            {/* <NavDropdown.Item as={Link} to="/cutter_travel_distance">
                                <span className="icon-element_gold">&nbsp;</span>
                                Cutter Travel Distance
                            </NavDropdown.Item> */}
                            <NavDropdown.Item as={Link} to="/convergency">
                                <span className="icon-element_gold">&nbsp;</span>
                                Convergency
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item as={Link} to="/pipe_jacking_machine">
                                <span className="icon-element_gold">&nbsp;</span>
                                Pipe Jacking Machine
                            </NavDropdown.Item> */}

                            {/* <NavDropdown.Item as={Link} to="/tender_schedule">
                                <span className="icon-element_gold">&nbsp;</span>
                                Tender Schedule
                            </NavDropdown.Item> */}
                        </NavDropdown>


                        <NavDropdown title={<> <span className="icon-element_wood">&nbsp; </span> Wood </>} id="basic-nav-dropdown">
                            {/* <NavDropdown.Item as={Link} to="/air_device_measurement"> */}
                            <NavDropdown.Item as={Link} to="/air_device_measurement2">
                                <span className="icon-element_wood">&nbsp;</span>
                                Air Measurement
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item as={Link} to="/noise_device_location_measurement"> */}
                            <NavDropdown.Item as={Link} to="/device_location_measurement2">
                                <span className="icon-element_wood">&nbsp;</span>
                                Noise Measurement
                            </NavDropdown.Item>
                        </NavDropdown>


                        <NavDropdown title={<> <span className="icon-element_water">&nbsp; </span> Water </>} id="basic-nav-dropdown">
                            {/* <NavDropdown.Item as={Link} to="/groundwater_all_combined">
                                <span className="icon-element_water">&nbsp;</span>
                                Groundwater
                            </NavDropdown.Item> */}
                            <NavDropdown.Item as={Link} to="/probing_record">
                                <span className="icon-element_water">&nbsp;</span>
                                Probing Record
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/groundwater_monitoring">
                                <span className="icon-element_water">&nbsp;</span>
                                Groundwater Monitoring
                            </NavDropdown.Item>
                        </NavDropdown>


                        <NavDropdown title={<> <span className="icon-element_fire">&nbsp; </span> Fire </>} id="basic-nav-dropdown">
                            {/* <NavDropdown.Item as={Link} to="/blasting"> */}
                            <NavDropdown.Item as={Link} to="/blasting_data2">
                                <span className="icon-element_fire">&nbsp;</span>
                                Blasting Data
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item as={Link} to="/blasting_overbreak">
                                <span className="icon-element_fire">&nbsp;</span>
                                Blasting Overbreak 
                            </NavDropdown.Item> */}                            
                            <NavDropdown.Item as={Link} to="/survey_for_blasting">
                                <span className="icon-element_fire">&nbsp;</span>
                                Survey For Blasting
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/overbreak_summary">
                                <span className="icon-element_fire">&nbsp;</span>
                                Overbreak Summary
                            </NavDropdown.Item>
                        </NavDropdown>


                        <NavDropdown title={<> <span className="icon-element_earth">&nbsp; </span> Earth </>} id="basic-nav-dropdown">                        
                        <NavDropdown.Item as={Link} to="/weather">
                                <span className="icon-element_earth">&nbsp;</span>
                                Weather
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item as={Link} to="/vibration">
                                <span className="icon-element_earth">&nbsp;</span>
                                -- Vibration
                            </NavDropdown.Item>                         */}
                            {/* <NavDropdown.Item as={Link} to="/air_over_pressure">
                                <span className="icon-element_earth">&nbsp;</span>
                                -- Air Over Pressure
                            </NavDropdown.Item> */}
                            <NavDropdown.Item as={Link} to="/tilting_checkpoint">
                                <span className="icon-element_earth">&nbsp;</span>
                                Tilting Checkpoint
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/gi_stratigraphy">
                                <span className="icon-element_earth">&nbsp;</span>
                                GI Stratigraphy
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/gi_spt">
                                <span className="icon-element_earth">&nbsp;</span>
                                GI SPT
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/weathering">
                                <span className="icon-element_earth">&nbsp;</span>
                                Weathering
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/spt">
                                <span className="icon-element_earth">&nbsp;</span>
                                SPT
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/ground_settlement_checkpoint">
                                <span className="icon-element_earth">&nbsp;</span>
                                Ground Settlement Checkpoint
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item as={Link} to="/pipe_jacking_prelim">
                                <span className="icon-element_earth">&nbsp;</span>
                                Pipe Jacking Prelim
                            </NavDropdown.Item> */}
                            <NavDropdown.Item as={Link} to="/vibration_and_aop">
                                <span className="icon-element_earth">&nbsp;</span>
                                Vibration and AOP
                            </NavDropdown.Item>                            
                        </NavDropdown>


                        {/* <Nav.Link as={Link} to="/template_table"> &nbsp; Template Table </Nav.Link>                      */}
                    </Nav>
                    ): (
                        <Nav className="mr-auto">                            
                        </Nav>
                    )
                }
                
                <Nav>
                    <Nav.Link></Nav.Link>
                </Nav>

                <Nav>          
                </Nav>

                <Nav>
                    <Nav.Link></Nav.Link>
                </Nav>

                <Nav>
                    <Nav.Link style={{ color: "grey" }} as={Link} to="/login" onClick={loginoutHandler}>
                        {/* avatar */}
                        {/* {
                            loginReducer.profile_information.name ? (
                                <div className='w-avatar themeBgi'>
                                    {loginReducer.profile_information.name.slice(0, 1)}
                                </div>
                            ) : null
                        }  */}
                        &nbsp; {loginReducer.isAuthenticated ? 'Logout' : 'Login'} &nbsp;&nbsp;
                    </Nav.Link>
                </Nav>

            </Navbar.Collapse>
        </Navbar>
    )
}