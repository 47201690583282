import { Redirect } from 'react-router-dom';

const AuthGuard = (WrappedComponent: any) => {

    const Route = ({ ...props }) => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

        if (!isLoggedIn) {
            return (
                <Redirect
                    to={{ pathname: '/login', state: { from: props.location } }}
                />
            );
        }

        return <WrappedComponent {...props} />;
    };

    return Route;
};

export default AuthGuard;